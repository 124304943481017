// LIBs
import axios from 'axios';

const optimusApi = axios.create({
  baseURL: 'https://optimus.prd.api.mdsystemweb.com.br/api',
});

export const setToken = (token: string) => {
  optimusApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

const filterObjectParams = (filter: { [keys: string]: any }) =>
  !!filter
    ? Object.keys(filter)
        .map((key) => `${key}=${filter[key]}`)
        .join('&')
    : '';

export const signIn = async (params: { login: string; password: string }) => {
  const { data } = await optimusApi.post('/auth', params);

  if (data.data && data.data.token) setToken(data.data.token);

  return data;
};

export const registerUser = async (params: object) => {
  const { data } = await optimusApi.post('/user', params);

  return data;
};

export const logout = async () => {
  const { data } = await optimusApi.get('/auth/logout');

  return data;
};

export const getUser = async () => {
  const { data } = await optimusApi.get('/v1/users/show');

  return data;
};

export const getUsers = async () => {
  const { data } = await optimusApi.get('/v1/users');

  return data;
};

export const updateUser = async (id: number, params: any) => {
  const { data } = await optimusApi.post(`/v1/users/${id}`, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return data;
};

export const calculateUserHours = async (params: object) => {
  const { data } = await optimusApi.get('v1/users/create', { params: params });

  return data;
};

export const getUserMonthlyHours = async (id: number, params: object) => {
  const { data } = await optimusApi.get(`v1/users/${id}/edit`, {
    params: params,
  });

  return data;
};

/**
 *
 * ALTERAR IMAGEM E SENHA
 *
 */
export const updateUserPassword = async (id: number, params: object) => {
  const { data } = await optimusApi.put(`v1/users/updatePassword/${id}`, params);

  return data;
};

export const updateUserPhoto = async (id: number, params: object) => {
  const { data } = await optimusApi.put(`v1/users/updatePhoto/${id}`, params);

  return data;
};

/**
 *
 * ESQUECER SENHA
 *
 */

export const forgotPassword = async (params: object) => {
  const { data } = await optimusApi.post('forget', params);

  return data;
};

export const resetPassword = async (params: object) => {
  const { data } = await optimusApi.post('forget/reset-password', params);

  return data;
};

/**
 *
 * CONFIGURAÇÔES
 *
 */
export const getSettings = async () => {
  const { data } = await optimusApi.get('v1/settings');

  return data;
};

export const sendSettings = async (params: FormData) => {
  const { data } = await optimusApi.post('v1/settings', params);

  return data;
};

/**
 * CHAT
 */
export const getAllMessagesUser = async (id_user: number | null) => {
  const { data } = await optimusApi.get(`/v1/chats${id_user ? `/${id_user}` : ''}`);

  return data;
};

export const sendMessageUser = async (id_user: number | null, message: string) => {
  let params: { user_id_to?: number; message: string } = { message };

  if (id_user) {
    params.user_id_to = id_user;
  }

  const { data } = await optimusApi.post('/v1/chats', params);

  return data;
};

/**
 * POSIÇÕES
 */
export const getAllPositions = async () => {
  const { data } = await optimusApi.get('/v1/positions');

  return data;
};

/**
 * ACORDO
 */
export const getAllAgreements = async () => {
  const { data } = await optimusApi.get('/v1/agreements');

  return data;
};

export const registerAgreement = async (params: object) => {
  const { data } = await optimusApi.post('/v1/agreements', params);

  return data;
};

export const updateAgreement = async (id: number, params: object) => {
  const { data } = await optimusApi.put(`/v1/agreements/${id}`, params);

  return data;
};

/**
 * DEFINIÇÃO
 */
export const getAllGroups = async () => {
  const { data } = await optimusApi.get('/v1/groups');

  return data;
};

export const addGroup = async (params: any) => {
  const { data } = await optimusApi.post('/v1/groups', params);

  return data;
};

export const updateGroup = async (id: number, params: any) => {
  const { data } = await optimusApi.put(`/v1/groups/${id}`, params);

  return data;
};

export const deleteGroup = async (id: number) => {
  const { data } = await optimusApi.delete(`/v1/groups/${id}`);

  return data;
};

/**
 * COLABORADORES
 */
export const createCollaborator = async (params: any) => {
  const { data } = await optimusApi.post('/v1/users', params);

  return data;
};

export const editCollaborator = async (params: any) => {
  const { data } = await optimusApi.put(`/v1/users/${params.id}`, params);

  return data;
};

/**
 * QUADROS DE TAREFAS
 */
export const getBoards = async () => {
  const { data } = await optimusApi.get('/v1/frames');

  return data;
};

export const getBoardById = async (id: number, query: object) => {
  const { data } = await optimusApi.get(`/v1/frames/${id}?${filterObjectParams(query)}`);

  return data;
};

export const registerBoard = async (params: object) => {
  const { data } = await optimusApi.post('/v1/frames', params);

  return data;
};

export const updateBoard = async (id: number, params: object) => {
  const { data } = await optimusApi.put(`/v1/frames/${id}`, params);

  return data;
};

/**
 * TAREFAS
 */
export const getTaskById = async (id: number) => {
  const { data } = await optimusApi.get(`/v1/framestasks/${id}`);

  return data;
};

export const getTasks = async () => {
  const { data } = await optimusApi.get(`/v1/framestasks`);

  return data;
};

export const updateTask = async (id: number, params: FormData) => {
  const { data } = await optimusApi.post(`/v1/framestasks/${id}`, params, {
    headers: {
      'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
    },
  });

  return data;
};

export const RegisterTask = async (params: FormData) => {
  const { data } = await optimusApi.post(`/v1/framestasks`, params, {
    headers: {
      'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
    },
  });

  return data;
};

export const assumeTask = async (id: number) => {
  const { data } = await optimusApi.put(`v1/framestasksuserassumed/`, {
    task_id: id,
  });

  return data;
};

/**
 * APONTAMENTOS
 */
export const getAppointmentById = async (id: number) => {
  const { data } = await optimusApi.get(`/v1/appointments/${id}`);

  return data;
};

export const getAppointments = async (params?: object) => {
  const { data } = await optimusApi.get(`/v1/appointments`, { params: params });

  return data;
};

export const updateAppointment = async (id: number, params: object) => {
  const { data } = await optimusApi.post(`v1/appointments/${id}`, {
    ...params,
    _method: 'PUT',
  });

  return data;
};

export const registerAppointment = async (params: object) => {
  const { data } = await optimusApi.post(`v1/appointments`, params);

  return data;
};

export const getAppointmentsTypes = async () => {
  const { data } = await optimusApi.get(`/appointment/types`);

  return data;
};

/**
 *
 * LINKS
 *
 */

export const getLinkById = async (id: number) => {
  const { data } = await optimusApi.get(`/v1/links/${id}`);

  return data;
};

export const getLinks = async (params?: object) => {
  const { data } = await optimusApi.get(`/v1/links`, { params: params });

  return data;
};

export const updateLink = async (id: number, params: object) => {
  const { data } = await optimusApi.post(`v1/links/${id}`, {
    ...params,
    _method: 'PUT',
  });

  return data;
};

export const registerLink = async (params: object) => {
  const { data } = await optimusApi.post(`v1/links`, params);

  return data;
};

export const deleteLink = async (id: number) => {
  const { data } = await optimusApi.delete(`v1/links/${id}`);

  return data;
};

/**
 * NOTAS FISCAIS
 */

export const getInvoices = async (params?: object) => {
  const { data } = await optimusApi.get(`/v1/invoices`, { params: params });

  return data;
};

export const registerInvoice = async (params: FormData) => {
  const { data } = await optimusApi.post(`/v1/invoices`, params);

  return data;
};

export const updateInvoice = async (id: number, params: object) => {
  const { data } = await optimusApi.put(`/v1/invoices/${id}`, params);

  return data;
};

export const deleteInvoice = async (id: number) => {
  const { data } = await optimusApi.delete(`/v1/invoices/${id}`);

  return data;
};

export const sendEmail = async (params: object) => {
  const { data } = await optimusApi.get(`v1/invoices/create`, {
    params: params,
  });

  return data;
};

/**
 * LINKS NOTAS FISCAIS
 */
export const getInvoicesLinks = async (params?: object) => {
  const { data } = await optimusApi.get(`/v1/invoice/token`, {
    params: params,
  });

  return data;
};

export const viewInvoicesLinks = async (token: string, params?: object) => {
  const { data } = await optimusApi.get(`/invoice/token/view/${token}`, {
    params: params,
  });

  return data;
};

export const sendInvoiceFromLink = async (params: FormData) => {
  const { data } = await optimusApi.post(`/invoice/token/create`, params, {
    headers: {
      'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
    },
  });

  return data;
};

export const registerInvoicesLinks = async (params: object) => {
  const { data } = await optimusApi.post(`/v1/invoice/token`, params);

  return data;
};

export const updateInvoicesLinks = async (id: number, params: object) => {
  const { data } = await optimusApi.put(`/v1/invoice/token/${id}`, params);

  return data;
};

/**
 * ABAS
 */
export const getTabs = async () => {
  const { data } = await optimusApi.get(`/v1/abas`);

  return data;
};

export const addTab = async (params: object) => {
  const { data } = await optimusApi.post(`/v1/abas`, params);

  return data;
};

export const updateTab = async (id: number, params: object) => {
  const { data } = await optimusApi.put(`/v1/abas/${id}`, params);

  return data;
};

export const deleteTab = async (id: number) => {
  const { data } = await optimusApi.delete(`/v1/abas/${id}`);

  return data;
};

/**
 * IMAGENS
 */

export const deleteImage = async (id: number) => {
  const { data } = await optimusApi.delete(`v1/framestasksuserassumed/${id}`);

  return data;
};

/**
 * PLANOS
 */
export const getPlans = async () => {
  const { data } = await optimusApi.get(`/plans`);

  return data;
};

/**
 * SERVICES
 */

export const getServices = async () => {
  const { data } = await optimusApi.get(`/v1/services`);

  return data;
};

export const addServices = async (params: object) => {
  const { data } = await optimusApi.post(`/v1/services`, params);

  return data;
};

export const updateServices = async (id: number, params: object) => {
  const { data } = await optimusApi.put(`/v1/services/${id}`, params);

  return data;
};

/**
 * CUSTOMERS
 */

export const getCustomers = async () => {
  const { data } = await optimusApi.get(`/v1/customers`);

  return data;
};

export const addCustomers = async (params: object) => {
  const { data } = await optimusApi.post(`/v1/customers`, params);

  return data;
};

export const updateCustomers = async (id: number, params: object) => {
  const { data } = await optimusApi.put(`/v1/customers/${id}`, params);

  return data;
};

/**
 * ORDER
 */

export const getOrder = async (page = 1) => {
  const { data } = await optimusApi.get(`v1/customer/order?page=${page}`);
  return data;
};

export const addOrder = async (params: object) => {
  const { data } = await optimusApi.post(`v1/customer/order`, params);
  return data;
};

export const updateOrder = async (id: number, params: object) => {
  const { data } = await optimusApi.put(`v1/customer/order/${id}`, params);
  return data;
};

export const deleteOrder = async (id: number) => {
  const { data } = await optimusApi.delete(`v1/customer/order/${id}`);
  return data;
};
