//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors } from "../../../core/stylesheet/includes";

export const TaskModalsStyle = styled.div`
  .content-container {
    &:nth-of-type(1) {
      max-width: 739px;
      width: 100%;
    }

    .modal-history-content {
      .history-content-collaborators {
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 1px solid ${colors.grayBlue};

        p {
          font-size: 16px;
          color: ${colors.darkBlueII};
          font-weight: 500;

          span {
            font-size: 16px;
            font-weight: 700;
          }
        }
      }

      .history-content-list {
        margin-bottom: 16px;

        .history-list-title {
          margin-bottom: 8px;

          p {
            font-size: 16px;
            font-weight: 700;
            color: ${colors.darkBlueII};
          }
        }

        .history-list-cards {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .history-card {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px;
            border-radius: 10px;
            border: 1px solid ${colors.darkBlueII};

            p {
              font-size: 16px;
              color: ${colors.darkBlueII};
              font-weight: 500;

              &:nth-of-type(1) {
                width: 100px;
              }
            }
          }
        }
      }
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .double-inputs {
      display: flex;
      gap: 16px;
    }

    .img-holder {
      display: flex;
      gap: 8px;

      border: 1px solid ${colors.blackBlue};
      border-radius: 10px;
      padding: 8px;

      img {
        width: 20%;
        cursor: pointer;
      }
    }
  }

  .modal-image {
    width: 100%;
    height: 100%;
  }
`;
