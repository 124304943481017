// LIBs
import { combineReducers } from "redux";

// REDUCERs
import UserReducer from "./UserReducer";
import ChatsReducer from "./ChatReducer";
import LoaderReducer from "./LoaderReducer";

export const reducers = combineReducers({
    user: UserReducer,
    chats: ChatsReducer,
    loader:LoaderReducer
});
