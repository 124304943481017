export const formDataUser = (params: any, photo: any = null) => {
    const form = new FormData();
    form.append("user_id", "");
    
    params.position && form.append("position_id", params.position.id);
    params.agreement && form.append("agreement_id", params.agreement.id);
    params.group && form.append("group_id", params.group.id);
    params.firstname && form.append("firstname", params.firstname);
    params.lastname && form.append("lastname", params.lastname);
    params.birthday && form.append("birthday", params.birthday);
    params.general_record && form.append("general_record", params.general_record);
    params.taxvat_primary && form.append("taxvat_primary", params.taxvat_primary);
    params.taxvat_secondy && form.append("taxvat_secondy", params.taxvat_secondy);
    photo && form.append("photo", photo);
    params.name_fantasy && form.append("name_fantasy", params.name_fantasy);
    params.zipcode && form.append("zipcode", params.zipcode);
    params.address && form.append("address", params.address);
    params.number && form.append("number", params.number);
    params.complement && form.append("complement", params.complement);
    params.neighborhood && form.append("neighborhood", params.neighborhood);
    params.city && form.append("city", params.city);
    params.state && form.append("state", params.state);
    params.state_abbreviation && form.append("state_abbreviation", params.state_abbreviation);
    params.email_operator && form.append("email_operator", params.email_operator);
    params.email_guys && form.append("email_guys", params.email_guys);
    params.bank_agency && form.append("bank_agency", params.bank_agency);
    params.bank_account && form.append("bank_account", params.bank_account);
    params.bank_operation && form.append("bank_operation", params.bank_operation);
    params.bank && form.append("bank", params.bank);
    params.pix && form.append("pix", params.pix);
    params.contact && form.append("contact", params.contact);
    params.date_init && form.append("date_init", params.date_init);
    params.wage && form.append("wage", params.wage);
    params.amount_time && form.append("amount_time", params.amount_time);
    params.login && form.append("login", params.login);
    params.is_active && form.append("is_active", params.is_active);
    
    if (params.password) {
        form.append("password", params.password);
    }

    if (params.email) {
        form.append("email", params.email);
    }

    form.append("_method", "PUT");
    return form;
};
