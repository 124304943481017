export const colors = {
    black: "#000000",
    white: "#ffffff",
    lightBlue: "#BFCBE0",
    mainBlue: "#19366E",
    blue:"#2857B2",
    darkBlue: "#0E2552",
    darkBlueII: "#031028",
    blackBlue: "#0E2552",
    placeholder: "#7285A9",
    red: "#C32520",
    green: "#239E23",
    lightBlue2: "#009DE3",
    lightBlue3:"#D9EDF7",
    yellow:"#CF7900",
    grayBlue: "#9CA8BD",
};
