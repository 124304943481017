// LIBs
import { jwtDecode } from "jwt-decode";

// TYPES
import * as types from "./types";
import sign from "jwt-encode";

const userName = "@optimus:user";

const INITIAL_STATE = {
    id: null,
    user_id: null,
    user_dependent_id: null,
    position_id: null,
    agreement_id: null,
    group_id: null,
    mobile_id: "",
    firstname: "",
    lastname: "",
    birthday: "",
    general_record: "",
    taxvat_primary: "",
    taxvat_secondy: "",
    photo: "",
    name_fantasy: "",
    zipcode: "",
    address: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "",
    state: "",
    state_abbreviation: "",
    email_operator: "",
    email_guys: "",
    bank_agency: "",
    bank_account: "",
    bank_operation: "",
    bank: "",
    pix: "",
    contact: "",
    date_init: "",
    wage: null,
    amount_time: null,
    login: "",
    is_active: null,
    user_master: null,
    user_dependent: null,
    position: {
        id: null,
        user_id: null,
        name: "",
        is_active: null,
    },
    agreement: {
        id: null,
        name: "",
        is_active: null,
    },
    group: {
        id: null,
        name: "",
        is_active: null,
        abas: [],
    },
};

const user = localStorage.getItem(userName);

const UserReducer = (state = INITIAL_STATE, action: any) => {
    let newState = { ...state };

    if (user) {
        Object.assign(newState, JSON.parse(jwtDecode(user)));
    }

    switch (action.type) {
        case types.CHANGE_USER:
            localStorage.setItem(userName, sign(JSON.stringify(action.payload.data), "user"));
            newState = {
                ...action.payload.data,
            };
            return newState;
        case types.CLEAR_USER:
            newState = INITIAL_STATE;
            localStorage.removeItem(userName);
            localStorage.removeItem("Token");
            window.location.reload();
            return newState;
        default:
            return newState;
    }
};

export default UserReducer;
