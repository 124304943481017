// LIBs
import styled from "styled-components";

// INCLUDEs
import { colors } from "../../core/stylesheet/includes";

export const Container = styled.div`
        max-width: 520px;
        width: 100%;

    form {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .buttons-holder{
            display: flex;
            flex-direction: row;
            gap: 16px;
        }
    }
`;
