//STYLES
import { PlansStyle } from "./styles";

//COMPONENTS
import Pagination from "components/Pagination";

//API
import { getPlans } from "core/utils/Api";

//LIBS
import { useEffect, useState } from "react";
import Slider from "react-slick";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//TYPES
import { PlanType } from "core/types/Types";
import notify from "core/utils/notify";
import PlanCard from "components/PlanCard";
import Button from "components/Button";

// ACTIONs
import { setLoader } from "../../../core/reducers/LoaderReducer/actions";

//ASSETS
import MDSWLogo from "../../../assets/images/MDSWLogoTransparent.png";
import Loading from "components/Loading";
import { useNavigate } from "react-router-dom";

interface IPlans {
    loader: boolean;
    setLoader: (state: boolean) => void;
    onBack: () => void;
    onSelect: (planId: number) => void;
}

const Plans = ({ setLoader, loader, onBack, onSelect }: IPlans) => {
    const [plansData, setPlansData] = useState<PlanType[]>([]);
    const [selectedPlan, setSelectedPlan] = useState<number>();

    const navigate = useNavigate();

    useEffect(() => {
        setLoader(true);

        getPlansData();
    }, []);

    const getPlansData = async () => {
        const req = await getPlans();

        if (req.error && req.error.message) {
            notify("error", req.error.message);
        } else {
            setPlansData(req.data);
            setLoader(false);
        }
    };

    const sliderSettings = {
        dots: plansData?.length > 3,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: plansData?.length >= 3 ? 3 : plansData?.length,
        slidesToScroll: 1,
        dotsClass: "Slider-Dots",
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: plansData?.length > 2,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
    };

    return (
        <PlansStyle>
            {loader && <Loading opacity={1} />}
            <Pagination>
                <div className="card-body">
                    <h1>SELECIONAR PLANO</h1>
                    {plansData && (
                        <Slider {...sliderSettings}>
                            {plansData.map((plan, index) => (
                                <div className="card-holder" key={index}>
                                    <PlanCard
                                        btnLabel="Selecionar Plano"
                                        onClick={() => {
                                            setSelectedPlan(plan.id);
                                        }}
                                        plan={plan}
                                        selected={
                                            selectedPlan !== undefined && plan.id !== selectedPlan
                                        }
                                    />
                                </div>
                            ))}
                        </Slider>
                    )}
                    <Button
                        onClick={() => selectedPlan && onSelect(selectedPlan)}
                        disabled={selectedPlan === undefined}
                    >
                        Continuar
                    </Button>
                    <Button color="light-blue" onClick={onBack}>
                        Voltar
                    </Button>
                    <p className="bottom-text">
                        Já possui uma conta? <span onClick={() => navigate("/login")}>Entrar</span>
                    </p>
                    <div className="brand-holder">
                        <img src={MDSWLogo} alt="Logo MDSYstem Web" />
                        <p className="brand-name">MDSystem Web Design</p>
                    </div>
                </div>
            </Pagination>
        </PlansStyle>
    );
};
const mapStateToProps = (state: any) => ({
    loader: state.loader,
});

const mapDispatchToProps = (dispatch: any) =>
    bindActionCreators(
        {
            setLoader,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Plans);
