//STYLES
import { GroupsRegisterStyle } from "./styles";

//LIBS
import { ChangeEvent, useEffect, useState } from "react";
import { bindActionCreators } from "@reduxjs/toolkit";
import { connect } from "react-redux";

//COMPONENTS
import Button from "components/Button";
import Input from "components/Input";
import Select from "components/Select";

//API
import { addGroup, getTabs, updateGroup } from "core/utils/Api";

//UTILS
import notify from "core/utils/notify";

// ACTIONs
import { setLoader } from "../../core/reducers/LoaderReducer/actions";

const EMPTY_INPUTS = {
    name: "",
    is_active: true,
    abas: [],
};

interface IAba {
    id: number;
    group_id: number;
    aba_id: number;
    name: string;
    link: string;
    icon: string;
    order: number;
    is_active: number;
}

interface IGroupsRegister {
    groupToEdit?: {
        id: number;
        name: string;
        is_active: number | boolean;
        abas: IAba[];
    };
    cancelEdit?: () => void;
    onSend: () => void;
    setLoader: (state: boolean) => void;
}

const GroupsRegister = ({ groupToEdit, cancelEdit, onSend, setLoader }: IGroupsRegister) => {
    const [inputsValues, setInputsValues] = useState<any>(EMPTY_INPUTS);
    const [abas, setAbas] = useState<IAba[]>([]);

    useEffect(() => {
        setInputsValues(groupToEdit || EMPTY_INPUTS);
    }, [groupToEdit]);

    useEffect(() => {
        getAbas();
    }, []);

    const getAbas = async () => {
        const req = await getTabs();
        setAbas(req.data);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputsValues((values: any) => ({ ...values, [name]: value }));
    };

    const handleSubmit = async () => {
        if (inputsValues.name !== "" && inputsValues.abas.length > 0) {
            setLoader(true);
            const params = {
                ...inputsValues,
                abas: inputsValues.abas.map((abaId: any) => ({ aba_id: abaId })),
            };
            let req;

            if (groupToEdit) {
                req = await updateGroup(groupToEdit.id, params);
            } else {
                req = await addGroup(params);
            }

            if (req.error && req.error.title) {
                notify("error", req.error.title);
            } else {
                notify("success", `Group ${groupToEdit ? "editada" : "cadastrada"} com sucesso`);
                onSend();
            }
        } else {
            notify("error", "Preencha todos os campos corretamente");
        }

        setLoader(false);
    };

    return (
        <GroupsRegisterStyle>
            <form>
                <Input
                    onChange={handleChange}
                    value={inputsValues.name}
                    label="Nome:"
                    name="name"
                    type="text"
                />
                <div className="checkbox">
                    <p className="checkbox--label">Abas:</p>
                    {abas && abas.map((aba) => (
                        <div
                            className="checkbox--holder"
                            key={aba.id}
                            onClick={() => {
                                setInputsValues((values: any) => ({
                                    ...values,
                                    abas: !values.abas.includes(aba.id)
                                        ? [...values.abas, aba.id]
                                        : values.abas.filter((item: any) => item !== aba.id),
                                }));
                            }}
                        >
                            <Input
                                checked={inputsValues.abas.includes(aba.id)}
                                onChange={() => { }}
                                showLabel={false}
                                label="*"
                                name="name"
                                type="checkbox"
                            />
                            <p>{aba.name}</p>
                        </div>
                    ))}
                </div>
                <Select
                    value={inputsValues.is_active ? "1" : "0"}
                    onSelect={(value) =>
                        setInputsValues((prev: any) => {
                            return { ...prev, is_active: value === "1" };
                        })
                    }
                    options={[
                        { label: "Ativo", value: "1" },
                        { label: "Inativo", value: "0" },
                    ]}
                />
                <Button onClick={handleSubmit}>Salvar</Button>
                {groupToEdit && (
                    <Button color="danger" onClick={cancelEdit}>
                        Cancelar
                    </Button>
                )}
            </form>
        </GroupsRegisterStyle>
    );
};

const mapDispatchToProps = (dispatch: any) =>
    bindActionCreators(
        {
            setLoader,
        },
        dispatch
    );

export default connect(null, mapDispatchToProps)(GroupsRegister);
