//STYLES
import { ChatsStyle } from "./styles";

//LIBS
import { ChangeEvent, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//CONTAINERS
import ChatsContainer from "container/Chat/Chats/";

//TYPES
import { ChatLink, IChatTypes, UserState } from "core/types/Types";

// ACTIONs
import { setLoader } from "../../core/reducers/LoaderReducer/actions";
import Modal from "components/Modal";
import Input from "components/Input";
import Button from "components/Button";
import { registerLink, updateLink } from "core/utils/Api";
import notify from "core/utils/notify";
import Select from "components/Select";
import Title from "components/Title";

interface IChats {
  user: UserState;
  chats: IChatTypes[];
  loader: boolean;
  setLoader: (state: boolean) => void;
}

const Chats = ({ user, chats, loader, setLoader }: IChats) => {
  const INITIAL_VALUES = {
    link: "",
    name: "",
    is_active: 1,
    user_id: user.id,
  };

  const [chatsData, setChatsData] = useState<IChatTypes[]>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [inputsValues, setInputsValues] = useState<ChatLink>(INITIAL_VALUES);
  const [editLink, setEditLink] = useState<boolean>(false);
  const [reloadLinks, setReloadLinks] = useState<boolean>(false);

  useEffect(() => {
    setLoader(true);

    setChatsData(chats);

    setTimeout(() => {
      setLoader(false);
    }, 500);
  }, []);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputsValues((values) => ({ ...values, [name]: value }));
  };

  const sendLink = async () => {
    let req;

    if (editLink && inputsValues.id) {
      req = await updateLink(inputsValues.id, inputsValues);
    } else {
      req = await registerLink(inputsValues);
    }

    if (req.error && req.error.message) {
      notify("error", req.error.message);
    } else {
      notify(
        "success",
        `Link ${editLink ? "editado" : "registrado"} com sucesso!`
      );
    }

    setEditLink(false);
    setReloadLinks(!reloadLinks);
    setOpenModal(false);
    setInputsValues(INITIAL_VALUES);
  };

  return (
    <ChatsStyle>
      <Title>Chat</Title>
      <ChatsContainer
        currUser={user}
        chats={chatsData}
        onAddLink={() => setOpenModal(true)}
        reloadLinks={reloadLinks}
        onEditLink={(link) => {
          setEditLink(true);
          setOpenModal(true);
          setInputsValues(link);
        }}
      />
      <Modal
        header="Adicionar Link"
        open={openModal}
        onClose={() => {
          setEditLink(false);
          setOpenModal(false);
        }}
      >
        <form className="add-link">
          <Input
            label="Titulo:"
            name="name"
            type="text"
            onChange={handleChange}
            value={inputsValues.name}
          />
          <Input
            label="Link:"
            name="link"
            type="text"
            onChange={handleChange}
            value={inputsValues.link}
          />
          {editLink && (
            <Select
              label="Status:"
              value={`${inputsValues.is_active}`}
              options={[
                { label: "Ativo", value: "1" },
                { label: "Inativo", value: "0" },
              ]}
              onSelect={(value) =>
                setInputsValues((prev) => {
                  return { ...prev, is_active: +value };
                })
              }
            />
          )}
          <div className="buttons-holder">
            <Button
              color="danger"
              onClick={() => {
                setEditLink(false);
                setOpenModal(false);
              }}
            >
              Fechar
            </Button>
            <Button onClick={sendLink}>Salvar</Button>
          </div>
        </form>
      </Modal>
    </ChatsStyle>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user,
  chats: state.chats,
  loader: state.loader,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      setLoader,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Chats);
