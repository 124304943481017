//LIBS
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

//STYLE
import { InvoiceLinksStyle } from "./styles";

//COMPONENTS
import Pagination from "components/Pagination";
import Input from "components/Input";
import Button from "components/Button";
import Table from "components/Table";
import Modal from "components/Modal";
import Select from "components/Select";

//API
import {
    getInvoicesLinks,
    registerInvoicesLinks,
    sendEmail,
    updateInvoicesLinks,
} from "core/utils/Api";

//UTILS
import { formatDate } from "core/utils/formatDate";
import notify from "core/utils/notify";

//TYPES
import { InvoiceType } from "core/types/Types";

const headerTable = ["Link", "Validade", "Status", "Registrado em"];
const months = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];

const UserInvoiceLinks = () => {
    let { id } = useParams();

    const [invoicesList, setInvoicesList] = useState<any>();
    const [invoices, setInvoices] = useState<any>();
    const [notifModal, setNotifModal] = useState<boolean>(false);
    const [validity, setValidity] = useState<string>("");
    const [salary, setSalary] = useState<number>();
    const [invoiceMonth, setInvoiceMonth] = useState<string>();
    const [modalEditOpen, setModalEditOpen] = useState<boolean>(false);
    const [validityEdit, setValidityEdit] = useState<{
        id?: number;
        validity: string;
        is_active: number;
    }>();

    useEffect(() => {
        getInvoiceLinks();
    }, []);

    useEffect(()=>{
        var copyButtons = document.querySelectorAll("#copy-link");

        const handleClick = (event: Event) => {
            const button = event.target as Element;
            copyToClipboard(button);
        };

        copyButtons.forEach((button) => button?.addEventListener("click", handleClick));

        return () => {
            copyButtons.forEach((button) => button.removeEventListener("click", handleClick));
        };
    },[invoices])

    const copyToClipboard = (el: Element) => {
        navigator.clipboard.writeText(el.getAttribute("value") || "");
        notify("success", "Link Copiado!");
    };

    const getInvoiceLinks = async () => {
        const req = await getInvoicesLinks({ user_id: Number(id) });
        setInvoicesList(req.data.all);

        setInvoices(
            req.data.all.map((inv: any) => {
                return {
                    id: inv.token,
                    list: [
                        `<button class="copy" id="copy-link" value="${window.location.host}/invoice/${inv.token}">Copiar Link</button>`,
                        `${formatDate(inv.validity)} ${inv.validity.split(" ")[1]}`,
                        `${inv.is_active == 1 ? "Ativo" : "Inativo"}`,
                        inv.created_at ? `${formatDate(inv.created_at)}` : "--/--/--",
                    ],
                };
            })
        );
    };

    const sendInvoiceLink = async () => {
        let req;

        if (validityEdit && validityEdit.id) {
            req = await updateInvoicesLinks(validityEdit.id, validityEdit);
        } else {
            req = await registerInvoicesLinks({ user_id: Number(id), validity: validity });
        }

        if (req.error && req.error.message) {
            notify("error", req.error.message);
        }

        setValidity("");
        setModalEditOpen(false);
        setValidityEdit(undefined);
        getInvoiceLinks();
    };

    const sendSalaryNotification = async () => {
        const req = await sendEmail({
            user_id: Number(id),
            salary_month: salary,
            reference_month: invoiceMonth,
        });

        if (req.error && req.error.message) {
            notify("error", req.error.message);
        }

        setInvoiceMonth(undefined);
        setSalary(undefined);
        setNotifModal(false);
    };

    return (
        <InvoiceLinksStyle>
            <Pagination>
                <div className="header">
                    <Input
                        label="Validade:"
                        name="validity"
                        type="datetime-local"
                        value={validity}
                        onChange={({ target }) => setValidity(target.value)}
                    />
                    <Button onClick={sendInvoiceLink}>Registrar</Button>
                </div>
                <div className="table-holder">
                    <Table
                        onNavigate={(_, id) => {
                            const invoice = invoicesList.find((nf: any) => nf.token == id);
                            if (invoice) {
                                const periodToGetHours = new Date(invoice.end_period);
                                setNotifModal(true);
                                setInvoiceMonth(
                                    `${
                                        months[periodToGetHours.getMonth()]
                                    }/${periodToGetHours.getFullYear()}`
                                );
                            }
                        }}
                        onEdit={async (_, id) => {
                            setModalEditOpen(true);
                            setValidityEdit(invoicesList.find((inv: any) => inv.token == id));
                        }}
                        headerList={headerTable}
                        bodyList={invoices}
                    />
                </div>
            </Pagination>

            <Modal
                open={modalEditOpen}
                onClose={() => {
                    setModalEditOpen(false);
                    setValidityEdit(undefined);
                }}
                header="Editar Link:"
            >
                {validityEdit?.validity && (
                    <form className="edit-link">
                        <Input
                            label="Validade:"
                            name="validity"
                            type="datetime-local"
                            value={validityEdit.validity}
                            onChange={({ target }) =>
                                setValidityEdit((prev) => {
                                    return prev
                                        ? { ...prev, validity: target.value }
                                        : { is_active: 1, validity: target.value };
                                })
                            }
                        />
                        <Select
                            label="Validade:"
                            name="validity"
                            options={[
                                { label: "Ativo", value: "1" },
                                { label: "Inativo", value: "0" },
                            ]}
                            value={`${validityEdit.is_active}`}
                            onSelect={(value) =>
                                setValidityEdit((prev) => {
                                    return prev
                                        ? { ...prev, is_active: +value }
                                        : { is_active: +value, validity: "" };
                                })
                            }
                        />
                        <Button onClick={sendInvoiceLink}>Editar</Button>
                        <Button
                            onClick={() => {
                                setModalEditOpen(false);
                                setValidityEdit(undefined);
                            }}
                            color="danger"
                        >
                            Fechar
                        </Button>
                    </form>
                )}
            </Modal>
            <Modal
                className="notification"
                onClose={() => {
                    setInvoiceMonth(undefined);
                    setSalary(undefined);
                    setNotifModal(false);
                }}
                header={`Notificar Usuário`}
                open={notifModal}
            >
                <div className="modal-content">
                    <p className="info">Valor da Nota Fiscal:</p>
                    <Input
                        label="Valor da NF:"
                        name="salary_month"
                        type="number"
                        value={salary || ""}
                        onChange={({ target }) => setSalary(+target.value)}
                    />
                    <div className="buttons-holder">
                        <Button
                            color="danger"
                            onClick={() => {
                                setInvoiceMonth(undefined);
                                setSalary(undefined);
                                setNotifModal(false);
                            }}
                        >
                            Fechar
                        </Button>
                        <Button onClick={sendSalaryNotification}>Enviar</Button>
                    </div>
                </div>
            </Modal>
        </InvoiceLinksStyle>
    );
};

export default UserInvoiceLinks;
