//STYLES
import { ImageSelectorStyle } from "./styles";

//LIBS
import { useRef } from "react";

//COMPONENTS
import Button from "components/Button";

//ASSETS
import ExampleImg from "../../assets/images/exampleImg.png";

interface IImageSelector {
  image?: string | null;
  onChangeImg?: (img: any) => void;
}

const ImageSelector = ({ image, onChangeImg }: IImageSelector) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <ImageSelectorStyle>
      <img
        src={
          image
            ? typeof image === "string"
              ? image
              : URL.createObjectURL(image)
            : ExampleImg
        }
        alt="Imagem do Usuário"
      />
      <Button onClick={() => inputRef.current?.click()}>
        Selecionar Imagem
      </Button>
      <input
        ref={inputRef}
        title="Imagem do Usuário"
        type="file"
        onChange={(e) => onChangeImg && onChangeImg(e.target.files)}
      />
    </ImageSelectorStyle>
  );
};

export default ImageSelector;
