//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors, screens } from "../../core/stylesheet/includes";

export const HeaderStyle = styled.header`
    width: 100%;
    background-color: ${colors.mainBlue};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    position: fixed;
    box-shadow: 1px 1px 4px #00000025;
    z-index: 2;

    @media ${screens.tb} {
        /* padding: 0 5%; */
        padding: 0;
    }

    .mobile-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        @media ${screens.tb} {
            display: none;
        }

        img {
            max-height: 32px;
        }

        button {
            color: ${colors.white};
            padding: 8px;
            border: 1px solid #fff;
            border-radius: 10px;
            display: flex;

            svg {
                width: 24px;
                height: 24px;
            }
        }
    }

    .content-holder {
        width: 100%;
        /* max-width: 1440px; */
        display: flex;
        flex-direction: column;
        align-items: center;
        left: 0;
        top: 100%;
        position: absolute;
        transform: translateX(-100%);
        transition: all ease 0.5s;

        @media ${screens.tb} {
            display: flex;
            flex-direction: row;
            position: relative;
            transform: translateX(0%);
        }

        &.active {
            transform: translateX(0%);
        }
    }
`;
