//LIBS
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

//STYLES
import { ExternalInvoiceStyle } from "./styles";

//API
import { sendInvoiceFromLink, viewInvoicesLinks } from "core/utils/Api";

//TYPES
import { ExternalInvoiceType } from "core/types/Types";

//ASSETS
import MDSWLogo from "../../assets/images/MDSWLogoTransparent.png";

//UTILS
import { formatDate } from "core/utils/formatDate";

//COMPONENTS
import Pagination from "components/Pagination";
import Input from "components/Input";
import Button from "components/Button";
import notify from "core/utils/notify";
import Title from "components/Title";

const ExternalInvoice = () => {
    let { token } = useParams();
    const navigate = useNavigate();
    const [invoiceData, setInvoiceData] = useState<ExternalInvoiceType>();
    const [accessIsValid, setAccessIsValid] = useState<boolean>(false);
    const [pdfs, setPdfs] = useState<any>()

    useEffect(() => {
        getInvoiceDate();
    }, []);

    const getInvoiceDate = async () => {
        if (token) {
            try {
                const req = await viewInvoicesLinks(token, { send: false });

                if (req.error && req.error.message) {
                    notify("error", req.error.message);
                } else {
                    setInvoiceData(req);
                    setAccessIsValid(new Date() < new Date(req.validity))
                }
            } catch (e) {
                navigate("/");
            }
        }
    };

    const sendInvoice = async() => {
        const form = new FormData();

        form.append("token", token as string)

        Array.from(pdfs).map((file:any)=>{
            form.append("pdf[]", file);
        })

        const req = await sendInvoiceFromLink(form)

        if(req.error && req.error.message){
            notify("error", req.error.message)
        }else{
            notify("success", req.data)
        }
    }

    return (
        <ExternalInvoiceStyle>
            {invoiceData && (
                <div className="content-holder">
                    <Pagination>
                        <div className="card-body">
                            <Title>Nota Fiscal</Title>
                            <div className="infos-holder">
                                <p className="info-text">
                                    <span>Status:</span>{" "}
                                    <span className={accessIsValid ? "success" : "error"}>
                                        {accessIsValid ? "No Prazo" : "Vencido"}
                                    </span>
                                </p>
                                <p className="info-text">
                                    <span>Vencimento:</span>{" "}
                                    {new Date(invoiceData.validity).toLocaleString("pt-BR")}
                                </p>
                                <p className="info-text">
                                    <span>Cliente:</span>{" "}
                                    {invoiceData.user.firstname + " " + invoiceData.user.lastname}
                                </p>
                                <p className="info-text">
                                    <span>Nascimento:</span> {formatDate(invoiceData.user.birthday)}
                                </p>
                                <p className="info-text">
                                    <span>RG:</span> {invoiceData.user.general_record}
                                </p>
                                <p className="info-text">
                                    <span>CPF:</span> {invoiceData.user.taxvat_primary}
                                </p>
                                <p className="info-text">
                                    <span>CNPJ:</span> {invoiceData.user.taxvat_secondy}
                                </p>
                                <p className="info-text">
                                    <span>Empresa:</span> {invoiceData.user.name_fantasy}
                                </p>
                            </div>

                            <form>
                                <p className="label">
                                    Documentos solicitados <span>(pdf)</span>
                                </p>
                                <Input
                                    accept="application/pdf"
                                    label="Notas Fiscais:"
                                    name="invoices"
                                    type="file"
                                    showLabel={false}
                                    onChangeFile={(e)=>setPdfs(e.target.files)}
                                />
                            </form>

                            <Button onClick={sendInvoice} disabled={!pdfs}>Enviar</Button>

                            <div className="brand-holder">
                                <img src={MDSWLogo} alt="Logo MDSYstem Web" />
                                <p className="brand-name">MDSystem Web Design</p>
                            </div>
                        </div>
                    </Pagination>
                </div>
            )}
        </ExternalInvoiceStyle>
    );
};

export default ExternalInvoice;
