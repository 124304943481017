//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors, screens } from "../../core/stylesheet/includes";

export const GroupsStyle = styled.main`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content-holder {
        width: 100%;
        max-width: 1440px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;

        main {
            width: 100%;

            @media ${screens.tb} {
                max-width: 320px;
            }
        }
        
        @media ${screens.tb} {
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
        }

        >div {
            @media ${screens.tb} {
                width: calc(100% - 352px);
            }
        
            @media ${screens.dsS} {
                flex-direction: row;
            }
        }
    }

    .table-holder {
        width: 100%;
        max-width: 1440px;
        overflow: auto;
        padding-bottom: 16px;

        table {
            min-width: 550px;
        }
    }
`;
