import { OrderStyle } from "./styles";
import OrderContainer from "container/Order";
import Title from "components/Title";

const Order = () => {
  return (
    <OrderStyle>
      <Title>Pedidos</Title>
      <div className="content-holder">
        <OrderContainer />
      </div>
    </OrderStyle>
  );
};

export default Order;
