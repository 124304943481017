// REDUCER
import * as types from "./types";

// API
import { getUsers } from "../../utils/Api";

//TYPES
import { IChatTypes } from "../../types/Types";

export const changeChats = async () => {
    const req = await getUsers();

    return { type: types.CHANGE_CHATS, payload: req };
};

export const setChats = (Chats: IChatTypes[]) => ({
    type: types.CHANGE_CHATS,
    payload: {
        data: [...Chats],
    },
});
