//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors, screens } from "../../core/stylesheet/includes";

export const SwitchStyle = styled.main`
    border-radius: 15px;
    width: 200px;
    padding: 6px;
    border: 1px solid ${colors.grayBlue};
    overflow: hidden;
    display: flex;
    flex-direction: row;
    gap: 16px;
    box-shadow: inset 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
    background-color: ${colors.lightBlue};

    button {
        padding: 8px;
        width: calc(50% - 8px);
        font-weight: 700;
        font-size: 14px;
        border-radius: 10px;
        color: ${colors.grayBlue};
        transition: all ease 0.5s;

        &.selected {
            box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
            background-color: ${colors.white};
            color: ${colors.mainBlue};
        }
    }
`;
