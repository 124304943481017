//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors, screens } from "../../core/stylesheet/includes";

export const ForgotStyle = styled.main`
    background-color: ${colors.mainBlue};
    width: 100vw;
    min-height: 100dvh;
    overflow: hidden;
    padding: 24px 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    .form-holder {
        background-color: ${colors.white};
        border-radius: 20px;
        box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.35);
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: center;
        padding: 24px 32px;
        width: 100%;
        max-width: 450px;

        .title {
            font-size: 32px;
            font-weight: 500;
            color: ${colors.blackBlue};
        }

        form {
            width: 100%;
            flex-direction: column;
            gap: 16px;
            display: flex;
            align-items: center;
        }

        .text-holder {
            width: 100%;
            flex-direction: column;
            gap: 8px;
            display: flex;
            align-items: center;
        }

        .brand-holder {
            display: flex;
            flex-direction: row;
            align-items: center;

            @media ${screens.mbB} {
                padding: 0 16px;
            }

            img {
                max-width: 35px;
                height: auto;

                @media ${screens.mbB} {
                    max-width: 50px;
                }
            }

            .brand-name {
                font-size: 16px;
                font-weight: 500;
                color: ${colors.darkBlue};

                @media ${screens.mbB} {
                    font-size: 20px;
                }
            }
        }

        .bottom-text {
            font-size: 16px;

            span {
                cursor: pointer;
                font-size: 16px;
                font-weight: 500;
                color: ${colors.lightBlue2};
                text-decoration: underline;
            }
        }
    }
`;
