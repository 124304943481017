//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors, screens } from "../../../core/stylesheet/includes";

export const UserTimekeepingStyle = styled.main`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  .title {
    color: ${colors.blackBlue};
    font-size: 32px;
    font-weight: 400;
  }

  .filter {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    width: 100%;

    @media ${screens.tb} {
      max-width: 400px;
      align-items: start;
    }

    .filter-holder {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 8px;
      width: 100%;

      @media ${screens.tb} {
        flex-direction: row;
      }

      & > div:last-child {
        width: 100%;

        @media ${screens.tb} {
          width: fit-content;
        }
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  .content-holder {
    width: 100%;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;

    @media ${screens.tb} {
      align-items: start;
    }
  }

  .table-holder {
    width: 100%;
    overflow: auto;

    table {
      min-width: 960px;
    }
  }

  .content-container {
    max-width: 553px;
    width: 100%;

    .modal-appointment-content {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .modal-appointment-group {
        display: flex;
        gap: 16px;
      }
    }
  }
`;
