//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors, screens } from "../../core/stylesheet/includes";

export const ChatsStyle = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  height: 100%;

  .content-container {
    max-width: 350px;
    width: 100%;
  }

  .add-link {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .buttons-holder {
      display: flex;
      flex-direction: column-reverse;
      gap: 16px;

      @media ${screens.mbB} {
        flex-direction: row;
      }
    }
  }
`;
