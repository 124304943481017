//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors, screens } from "../../../core/stylesheet/includes";

export const UserInfosStyle = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 1440px;

  @media ${screens.dsS} {
    flex-direction: row;
  }

  .card {
    padding: 24px;
    width: 100%;
    border-radius: 10px;
    background: ${colors.white};
    box-shadow: 2px 2px 8px 0px rgba(3, 16, 40, 0.35);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 32px;

    .value {
      font-size: 20px;
      font-weight: 600;
      color: ${colors.darkBlue};
    }

    .infos-holder {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 32px;
      justify-content: space-around;

      .infos {
        display: flex;
        flex-direction: column;
        gap: 8px;
        max-width: 280px;

        @media ${screens.tb} {
          min-width: 240px;
        }
      }

      p {
        color: ${colors.darkBlue};
        font-size: 16px;
        font-weight: 400;
        word-break: break-word;

        span {
          font-weight: 700;
          font-size: 16px;
          color: ${colors.mainBlue};
        }
      }
    }
  }
`;
