//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors, screens } from "../../core/stylesheet/includes";

export const ConfigContainerStyle = styled.main`
    display: flex;
    gap: 16px;
    width: 100%;
    max-width: 1440px;
    background-color: ${colors.white};
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
    padding: 24px;
    border-radius: 10px;
    align-items: center;
    flex-direction: column;
    
    @media ${screens.dsS} {
        flex-direction: row;
        gap: 48px;
    }

    form{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .double-input{
            display: flex;
            flex-direction: column;
            gap: 16px;
            
            @media ${screens.mbV} {
                flex-direction: row;
                gap: 16px;
            }
        }
    }
`;
