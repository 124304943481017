//STYLES
import { RegisterUserStyle } from "./styles";

//COMPONENTS
import Input from "components/Input";
import Select from "components/Select";
import Button from "components/Button";

//LIBS
import { ChangeEvent, useEffect, useState } from "react";
import { bindActionCreators } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import InputMask from "react-input-mask";

//APIS
import {
  createCollaborator,
  editCollaborator,
  getAllAgreements,
  getAllGroups,
  getAllPositions,
  getAppointmentsTypes,
} from "core/utils/Api";

// ACTIONs
import { setLoader } from "../../../core/reducers/LoaderReducer/actions";

//INPUTS
import { EMPTY_OBJECT, inputsToValidate } from "./constants";

//UTILS
import notify from "core/utils/notify";
import axios, { AxiosResponse } from "axios";

interface IRegisterUser {
  userToEdit?: any;
  cancelEdit?: () => void;
  allUsers: any;
  setLoader: (state: boolean) => void;
  onSend: () => void;
}

const RegisterUser = ({
  onSend,
  userToEdit,
  cancelEdit,
  allUsers,
  setLoader,
}: IRegisterUser) => {
  const [registerStage, setRegisterStage] = useState<number>(1);
  const [inputsValues, setInputsValues] = useState<any>(EMPTY_OBJECT);
  const [selectsValues, setSelectsValues] = useState<any>({});

  useEffect(() => {
    setLoader(true);
    if (allUsers) {
      getSelectsValues();
      setTimeout(() => {
        setLoader(false);
      }, 1500);
    }
  }, [allUsers]);

  useEffect(() => {
    setInputsValues(userToEdit || { ...EMPTY_OBJECT });
  }, [userToEdit]);

  useEffect(() => {
    document.querySelectorAll(`.column-${registerStage}`).forEach((el) => {
      el.classList.add("active");
    });

    return () => {
      document.querySelectorAll(`.column-${registerStage}`).forEach((el) => {
        el.classList.remove("active");
      });
    };
  }, [registerStage]);

  const handleSubmit = async () => {
    if (
      inputsToValidate.every(
        (value) => value === "user_id" || inputsValues[value] !== null
      )
    ) {
      let req;

      if (inputsValues.user_dependent_id === "") {
        inputsValues.user_dependent_id = null;
      }

      if (userToEdit) {
        req = await editCollaborator(inputsValues);
      } else {
        req = await createCollaborator(inputsValues);
      }

      if (req.error && req.error.message) {
        notify("error", req.error.message);
      } else {
        notify(
          "success",
          `Usuário ${userToEdit ? "editado" : "registrado"} com sucesso`
        );
        clear();
        onSend();
      }
    } else {
      notify("error", "Preencha todos os campos corretamente");
    }
  };

  const clear = () => {
    setInputsValues(EMPTY_OBJECT);
    cancelEdit && cancelEdit();
  };

  const getSelectsValues = async () => {
    const res = await Promise.all([
      getAllGroups(),
      getAllPositions(),
      getAllAgreements(),
      getAppointmentsTypes(),
    ]);

    const groupReq = res[0];
    const positionsReq = res[1];
    const agreementsReq = res[2];
    const appointmentsReq = res[3];

    setSelectsValues({
      groups: groupReq.data.enable,
      positions: positionsReq.data.enable,
      agreements: agreementsReq.data.enable,
      users: allUsers.filter((user: any) => !!user.is_active),
      appointments: appointmentsReq,
    });
  };

  interface CepResponse {
    data: any;
    cep: string;
    logradouro: string;
    complemento: string;
    bairro: string;
    localidade: string;
    uf: string;
    ibge: string;
    gia: string;
    ddd: string;
    siafi: string;
  }

  const fetchCEP = async (cep: string): Promise<CepResponse | null> => {
    try {
      const response: AxiosResponse<CepResponse> = await axios.get(
        `https://api.optimus.mdsystemweb.com.br/api/viacep/${cep}`
      );
      return response.data;
    } catch (error) {
      throw new Error("Erro ao buscar CEP");
    }
  };

  const handleChange = async (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputsValues((values: any) => ({ ...values, [name]: value }));

    if (name === "zipcode") {
      const cepData = await fetchCEP(value);

      if (cepData) {
        setInputsValues((values: any) => ({
          ...values,
          ...cepData.data,
          zipcode: cepData.data.cep,
          state: cepData.data.state || "",
          state_abbreviation: cepData.data.uf,
          city: cepData.data.localidade,
          neighborhood: cepData.data.bairro,
          address: cepData.data.logradouro,
        }));
      }
    }
  };

  return (
    <RegisterUserStyle>
      <form>
        {/* Seção 1 */}
        <Input
          onChange={handleChange}
          value={inputsValues.firstname}
          className="column-1"
          label="Nome:"
          name="firstname"
          type="text"
        />
        <Input
          onChange={handleChange}
          value={inputsValues.lastname}
          className="column-1"
          label="Sobrenome:"
          name="lastname"
          type="text"
        />
        <InputMask
          mask="(99)99999-9999"
          onChange={handleChange}
          value={inputsValues.contact}
        >
          <Input
            className="column-1"
            label="Contato:"
            name="contact"
            type="text"
          />
        </InputMask>

        <InputMask
          mask="99.999999"
          onChange={handleChange}
          value={inputsValues.general_record}
        >
          <Input
            className="column-1"
            label="RG:"
            name="general_record"
            type="text"
          />
        </InputMask>

        <Input
          onChange={handleChange}
          value={inputsValues.birthday}
          className="column-1"
          label="Nascimento:"
          name="birthday"
          type="date"
        />
        <InputMask
          mask="999.999.999-99"
          onChange={handleChange}
          value={inputsValues.taxvat_primary}
        >
          <Input
            className="column-1"
            label="CPF:"
            name="taxvat_primary"
            type="text"
          />
        </InputMask>

        <Input
          onChange={handleChange}
          value={inputsValues.email}
          className="column-1"
          label="E-mail Pessoal:"
          name="email"
          type="text"
        />
        <Input
          onChange={handleChange}
          value={inputsValues.email_guys}
          className="column-1"
          label="E-mail Operador:"
          name="email_guys"
          type="text"
        />

        {/* Seção 2 */}
        <InputMask
          onChange={handleChange}
          value={inputsValues.zipcode}
          mask="99999-999"
        >
          <Input className="column-2" label="CEP:" name="zipcode" type="text" />
        </InputMask>
        <div className="double-inputs column-2">
          <Input
            onChange={handleChange}
            value={inputsValues.state}
            label="Estado:"
            name="state"
            type="text"
          />
          <Input
            onChange={handleChange}
            value={inputsValues.state_abbreviation}
            label="UF:"
            name="state_abbreviation"
            type="text"
          />
        </div>
        <Input
          onChange={handleChange}
          value={inputsValues.city}
          className="column-2"
          label="Cidade:"
          name="city"
          type="text"
        />
        <Input
          onChange={handleChange}
          value={inputsValues.neighborhood}
          className="column-2"
          label="Bairro:"
          name="neighborhood"
          type="text"
        />
        <Input
          onChange={handleChange}
          value={inputsValues.address}
          className="column-2"
          label="Endereço:"
          name="address"
          type="text"
        />
        <Input
          onChange={handleChange}
          value={inputsValues.number}
          className="column-2"
          label="N◦:"
          name="number"
          type="number"
        />
        <Input
          onChange={handleChange}
          value={inputsValues.complement}
          className="column-2"
          label="Complemento:"
          name="complement"
          type="text"
        />
        <Select
          placeholder="Selecionar status:"
          className="column-2"
          label="Status:"
          value={inputsValues.is_active ? "1" : "0"}
          onSelect={(value) => {
            setInputsValues((prev: any) => {
              return { ...prev, is_active: value == "1" };
            });
          }}
          options={[
            { label: "Ativo", value: "1" },
            { label: "Inativo", value: "0" },
          ]}
        />

        {/* Seção 3 */}
        <Input
          onChange={handleChange}
          value={inputsValues.name_fantasy}
          className="column-3"
          label="Empresa:"
          name="name_fantasy"
          type="text"
        />
        <InputMask
          onChange={handleChange}
          value={inputsValues.taxvat_secondy}
          mask="99.999.999.9999-99"
        >
          <Input
            className="column-3"
            label="CNPJ:"
            name="taxvat_secondy"
            type="text"
          />
        </InputMask>
        <div className="double-inputs column-3">
          <Input
            onChange={handleChange}
            value={inputsValues.bank_agency}
            label="Agência:"
            name="bank_agency"
            type="text"
          />
          <Input
            onChange={handleChange}
            value={inputsValues.bank_account}
            label="Conta:"
            name="bank_account"
            type="text"
          />
        </div>
        <div className="double-inputs column-3">
          <Input
            onChange={handleChange}
            value={inputsValues.bank_operation}
            label="Operação:"
            name="bank_operation"
            type="text"
          />
          <Input
            onChange={handleChange}
            value={inputsValues.bank}
            label="Banco:"
            name="bank"
            type="text"
          />
        </div>
        <Input
          onChange={handleChange}
          value={inputsValues.pix}
          className="column-3"
          label="PIX:"
          name="pix"
          type="text"
        />
        <Input
          onChange={handleChange}
          value={inputsValues.date_init}
          className="column-3"
          label="Data Inicio:"
          name="date_init"
          type="date"
        />
        <Input
          onChange={handleChange}
          value={inputsValues.wage}
          className="column-3"
          label="Salário Base:"
          name="wage"
          type="text"
        />

        {/* Seção 4 */}
        <Input
          onChange={handleChange}
          value={inputsValues.amount_time}
          className="column-3"
          label="Total Horas:"
          name="amount_time"
          type="text"
        />
        <Select
          placeholder="Selecionar cargo"
          onSelect={(value) => {
            setInputsValues((prev: any) => {
              return { ...prev, position_id: +value };
            });
          }}
          className="column-4"
          label="Cargo:"
          name="position"
          value={`${inputsValues.position_id}`}
          options={[{ value: "1", label: "Desenvolvedor Front-End Magento" }]}
        />

        {selectsValues.appointments && (
          <Select
            placeholder="Selecione o apontamento"
            onSelect={(value) => {
              setInputsValues((prev: any) => {
                return { ...prev, appointment_type_id: +value };
              });
            }}
            className="column-4"
            label="Tipo de Apontamento:"
            name="position"
            value={`${inputsValues.appointment_type_id}`}
            options={selectsValues.appointments.map((value: any) => {
              return {
                value: value.id,
                label: value.name,
              };
            })}
          />
        )}

        {selectsValues.agreements && (
          <Select
            placeholder="Selecionar acordo"
            onSelect={(value) => {
              setInputsValues((prev: any) => {
                return { ...prev, agreement_id: +value };
              });
            }}
            name="agreements_id"
            className="column-4"
            label="Acordo:"
            value={`${inputsValues.agreement_id}`}
            options={selectsValues.agreements.map((value: any) => {
              return { value: value.id, label: value.name };
            })}
          />
        )}
        {selectsValues.groups && (
          <Select
            placeholder="Selecionar grupo"
            onSelect={(value) => {
              setInputsValues((prev: any) => {
                return { ...prev, group_id: +value };
              });
            }}
            name="group"
            className="column-4"
            label="Definição:"
            value={`${inputsValues.group_id}`}
            options={selectsValues.groups.map((value: any) => {
              return { value: value.id, label: value.name };
            })}
          />
        )}
        <Input
          onChange={handleChange}
          value={inputsValues.login}
          className="column-4"
          label="Login:"
          name="login"
          type="text"
        />
        <Input
          onChange={handleChange}
          value={inputsValues.password}
          className="column-4"
          label="Senha:"
          name="password"
          type="password"
        />
        {selectsValues.users && (
          <Select
            placeholder="Selecionar Usuário"
            className="column-4"
            label="Dependente de:"
            value={inputsValues.user_dependent_id || ""}
            onSelect={(value) => {
              setInputsValues((prev: any) => {
                return { ...prev, user_dependent_id: value || "" };
              });
            }}
            options={selectsValues.users.map((value: any) => {
              return { value: value.id, label: value.firstname };
            })}
          />
        )}

        {/* Buttons Mobile*/}
        <div className="buttons-holder-mobile">
          {console.log(registerStage)}
          {registerStage && (
            <div className="col-2">
              <Button
                color="light-blue"
                onClick={() => {
                  {registerStage == 1 ? setRegisterStage(registerStage) : setRegisterStage(registerStage - 1)};
                }}
              >
                Voltar
              </Button>
              <Button
                onClick={() => {
                  {registerStage == 4 ? setRegisterStage(registerStage) : setRegisterStage(registerStage + 1)};
                }}
              >
                Próximo
              </Button>
            </div>
          )}

          {registerStage == 4 && !userToEdit && <Button onClick={handleSubmit}>Salvar</Button>}
          {userToEdit && (
            <>
              <Button onClick={handleSubmit}>Salvar</Button>
              <Button color="danger" onClick={cancelEdit}>
                Cancelar
              </Button>
            </>
          )}
        </div>

        {/* Buttons Desktop*/}
        <div className="buttons-holder">
          {userToEdit && (
            <Button className="save-button" color="danger" onClick={cancelEdit}>
              Cancelar
            </Button>
          )}
          <Button className="save-button" onClick={handleSubmit}>
            Salvar
          </Button>
        </div>
      </form>
    </RegisterUserStyle>
  );
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      setLoader,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(RegisterUser);
