//STYLES
import { TaskModalsStyle } from "./style";

//TYPES
import { ITask } from "../../../core/types/Types";

//LIBS
import { useEffect, useState } from "react";

//COMPONENTS
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import { getTaskById } from "core/utils/Api";

interface ITaskModals {
  task: ITask;
  openEdit: () => void;
  modalOpen: boolean;
  historicOpen: boolean;
  closeModals: () => void;
}

const TaskModals = ({
  task,
  openEdit,
  modalOpen,
  historicOpen,
  closeModals,
}: ITaskModals) => {
  const [taskData, setTaskData] = useState<ITask>(task);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [clickedImage, setClikedImage] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    getTasksData();
  }, [task]);

  const getTasksData = async () => {
    const req = await getTaskById(task.id);

    setTaskData(req.data);
  };

  const formatName = () => {
    return task.name.split(" - ").length > 1
      ? task.name
          .split(" - ")
          .filter((value: any, index: number) => index !== 0)
          .join(" - ")
      : task.name;
  };

  const handleImageClick = (imagePath: string) => {
    setClikedImage(imagePath);
    setImageModalOpen(true);
  };

  return (
    <TaskModalsStyle key={taskData.id}>
      <Modal
        header={`Histórico - ${taskData.name}`}
        open={historicOpen}
        onClose={closeModals}
      >
        <div className="modal-history-content">
          <div className="history-content-collaborators">
            <p>
              <span>Colaboradores:</span>{" "}
              {taskData.historic &&
                taskData.historic
                  .map((history, index) => history.user.firstname)
                  .join(", ")}
            </p>
          </div>

          <div className="history-content-list">
            <div className="history-list-title">
              <p>Histórico</p>
            </div>

            <div className="history-list-cards">
              {taskData.historic &&
                taskData.historic.map((history, index) => (
                  <div className="history-card" key={index}>
                    <p>{history.frame.name}</p>
                    <p>{history.user.firstname}</p>
                  </div>
                ))}
            </div>
          </div>

          <Button onClick={closeModals} color="danger">
            Fechar
          </Button>
        </div>
      </Modal>

      <Modal
        header={`Visualizar Tarefa - TK-${taskData.code}`}
        open={modalOpen}
        onClose={closeModals}
      >
        <div className="modal-body">
          <Input
            onChange={() => {}}
            title={`TK-${taskData.code}`}
            value={formatName()}
            label="Título:"
            name="name"
            type="taskData?-title"
            readOnly={true}
          />
          <Input
            onChange={() => {}}
            value={taskData.description}
            label="Descrição:"
            name="description"
            type="textarea"
            readOnly={true}
          />
          <div className="double-inputs">
            <Input
              onChange={() => {}}
              value={taskData.delivery_date}
              label="Entrega:"
              name="delivery_date"
              type="date"
              readOnly={true}
            />
            <Input
              onChange={() => {}}
              value={taskData.quantity_hours}
              label="Qt. Horas:"
              name="quantity_hours"
              type="number"
              readOnly={true}
            />
          </div>
          <div className="double-inputs">
            <Select
              label="Quadro:"
              onSelect={() => {}}
              options={[{ value: "0", label: "Quadro" }]}
            />
          </div>

          {taskData.images && taskData.images?.length > 0 && (
            <div className="img-holder">
              {taskData.images?.map((image) => (
                <img
                  key={image.id}
                  src={image.path}
                  alt={`Task image ${image.id}`}
                  title="Imagem"
                  onClick={() => handleImageClick(image.path)}
                />
              ))}
            </div>
          )}

          {clickedImage && (
            <Modal
              header={"imagem"}
              onClose={() => setImageModalOpen(false)}
              open={imageModalOpen}
              wdt1440="images"
            >
              <img
                src={clickedImage}
                alt="Clicked task image"
                title="Imagem"
                className="modal-image"
              />
            </Modal>
          )}

          <div className="double-inputs">
            <Button color="danger" onClick={closeModals}>
              Fechar
            </Button>
            <Button
              color="green"
              onClick={() => {
                closeModals();
                openEdit();
              }}
            >
              Editar
            </Button>
          </div>
        </div>
      </Modal>
    </TaskModalsStyle>
  );
};

export default TaskModals;
