export const screens = {
    mbS: "only screen and (min-width: 360px)", // MOBILE SMALL
    mb: "only screen and (min-width: 400px)", // MOBILE
    mbB: "only screen and (min-width: 450px)", // MOBILE BIG
    tbS: "only screen and (min-width: 700px)", // TABLET SMALL
    tb: "only screen and (min-width: 768px)", // TABLET
    dsS: "only screen and (min-width: 1024px)", // DESKTOP SMALL
    ds: "only screen and (min-width: 1280px)", // DESKTOP
    dsB: "only screen and (min-width: 1440px)", // DESKTOP BIG

    mbV: "only screen and (min-width: 560px)", // MOBILE VARIANT
    tbV: "only screen and (min-width: 991px)", // TABLET VARIANT
    dsV: "only screen and (min-width: 1680px)", // DESKTOP VARIANT
};
