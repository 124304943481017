//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors, screens } from "../../core/stylesheet/includes";

export const InputStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: start;
  max-width: 100%;
  width: 100%;

  &.checkbox {
    width: fit-content;
  }

  label {
    color: ${colors.darkBlue};
    font-size: 14px;
    font-weight: 500;
  }

  input[type="checkbox"] {
    width: fit-content;

    &:hover {
      cursor: pointer;
    }
  }

  input,
  textarea {
    border-radius: 6px;
    border: 1px solid ${colors.darkBlue};
    background-color: ${colors.white};
    padding: 8px;
    width: 100%;
    color: ${colors.darkBlue};
    font-size: 14px;

    &::placeholder {
      color: ${colors.placeholder};
      font-size: 14px;
      font-weight: 500;
    }
  }

  input[type="file"]::file-selector-button {
    padding: 4px 16px;
    flex-direction: column;
    gap: 8px;
    border-radius: 5px;
    border: 2px solid ${colors.mainBlue};
    background: ${colors.lightBlue};
  }

  input[type="file"] {
    padding: 4px 8px;
  }

  textarea {
    min-height: 100px;
  }

  .hidden {
    display: none;
  }

  .title-input {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;

    .title {
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 10px;
      background: #19366e;
      display: flex;
      padding: 0 12px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      color: ${colors.white};
      font-weight: 500;
    }

    input {
      padding-left: 100px;
    }
  }
`;
