//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors, screens } from "../../core/stylesheet/includes";

export const UserStyle = styled.main`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;

    .pdf{
        width: 80vw;
        max-height: 80vw;
        aspect-ratio: 9/16;
        margin-bottom: 16px;

        @media ${screens.mbB}{
            max-height: 60vw;
        }

        @media ${screens.tb}{
            max-height: 40vw;
        }

        @media ${screens.dsS}{
            max-height: 30vw;
        }
    }

    .user-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        max-width: 1440px;
    }

    .table-holder {
        width: 100%;
        overflow: auto;
        padding-bottom: 16px;
        max-width: 1440px;

        > p {
            font-size: 20px;
        }

        table {
            min-width: 1750px;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar {
            width: 5px;
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: ${colors.mainBlue};
        }
    }

    .notification{
        .content-container {
            max-width: 553px;
            width: 100%;
        }        
    }
    .modal-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .info {
            font-size: 16px;
            font-weight: 700;
            padding-bottom: 16px;
            border-bottom: 1px solid ${colors.grayBlue};

            span {
                font-size: 16px;
                font-weight: 400;
            }
        }

        .buttons-holder {
            display: flex;
            flex-direction: column-reverse;
            gap: 16px;

            @media ${screens.mbB} {
                flex-direction: row;
            }
        }
    }
`;
