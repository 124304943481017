//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors, screens } from "../../core/stylesheet/includes";

export const ConfigStyle = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;
