//STYLES
import { TimekeepingStyle } from "./styles";

//LIBS
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { TbHandClick } from "react-icons/tb";
import { FiLogIn, FiLogOut } from "react-icons/fi";

//CONTAINERS
import Table from "components/Table";

// COMPONENTs
import Pagination from "components/Pagination";
import Button from "components/Button";

//API
import { getAppointments, registerAppointment, updateAppointment } from "core/utils/Api";

//UTILS
import { formatDate } from "core/utils/formatDate";

//TYPES
import { AppointmentHourType, PaginationType, UserState } from "core/types/Types";
import { colors } from "core/stylesheet/includes";
import notify from "core/utils/notify";

// ACTIONs
import { setLoader } from "../../../core/reducers/LoaderReducer/actions";
import { bindActionCreators } from "@reduxjs/toolkit";
import Switch from "components/Switch";

const INITIAL_PAG = {
    total: 0,
    per_page: 3,
    current_page: 1,
    last_page: 1,
    from: null,
    to: null,
};

interface ITimekeeping {
    user: UserState;
    setLoader: (state: boolean) => void;
}

const TableHeader = [
    "Dia do Apontamento",
    "Horário entrada",
    "Horário saída",
    "Tempo Total",
    "Colaborador",
];

const TimekeepingPerHour = ({ user, setLoader }: ITimekeeping) => {
    const [lastAppointment, setLastAppointment] = useState<AppointmentHourType>();
    const [appointmentRows, setAppointmentRows] = useState<{ id: number; list: string[] }[]>([]);
    const [latLng, setLatLng] = useState<{ lat: number | string; lng: number | string }>({
        lat: "",
        lng: "",
    });
    const [pagination, setPagination] = useState<PaginationType>(INITIAL_PAG);
    const [pagePerView, setPagePerView] = useState("3");
    const [tab, setTab] = useState("Hoje");

    let { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        setLoader(true);

        if (!id && user.appointment_type_id !== 2) {
            navigate("/");
            return;
        }

        const location = window.navigator && window.navigator.geolocation;

        if (location) {
            location.getCurrentPosition((position) => {
                setLatLng({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                });
            });
        }

        getAppointmentsData();
    }, []);

    useEffect(() => {
        getAppointmentsData();
    }, [pagePerView, tab]);

    const getAppointmentsData = async (page = 1) => {
        let today = new Date();

        const req = await getAppointments({
            ...(tab === "Hoje" && { date_of_appointment: today.toISOString().split("T")[0] }),
            page: page,
            limit: pagePerView,
            order: "id",
            type_order: "desc",
        });

        if (req.data) {
            let lastAppointent = req;

            if (!lastAppointent.data[0]) {
                lastAppointent = await getAppointments({
                    page: 1,
                    limit: "1",
                    order: "id",
                    type_order: "desc",
                });
            }

            page == 1 && setLastAppointment(lastAppointent.data[0]);

            setPagination(req.pagination);

            setAppointmentRows(
                req.data.map((aptmt: any) => {
                    const totalTime = new Date(
                        (aptmt.date_time_end
                            ? new Date(aptmt.date_time_end).getTime()
                            : new Date().getTime()) - new Date(aptmt.date_time_init).getTime()
                    )
                        .toISOString()
                        .split("T")[1]
                        .split(".")[0];

                    return {
                        id: aptmt.id,
                        list: [
                            formatDate(aptmt.date_of_appointment),
                            aptmt.date_time_init ? aptmt.date_time_init.split(" ")[1] : "--:--",
                            aptmt.date_time_end ? aptmt.date_time_end.split(" ")[1] : "--:--",
                            aptmt.date_time_init ? totalTime : "--:--",
                            aptmt.user.firstname,
                        ],
                    };
                })
            );
        }

        setLoader(false);
    };

    const clockIn = async () => {
        let date = new Date();
        date.setHours(date.getHours() - 3);
        let today = date.toISOString().split("T")[0];
        let currTime = date.toISOString().replace("T", " ").split(".")[0];

        let req;

        let registerData: AppointmentHourType = lastAppointment || {};
        if (lastAppointment?.date_time_end || lastAppointment == undefined) {
            registerData = {
                date_of_appointment: today,
                date_time_init: currTime,
                date_time_end: "",
                latitude_init: latLng.lat,
                longitude_init: latLng.lng,
                latitude_end: "",
                longitude_end: "",
            };

            req = await registerAppointment(registerData);
        }

        if (
            lastAppointment?.date_time_init &&
            !lastAppointment?.date_time_end &&
            lastAppointment.id
        ) {
            registerData = {
                date_of_appointment: lastAppointment.date_of_appointment,
                date_time_init: lastAppointment.date_time_init,
                date_time_end: currTime,
                latitude_end: latLng.lat,
                longitude_end: latLng.lng,
            };

            req = await updateAppointment(lastAppointment.id, registerData);
        }

        if (req.error && req.error.message) {
            notify("error", req.error.message);
        }

        getAppointmentsData();
    };

    return (
        <TimekeepingStyle>
            <div className="content-holder">
                <h1>Apontamento de Horas</h1>
                <Pagination
                    countPages={pagination.last_page}
                    currentPage={pagination.current_page}
                    onPage={(num) => getAppointmentsData(num)}
                    pageQty={pagination.per_page}
                    setPageQty={(num) => setPagePerView(num)}
                    className="pagination-container"
                >
                    <div className="timekeeping-register">
                        <Button
                            className="point"
                            color={
                                lastAppointment?.date_time_init && !lastAppointment?.date_time_end
                                    ? "light-blue"
                                    : "green"
                            }
                            onClick={clockIn}
                        >
                            <TbHandClick />{" "}
                            {lastAppointment?.date_time_init && !lastAppointment?.date_time_end
                                ? "Bater ponto"
                                : "Registrar entrada"}
                        </Button>
                        <div className="hours">
                            <FiLogIn color={colors.mainBlue} />
                            <div className="hours__texts">
                                <span>Última Entrada</span>
                                <p>
                                    {lastAppointment?.date_time_init?.length
                                        ? lastAppointment.date_time_init.split(" ")[1]
                                        : "--:--"}
                                </p>
                            </div>
                        </div>

                        <div className="hours">
                            <FiLogOut color={colors.mainBlue} />
                            <div className="hours__texts">
                                <span>Última Saída</span>
                                <p>
                                    {lastAppointment?.date_time_end?.length
                                        ? lastAppointment.date_time_end.split(" ")[1]
                                        : "--:--"}
                                </p>
                            </div>
                        </div>
                        <div className="switch-holder">
                            <Switch
                                option1="Hoje"
                                option2="Histórico"
                                onChange={(opt) => setTab(opt)}
                            />
                        </div>
                    </div>

                    <div className="table-holder">
                        <Table headerList={TableHeader} bodyList={appointmentRows} />
                    </div>
                </Pagination>
            </div>
        </TimekeepingStyle>
    );
};

const mapDispatchToProps = (dispatch: any) =>
    bindActionCreators(
        {
            setLoader,
        },
        dispatch
    );

const mapStateToProps = (state: any) => ({
    user: state.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(TimekeepingPerHour);
