//LIBS
import { useState, ChangeEvent, MouseEventHandler, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//STYLES
import { RegisterFormStyle } from "./styles";

//ASSETS
import MDSWLogo from "../../assets/images/MDSWLogoTransparent.png";

//COMPONENTS
import Input from "../../components/Input";
import Button from "../../components/Button";

//UTILS
import notify from "core/utils/notify";
import { RegisterData } from "core/types/Types";
import Select from "components/Select";
import {
  estadosBrasileiros,
  estadosBrasileirosUF,
} from "../../core/utils/mockState";
import Title from "components/Title";

interface IRegisterForm {
  onContinue: (data: RegisterData) => void;
  inputValue?: RegisterData;
}

const RegisterForm = ({ onContinue, inputValue }: IRegisterForm) => {
  const navigate = useNavigate();

  const InitialInputs = {
    appointment_type_id: 1,
    firstname: "",
    lastname: "",
    birthday: "",
    email: "",
    contact: "",
    zipcode: "",
    city: "",
    state: "",
    state_abbreviation: "",
    address: "",
    neighborhood: "",
    number: "",
    complement: "",
    login: "",
    password: "",
    confirm_password: "",
    is_active: true,
  };

  const [registerInputs, setRegisterInputs] =
    useState<RegisterData>(InitialInputs);
  const [isRegister, setIsRegister] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);

  const inputsHandler = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = e.target.name;
    const value = e.target.value;

    return setRegisterInputs({ ...registerInputs, [name]: value });
  };

  useEffect(() => {
    if (inputValue) {
      setRegisterInputs(inputValue);
      setStep(3);
    }
  }, []);

  const nextStep = () => {
    if (step === 1) {
      if (
        registerInputs.firstname.trim() === "" ||
        registerInputs.lastname.trim() === "" ||
        registerInputs.birthday.trim() === "" ||
        registerInputs.email.trim() === "" ||
        registerInputs.contact.trim() === ""
      ) {
        notify("error", "Preencha todos os campos corretamente!");
      } else {
        setStep((prev) => prev + 1);
      }
    } else if (step === 2) {
      if (
        registerInputs.zipcode.trim() === "" ||
        registerInputs.city.trim() === "" ||
        registerInputs.state.trim() === "" ||
        registerInputs.state_abbreviation.trim() === "" ||
        registerInputs.address.trim() === "" ||
        registerInputs.neighborhood.trim() === "" ||
        registerInputs.number.trim() === ""
      ) {
        notify("error", "Preencha todos os campos corretamente!");
      } else {
        setStep((prev) => prev + 1);
      }
    } else if (step === 3) {
      if (
        registerInputs.login.trim() === "" ||
        registerInputs.password.trim() === "" ||
        registerInputs.confirm_password.trim() === ""
      ) {
        notify("error", "Preencha todos os campos corretamente!");
      } else {
        onContinue(registerInputs);
      }
    }
  };

  const prevStep = () => {
    if (step > 1) setStep((prev) => prev - 1);
  };

  return (
    <RegisterFormStyle>
      <div className="form-holder">
        <Title>CRIAR CONTA</Title>
        <form>
          {step == 1 && (
            <>
              <Input
                onChange={inputsHandler}
                value={registerInputs.firstname}
                label="Nome:"
                name="firstname"
                type="text"
                placeholder="Preencha seu nome"
              />
              <Input
                onChange={inputsHandler}
                value={registerInputs.lastname}
                label="Sobrenome:"
                name="lastname"
                type="text"
                placeholder="Preencha seu sobrenome"
              />
              <Input
                onChange={inputsHandler}
                value={registerInputs.birthday}
                label="Data de Nascimento:"
                name="birthday"
                type="date"
              />
              <Input
                onChange={inputsHandler}
                value={registerInputs.email}
                label="E-mail:"
                name="email"
                type="email"
                placeholder="Preencha seu e-mail"
              />
              <Input
                onChange={inputsHandler}
                value={registerInputs.contact}
                label="Contato:"
                name="contact"
                type="text"
                placeholder="(99)99999-9999"
              />
            </>
          )}
          {step == 2 && (
            <>
              <div className="double-inputs">
                <Input
                  onChange={inputsHandler}
                  value={registerInputs.zipcode}
                  label="CEP:"
                  name="zipcode"
                  type="text"
                />
                <Select
                  onSelect={(value) => {
                    setRegisterInputs((prevState) => {
                      return { ...prevState, state_abbreviation: value };
                    });
                  }}
                  value={registerInputs.state_abbreviation}
                  label="UF:"
                  name="state_abbreviation"
                  options={estadosBrasileirosUF}
                />
              </div>
              <Select
                onSelect={(value) => {
                  setRegisterInputs((prevState) => {
                    return { ...prevState, state: value };
                  });
                }}
                value={registerInputs.state}
                label="Estado:"
                name="state"
                options={estadosBrasileiros}
              />
              <div className="double-inputs">
                <Input
                  onChange={inputsHandler}
                  value={registerInputs.city}
                  label="Cidade:"
                  name="city"
                  type="text"
                />
                <Input
                  onChange={inputsHandler}
                  value={registerInputs.neighborhood}
                  label="Bairro:"
                  name="neighborhood"
                  type="text"
                />
              </div>
              <div className="double-inputs">
                <Input
                  onChange={inputsHandler}
                  value={registerInputs.address}
                  label="Endereço:"
                  name="address"
                  type="text"
                />
                <Input
                  onChange={inputsHandler}
                  value={registerInputs.number}
                  label="Número:"
                  name="number"
                  type="number"
                />
              </div>
              <Input
                onChange={inputsHandler}
                value={registerInputs.complement}
                label="Complemento:"
                name="complement"
                type="text"
              />
            </>
          )}
          {step == 3 && (
            <>
              <Input
                onChange={inputsHandler}
                value={registerInputs.login}
                label="Login:"
                name="login"
                type="text"
              />
              <Input
                onChange={inputsHandler}
                value={registerInputs.password}
                label="Senha:"
                name="password"
                type="password"
              />
              <Input
                onChange={inputsHandler}
                value={registerInputs.confirm_password}
                label="Confirmar senha:"
                name="confirm_password"
                type="password"
              />
            </>
          )}
        </form>
      </div>

      <div className="button-holder">
        {step == 3 && (
          <Button disabled={isRegister} onClick={nextStep}>
            Continuar
          </Button>
        )}
        {step < 3 && <Button onClick={nextStep}>Próximo</Button>}
        {step > 1 && (
          <Button color="light-blue" onClick={prevStep}>
            Voltar
          </Button>
        )}
        <p className="bottom-text">
          Já possui uma conta?{" "}
          <span onClick={() => navigate("/login")}>Entrar</span>
        </p>
        <div className="brand-holder">
          <img src={MDSWLogo} alt="Logo MDSYstem Web" />
          <p className="brand-name">MDSystem Web Design</p>
        </div>
      </div>
    </RegisterFormStyle>
  );
};

export default RegisterForm;
