//STYLES
import { UserStyle } from "./styles";

//LIBS
import { useNavigate, useParams } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from "react";

//TYPES
import { InvoiceType, PaginationType, UserState } from "../../core/types/Types";

//API
import { getInvoices, getUserMonthlyHours, getUsers, sendEmail } from "core/utils/Api";

//CONTAINERS
import UserInfos from "container/User/Infos";
import UserTimekeeping from "container/User/Timekeeping";
import ModalEditInvoice from "container/User/EditInvoice";

//COMPONENTS
import Table from "components/Table";
import Modal from "components/Modal";
import Input from "components/Input";
import Button from "components/Button";

//CONSTS
import { HeaderArray } from "view/User/constants";

//UTILS
import { currencyFormat } from "core/utils/currencyFormat";
import Pagination from "components/Pagination";
import { formatDate } from "core/utils/formatDate";
import notify from "core/utils/notify";
import UserInvoiceLinks from "container/User/InvoiceLinks";
import Title from "components/Title";

const TiposPagmto = ["Freelancer", "Mensalista", "Por Hora"];

const months = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];

const INITIAL_PAG = {
    total: 0,
    per_page: 3,
    current_page: 1,
    last_page: 1,
    from: null,
    to: null,
};

const User = () => {
    let { id } = useParams();
    const navigate = useNavigate();

    const [userData, setUserData] = useState<UserState>();
    const [notifModal, setNotifModal] = useState<boolean>(false);
    const [notasFiscais, setNotasFiscais] = useState<InvoiceType[]>([]);
    const [pagePerView, setPagePerView] = useState("3");
    const [pagination, setPagination] = useState<PaginationType>(INITIAL_PAG);
    const [openNf, setOpenNf] = useState<boolean>(false);
    const [pdfPath, setPdfPath] = useState<string>("");
    const [pdfNum, setPdfNum] = useState<number>(0);
    const [editNf, setEditNf] = useState<boolean>(false);
    const [invoiceEdit, setInvoiceEdit] = useState<InvoiceType>();
    const [monthlyHours, setMonthlyHours] = useState<number>();
    const [salary, setSalary] = useState<number>();
    const [invoiceMonth, setInvoiceMonth] = useState<string>();

    useEffect(() => {
        if (!Number(id)) {
            navigate("/");
        } else {
            getUser();
        }
    }, []);

    const getUser = async (page = 1) => {
        const req = await getUsers();
        const reqInv = await getInvoices({
            page: page,
            limit: pagePerView,
            order: "id",
            type_order: "desc",
        });

        setPagination(reqInv.data.list.pagination);

        const user = req.data.all.find((user: UserState) => user.id === Number(id));
        setPdfPath(reqInv.data.path);
        setNotasFiscais(reqInv.data.list.data);
        setUserData(user);
    };

    const sendSalaryNotification = async () => {
        const req = await sendEmail({
            user_id: Number(id),
            salary_month: salary,
            reference_month: invoiceMonth,
        });

        if (req.error && req.error.message) {
            notify("error", req.error.message);
        }

        setInvoiceMonth(undefined);
        setSalary(undefined);
        setNotifModal(false);
    };

    return (
        <>
            {userData && (
                <UserStyle>
                    <div className="user-content">
                        <Title>{userData.firstname}</Title>
                        <UserInfos user={userData} />
                        <Pagination
                            countPages={pagination.last_page}
                            currentPage={pagination.current_page}
                            onPage={(num) => getUser(num)}
                            pageQty={pagination.per_page}
                            setPageQty={(num) => setPagePerView(num)}
                        >
                            <div className="table-holder">
                                <Table
                                    onView={(_, id) => {
                                        const nf = notasFiscais.find((nf) => nf.id === id);
                                        setOpenNf(true);
                                        setPdfPath([pdfPath, nf?.pdf].join("/"));
                                        setPdfNum(nf?.number_invoice);
                                    }}
                                    onCalculate={async (_, id) => {
                                        const invoiceEdit = notasFiscais.find((nf) => nf.id == id);

                                        setEditNf(true);
                                        setInvoiceEdit(invoiceEdit);

                                        if (invoiceEdit) {
                                            const periodToGetHours = new Date(
                                                invoiceEdit.end_period
                                            );
                                            const userHours = await getUserMonthlyHours(
                                                invoiceEdit.user_id,
                                                {
                                                    month: periodToGetHours.getMonth() + 1,
                                                    year: periodToGetHours.getFullYear(),
                                                }
                                            );

                                            setMonthlyHours(userHours.data.sum_amount_hour);
                                        }
                                    }}
                                    onNavigate={(_, id) => {
                                        const invoice = notasFiscais.find((nf) => nf.id == id);
                                        if (invoice) {
                                            const periodToGetHours = new Date(invoice.end_period);
                                            setNotifModal(true);
                                            setInvoiceMonth(
                                                `${
                                                    months[periodToGetHours.getMonth()]
                                                }/${periodToGetHours.getFullYear()}`
                                            );
                                        }
                                    }}
                                    headerList={HeaderArray}
                                    bodyList={notasFiscais.map((nf) => {
                                        return {
                                            id: nf.id,
                                            list: [
                                                nf.invoice_status?.name,
                                                TiposPagmto[nf.user?.agreement_id - 1],
                                                nf.user?.agreement_id == 2 ? "True" : "False",
                                                nf.reference_month,
                                                formatDate(nf.start_period),
                                                formatDate(nf.end_period),
                                                nf.observation,
                                                currencyFormat(nf.base_salary),
                                                `${nf.user.amount_time}`,
                                                nf.extra_hour,
                                                currencyFormat(nf.extra_value),
                                                currencyFormat(nf.reimbursement),
                                                currencyFormat(nf.discount),
                                                currencyFormat(nf.salary_month),
                                                nf.status_payment ? "True" : "False",
                                                formatDate(nf.date_payment),
                                            ],
                                        };
                                    })}
                                />
                            </div>
                        </Pagination>
                        <UserTimekeeping user={userData} />
                    </div>
                    <UserInvoiceLinks />
                    <Modal
                        className="notification"
                        onClose={() => {
                            setInvoiceMonth(undefined);
                            setSalary(undefined);
                            setNotifModal(false);
                        }}
                        header={`Notificar - ${userData.firstname}`}
                        open={notifModal}
                    >
                        <div className="modal-content">
                            <p className="info">
                                Salário Base:
                                <span>{currencyFormat(userData.wage)}</span>
                            </p>
                            <Input
                                label="Valor da NF:"
                                name="salary_month"
                                type="number"
                                value={salary || ""}
                                onChange={({ target }) => setSalary(+target.value)}
                            />
                            <div className="buttons-holder">
                                <Button
                                    color="danger"
                                    onClick={() => {
                                        setInvoiceMonth(undefined);
                                        setSalary(undefined);
                                        setNotifModal(false);
                                    }}
                                >
                                    Fechar
                                </Button>
                                <Button onClick={sendSalaryNotification}>Enviar</Button>
                            </div>
                        </div>
                    </Modal>

                    <Modal
                        header={`NF.:${pdfNum}`}
                        open={openNf}
                        onClose={() => setOpenNf(!openNf)}
                    >
                        <iframe className="pdf" src={pdfPath}></iframe>
                        <Button
                            color="danger"
                            onClick={() => {
                                setOpenNf(!openNf);
                                setPdfPath("");
                                setPdfNum(0);
                            }}
                        >
                            Fechar
                        </Button>
                    </Modal>
                    {invoiceEdit && (
                        <ModalEditInvoice
                            hours={monthlyHours}
                            modalOpen={editNf}
                            invoiceToEdit={invoiceEdit}
                            onClose={() => {
                                setEditNf(false);
                                setInvoiceEdit(undefined);
                            }}
                        />
                    )}
                </UserStyle>
            )}
        </>
    );
};

export default User;
