//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors, screens } from "../../core/stylesheet/includes";

export const ExternalInvoiceStyle = styled.main`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 56px 16px;
    gap: 32px;
    width: 100%;
    min-height: 100dvh;
    background-color: ${colors.darkBlue};

    .infos-holder {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
    }

    .info-text {
        font-size: 20px;

        span {
            color: ${colors.darkBlue};
            font-size: 20px;
            font-weight: 700;

            &.success {
                color: ${colors.green};
            }

            &.error {
                color: ${colors.red};
            }
        }
    }

    .content-holder {
        max-width: 1440px;
    }

    .brand-holder {
        display: flex;
        flex-direction: row;
        align-items: center;

        @media ${screens.mbB} {
            padding: 0 16px;
        }

        img {
            max-width: 35px;
            height: auto;

            @media ${screens.mbB} {
                max-width: 50px;
            }
        }

        .brand-name {
            font-size: 16px;
            font-weight: 500;
            color: ${colors.darkBlue};

            @media ${screens.mbB} {
                font-size: 20px;
            }
        }
    }

    .card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
    }

    form{
        display: flex;
        flex-direction: column;
        gap: 8px;

        .label{
            color: ${colors.darkBlue};
            font-size: 20px;
            font-weight: 700;

            span {
                font-size: 16px;
                color: ${colors.darkBlue};
                font-weight: 700;   
            }
        }
    }
`;
