//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors } from "../../core/stylesheet/includes";

export const RegisterStyle = styled.main`
    background-color: ${colors.mainBlue};
    width: 100vw;
    min-height: 100dvh;
    overflow: hidden;
    padding: 24px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
` 