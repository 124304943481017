//LIBS
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//STYLES
import { LoginStyle } from "./styles";

//UTILS
import { validateUser } from "../../core/utils/validation";
import notify from "core/utils/notify";

//FORM
import LoginForm from "../../forms/LoginForm";

//API
import { signIn } from "../../core/utils/Api";

//REDUCERS
import { changeUser } from "../../core/reducers/UserReducer/actions";

interface LoginProps {
  changeUser: () => void;
}

const Login = ({ changeUser }: LoginProps) => {
  const navigate = useNavigate();

  const logIn = async (data: { login: string; password: string }) => {
    let req;

    try{
          req = await signIn(data);

          if (req.data && req.data.token) {
            localStorage.setItem("Token", req.data.token);
            changeUser();
            setTimeout(() => navigate("/"), 500);
            
          } else if (req.error && req.error.message) {
            notify("error", req.error.message);
          }
    }catch (err:any){
      notify("error", err.response.data.error.message);
    }
  };

  return validateUser() ? (
    <Navigate to="/" replace />
  ) : (
    <LoginStyle>
      <LoginForm sendSubmit={(data) => logIn(data)} />
    </LoginStyle>
  );
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      changeUser,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Login);
