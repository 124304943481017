//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors, screens } from "../../../core/stylesheet/includes";

export const InvoiceRegisterStyle = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  gap: 30px;
  border-bottom: 1px solid ${colors.blackBlue};
  padding-bottom: 32px;
  flex-direction: column;

  @media ${screens.tb} {
    flex-direction: row;
  }

  .infos {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    justify-content: space-between;

    .warning {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .danger {
        color: #c80000;
        font-size: 20px;
        font-weight: 700;
        display: flex;
        align-items: center;
      }

      .text {
        color: ${colors.blackBlue};
        font-size: 18px;
        font-weight: 500;
        line-height: 1.4;
      }

      ul > li {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.4;
        color: ${colors.yellow};
      }
    }

    .emission-infos {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .title {
        color: ${colors.blackBlue};
        font-size: 20px;
        font-weight: 700;
      }

      .infos {
        gap: 8px;

        p {
          color: ${colors.blackBlue};
          font-size: 16px;
          font-weight: 400;

          span {
            font-size: 16px;
            color: ${colors.mainBlue};
            font-weight: 700;
          }
        }
      }
    }

    .qty-hours {
      display: flex;
      padding: 24px 32px;
      align-items: center;
      border-radius: 10px;
      border: 1px solid ${colors.mainBlue};
      background: ${colors.lightBlue3};
      color: ${colors.mainBlue};
      font-size: 20px;
      font-weight: 400;
    }
  }

  .form-holder {
    height: fit-content;
    width: 100%;
    background-color: ${colors.white};
    border-radius: 10px;
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
    padding: 16px;

    form {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
`;
