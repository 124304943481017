//STYLES
import { AgreementsStyle } from "./styles";

//LIBS
import { useEffect, useState } from "react";
import { bindActionCreators } from "@reduxjs/toolkit";
import { connect } from "react-redux";

//COMPONENTS
import Table from "components/Table";

//API
import { getAllAgreements } from "core/utils/Api";

// ACTIONs
import { setLoader } from "../../core/reducers/LoaderReducer/actions";

//CONTAINERS
import AgreementsRegister from "container/Agreements";
import Pagination from "components/Pagination";
import Title from "components/Title";

const headersArray = ["Nome", "Ativo"];

interface IAgreements {
    setLoader: (state: boolean) => void;
}

const Agreements = ({ setLoader }: IAgreements) => {
    const [agreements, setAgreements] = useState<any>([]);
    const [agreementToEdit, setGroupToEdit] = useState<any>(undefined);

    useEffect(() => {
        setLoader(true);
        getAgreements();
        setTimeout(() => {
            setLoader(false);
        }, 1000);
    }, []);

    const getAgreements = async () => {
        const req = await getAllAgreements();
        setAgreements(req.data.all);
    };

    return (
        <AgreementsStyle>
            <Title>Acordos</Title>
            <div className="content-holder">
                <AgreementsRegister
                    cancelEdit={() => setGroupToEdit(undefined)}
                    agreementToEdit={agreementToEdit}
                    onSend={() => {
                        setGroupToEdit(undefined);
                        getAgreements();
                    }}
                /> 
                
                <Pagination>
                    <div className="table-holder">
                        <Table
                            onEdit={(item, id, index) => {                                
                                setGroupToEdit(agreements[index]);
                            }}
                            headerList={headersArray}
                            bodyList={agreements.map((agreement: any) => {
                                return {
                                    id: agreement.id,
                                    list: [
                                        agreement.name,
                                        agreement.is_active ? "true" : "false",
                                    ],
                                };
                            })}
                        />
                    </div>
                </Pagination>
            </div>
        </AgreementsStyle>
    );
};

const mapDispatchToProps = (dispatch: any) =>
    bindActionCreators(
        {
            setLoader,
        },
        dispatch
    );

export default connect(null, mapDispatchToProps)(Agreements);
