//STYLES
import { ResetStyle } from "./styles";

//LIBS
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";

//UTILS
import { validateUser } from "core/utils/validation";
import notify from "core/utils/notify";

//COMPONENTS
import Input from "components/Input";
import Button from "components/Button";

//API
import { resetPassword } from "core/utils/Api";

//ASSETS
import MDSWLogo from "../../assets/images/MDSWLogoTransparent.png";
import Title from "components/Title";

const ResetPassword = () => {
    const navigate = useNavigate();
    let [searchParams, _] = useSearchParams();
    const email = searchParams.get("email");
    const token = searchParams.get("token");

    const [newPassword, setNewPassword] = useState<string>("");
    const [passwordVerif, setPasswordVerif] = useState<string>("");

    const updatePassword = async () => {
        if (!newPassword.length || !passwordVerif.length) {
            notify("error", "Preencha todos os campos!");
            return;
        }

        if (newPassword === passwordVerif) {
            const req = await resetPassword({
                token: token,
                email: email,
                password: newPassword,
                password_confirmation: passwordVerif,
            });

            if (req.error && req.error.message) {
                notify("error", req.error.message);
            } else {
                notify("success", "Senha alterada com sucesso!");

                setTimeout(() => {
                    navigate("/login");
                }, 3000);
            }
        } else {
            notify("error", "Senhas divergentes!");
        }
    };

    return validateUser() || !email || !token ? (
        <Navigate to="/" replace />
    ) : (
        <ResetStyle>
            <div className="content-container">
                <Title>ALTERAR SENHA</Title>
                <form>
                    <Input
                        onChange={({ target }) => setNewPassword(target.value)}
                        value={newPassword}
                        label="Nova Senha:"
                        name="password"
                        type="password"
                    />
                    <Input
                        onChange={({ target }) => setPasswordVerif(target.value)}
                        value={passwordVerif}
                        label="Confirmar Senha:"
                        name="password_confirmation"
                        type="password"
                    />
                </form>
                <Button onClick={updatePassword}>Alterar</Button>
                <div className="brand-holder">
                    <img src={MDSWLogo} alt="Logo MDSYstem Web" />
                    <p className="brand-name">MDSystem Web Design</p>
                </div>
            </div>
        </ResetStyle>
    );
};

export default ResetPassword;
