//STYLES
import { MouseEventHandler } from "react";
import { ButtonStyle } from "./styles";
import Spin from "components/Spin";

interface IButton {
  className?: string;
  link?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
  color?:
    | "default"
    | "white"
    | "danger"
    | "light-blue"
    | "green"
    | "yellow"
    | "green-white";
  size?: "s1" | "s2" | "s3" | "s4";
  disabled?: boolean;
  isLoading?: boolean;
}

const Button = ({
  className,
  link,
  onClick,
  children,
  color = "default",
  size = "s1",
  disabled = false,
  isLoading = false,
}: IButton) => {
  return (
    <ButtonStyle className={className}>
      <a href={link}>
        <button
          disabled={disabled || isLoading}
          type="button"
          className={`${color} ${size}`}
          onClick={onClick}
        >
          {isLoading ? <Spin /> : children}
        </button>
      </a>
    </ButtonStyle>
  );
};

export default Button;
