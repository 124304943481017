// LIBs
import styled from "styled-components";

// INCLUDEs
import { colors, screens } from "../../core/stylesheet/includes";

export const Container = styled.div`
  height: auto;
  display: flex;
  background-color: ${colors.white};
  position: fixed;
  z-index: 1;
  width: 100%;

  @media ${screens.tb} {
    width: auto;
    position: unset;
    z-index: auto;
  }

  &.active {
    .drawer-container {
      display: flex;
    }
  }

  .drawer-container {
    position: relative;
    max-width: 100vw;
    width: 100%;
    padding: 32px 16px;
    display: none;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    max-height: 100dvh;
    padding-top: 90px;

    @media ${screens.tb} {
      min-width: 320px;
      max-width: 360px;
    }

    .close-drawer {
      position: absolute;
      top: 90px;
      right: 16px;
      color: ${colors.darkBlue};

      @media ${screens.tb} {
        display: none;
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }

    .title {
      font-size: 32px;
      font-weight: 700;
      color: ${colors.darkBlue};
    }

    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }

    .board-cards-holder {
      padding: 8px;
      height: 100%;
      width: 100%;
      background-color: ${colors.lightBlue};
      border-radius: 10px;
      overflow: auto;
      box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25) inset;
      display: flex;
      flex-direction: column;
      gap: 16px;

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: ${colors.mainBlue};
      }
    }
  }

  .drawer-opener {
    display: none;
    height: 100%;
    width: fit-content;
    background-color: ${colors.white};
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
    padding: 16px;
    padding-top: 80px;
    align-items: center;
    flex-direction: column;
    gap: 8px;

    @media ${screens.tb} {
      display: flex;
    }

    button {
      background-color: ${colors.white};
      border-radius: 5px;
      border: 1px solid ${colors.darkBlue};
      color: ${colors.darkBlue};
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        color: ${colors.darkBlue};
        width: 16px;
        height: 16px;
      }
    }

    svg {
      color: ${colors.darkBlue};
      width: 24px;
      height: 24px;
    }
  }
`;
