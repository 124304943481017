//LIBS
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//STYLES
import { RegisterStyle } from "./styles";

//UTILS
import { validateUser } from "../../core/utils/validation";
import notify from "core/utils/notify";

//FORM
import RegisterForm from "../../forms/RegisterForm";

//API
import { registerUser, signIn } from "../../core/utils/Api";

//REDUCERS
import { changeUser } from "../../core/reducers/UserReducer/actions";
import { useState } from "react";
import Plans from "container/Register/Plans";
import { RegisterData } from "core/types/Types";

interface RegisterProps {
  changeUser: () => void;
}

const Register = ({ changeUser }: RegisterProps) => {
  const navigate = useNavigate();

  const [step, setStep] = useState<number>(1);
  const [registerData, setRegisterData] = useState<RegisterData>();

  const register = async (planId: number) => {
    if (registerData) {
      const req = await registerUser({ ...registerData, plan_id: planId });

      if (req.data && req.data.token) {
        const login = await signIn({
          login: registerData.login,
          password: registerData.password,
        });

        if (login) {
          localStorage.setItem("Token", req.data.token);
          changeUser();
        }

        setTimeout(() => navigate("/"), 1000);
      } else if (req.error && req.error.message) {
        notify("error", req.error.message);
      }
    }
  };

  return validateUser() ? (
    <Navigate to="/" replace />
  ) : (
    <RegisterStyle>
      {step == 1 && (
        <RegisterForm
          onContinue={(data) => {
            setRegisterData(data);
            setStep(2);
          }}
          inputValue={registerData}
        />
      )}
      {step == 2 && (
        <Plans
          onSelect={register}
          onBack={() => {
            setStep(1);
          }}
        />
      )}
    </RegisterStyle>
  );
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      changeUser,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Register);
