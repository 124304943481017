//LIBS
import Title from "components/Title";
import { ConfigStyle } from "./styles";

//CONTAINERS
import ConfigContainer from "container/Config";

const Config = () => {
  return (
    <ConfigStyle>
      <Title>Configurações</Title>
      <ConfigContainer />
    </ConfigStyle>
  );
};

export default Config;
