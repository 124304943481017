//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors, screens } from "../../../core/stylesheet/includes";

export const EditInvoiceStyle = styled.main`
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: 60dvh;
    overflow: auto;
    width: 80vw;

    @media ${screens.tb} {
        width: 100%;
    }

    .info {
        font-size: 16px;
        font-weight: 700;
        padding-bottom: 16px;
        border-bottom: 1px solid ${colors.grayBlue};

        span {
            font-size: 16px;
            font-weight: 400;
        }
    }

    .inputs-holder {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;

        @media ${screens.tb} {
            flex-direction: row;
        }
    }

    .buttons-holder{
        display: flex;
        flex-direction: column-reverse;
        gap:16px;

        @media ${screens.tb} {
            flex-direction: row;
        }
    }
`;
