//LIBS
import { useState, ChangeEvent, MouseEventHandler } from "react";

//STYLES
import { LoginFormStyle } from "./styles";

//ASSETS
import MDSWLogo from "../../assets/images/MDSWLogoTransparent.png";

//COMPONENTS
import Input from "../../components/Input";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import Title from "components/Title";

interface ILoginForm {
  sendSubmit: (data: { login: string; password: string }) => void;
}

const InitialInputs = {
  login: "",
  password: "",
};

const LoginForm = ({ sendSubmit }: ILoginForm) => {
  const navigate = useNavigate();

  const [loginInputs, setLoginInputs] = useState(InitialInputs);
  const [isLogin, setIsLogin] = useState<boolean>(false);

  const inputsHandler = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = e.target.name;
    const value = e.target.value;

    return setLoginInputs({ ...loginInputs, [name]: value });
  };

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setIsLogin(true);
    if (
      Object.values(loginInputs).every(
        (x) => x !== null && x !== "" && x !== undefined
      )
    ) {
      sendSubmit(loginInputs);
    }

    setTimeout(() => {
      setIsLogin(false);
    }, 2000);
  };

  return (
    <LoginFormStyle>
      <div className="form-holder">
        <Title>LOGIN</Title>
        <form onKeyDown={(e: any) => e.key === "Enter" && handleSubmit(e)}>
          <Input
            onChange={inputsHandler}
            value={loginInputs.login}
            label="Login:"
            name="login"
            type="text"
          />
          <div className="password-holder">
            <Input
              onChange={inputsHandler}
              value={loginInputs.password}
              label="Senha:"
              name="password"
              type="password"
            />
            <span onClick={() => navigate("/forgot")} className="forgot">
              Esqueci minha senha
            </span>
          </div>
        </form>
      </div>

      <div className="button-holder">
        <Button disabled={isLogin} onClick={handleSubmit}>
          Entrar
        </Button>
        <p className="bottom-text">
          Não possui uma conta?{" "}
          <span onClick={() => navigate("/register")}>Cadastre-se</span>
        </p>
        <div className="brand-holder">
          <img src={MDSWLogo} alt="Logo MDSYstem Web" />
          <p className="brand-name">MDSystem Web Design</p>
        </div>
      </div>
    </LoginFormStyle>
  );
};

export default LoginForm;
