//STYLES
import { BoardsContainerStyle } from "./style";

//COMPONENTS
import Board from "../../../components/Board";

//LIBS
import { DragDropContext } from "@hello-pangea/dnd";
import { Fragment, useRef, useEffect, useState } from "react";

//TYPES
import { IBoardsHomeType, ITask } from "../../../core/types/Types";

interface IBoards {
  boards: IBoardsHomeType[];
  onDragEnd: (res: any) => void;
  updateBoard: boolean;
  onCreateTask: (boardId: number, boardName: string) => void;
  onEditTask: (task: ITask) => void;
  openView: (task: ITask) => void;
  openHist: (task: ITask) => void;
}

const BoardsContainer = ({
  boards,
  onDragEnd,
  updateBoard,
  onCreateTask,
  onEditTask,
  openHist,
  openView,
}: IBoards) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    const container = containerRef.current;

    const onMouseDown = (e: MouseEvent) => {
      if ((e.target as HTMLElement).closest(".card")) return;
      setIsDragging(true);
      setStartX(e.pageX - container!.offsetLeft);
      setScrollLeft(container!.scrollLeft);
    };

    const onMouseLeave = () => {
      setIsDragging(false);
    };

    const onMouseUp = () => {
      setIsDragging(false);
    };

    const onMouseMove = (e: MouseEvent) => {
      if (!isDragging) return;
      e.preventDefault();
      const x = e.pageX - container!.offsetLeft;
      const walk = (x - startX) * 2; // Aumentar a velocidade do scroll caso necessario.
      container!.scrollLeft = scrollLeft - walk;
    };

    if (container) {
      container.addEventListener("mousedown", onMouseDown);
      container.addEventListener("mouseleave", onMouseLeave);
      container.addEventListener("mouseup", onMouseUp);
      container.addEventListener("mousemove", onMouseMove);
    }

    return () => {
      if (container) {
        container.removeEventListener("mousedown", onMouseDown);
        container.removeEventListener("mouseleave", onMouseLeave);
        container.removeEventListener("mouseup", onMouseUp);
        container.removeEventListener("mousemove", onMouseMove);
      }
    };
  }, [isDragging, startX, scrollLeft]);
  return (
    <BoardsContainerStyle ref={containerRef}>
      <DragDropContext onDragEnd={onDragEnd}>
        {boards &&
          boards.map((board, index) => (
            <Fragment key={index}>
              <Board
                openHist={openHist}
                openView={openView}
                onEditTask={onEditTask}
                onCreateTask={onCreateTask}
                updateLength={updateBoard}
                board={board}
                index={index}
              />
            </Fragment>
          ))}
      </DragDropContext>
    </BoardsContainerStyle>
  );
};

export default BoardsContainer;
