//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors } from "../../core/stylesheet/includes";

export const Titulo = styled.h1`
  font-family: "Paytone One";
  font-weight: 400;
  font-style: normal;
  font-size: 32px;
  color: ${colors.mainBlue};
  font-weight: 500;
  text-align: center;
`;
