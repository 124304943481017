// LIBS
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { ChangeEvent, useState, Fragment } from "react";
import { CgClose } from "react-icons/cg";

// STYLEs
import * as C from "./styles";

//COMPONENTS
import Input from "../Input";
import Button from "../Button";
import BoardCard from "../BoardCard";
import Select from "../Select";

//TYPES
import { IBoardsHomeType } from "../../core/types/Types";

//API
import { registerBoard, updateBoard } from "../../core/utils/Api";

//UTILS
import notify from "../../core/utils/notify";
import Title from "components/Title";

interface IBoardDrawer {
  boards: IBoardsHomeType[];
  onSave: () => void;
  openDrawer: boolean;
  setOpenDrawer: (state: boolean) => void;
}

const formInitialInputs = {
  name: "",
  order: "",
};

const selectInitialValue = "";

const BoardDrawer = ({
  boards,
  onSave,
  openDrawer,
  setOpenDrawer,
}: IBoardDrawer) => {
  const [inputsValues, setInputsValues] = useState<{ [key: string]: any }>(
    formInitialInputs
  );
  const [selectValue, setSelectValue] = useState<string>(selectInitialValue);
  const [boardToEdit, setBoardToEdit] = useState<IBoardsHomeType>();

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputsValues((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async () => {
    let req;

    if (boardToEdit) {
      if (Object.values(boardToEdit).every((input) => input !== "")) {
        req = await updateBoard(boardToEdit.id, {
          ...inputsValues,
          is_active: +selectValue,
        });
      } else {
        notify("error", "Preencha todos os campos devidamente!");
      }
    } else {
      if (Object.values(inputsValues).every((input) => input !== "")) {
        req = await registerBoard(inputsValues);
      } else {
        notify("error", "Preencha todos os campos devidamente!");
      }
    }

    if (req.error && req.error.title) {
      notify("error", req.error.title);
    } else {
      onSave();
      notify(
        "success",
        `Quadro ${boardToEdit ? "editado" : "registrado"} com sucesso!`
      );
      resetValues();
    }
  };

  const resetValues = () => {
    setInputsValues(formInitialInputs);
    setSelectValue(selectInitialValue);
    setBoardToEdit(undefined);
  };

  return (
    <C.Container className={`${openDrawer ? "active" : ""}`}>
      <div className="drawer-container">
        <button
          type="button"
          title="Fechar"
          className="close-drawer"
          onClick={() => setOpenDrawer(!openDrawer)}
        >
          <CgClose />
        </button>
        {/* <p className="title">Quadros</p> */}
        <form>
          <Input
            onChange={handleChange}
            value={inputsValues.name}
            label="Quadro:"
            name="name"
            type="text"
            placeholder="Quadro"
          />
          <Input
            min={0}
            onChange={handleChange}
            value={inputsValues.order}
            label="Ordem:"
            name="order"
            type="number"
            placeholder="1.1 - Separe com ponto"
          />
          {boardToEdit && (
            <Select
              onSelect={(value) => setSelectValue(value)}
              value={selectValue}
              placeholder="Status"
              label="Status:"
              options={[
                { value: "1", label: "Ativo" },
                { value: "0", label: "Inativo" },
              ]}
            />
          )}
          <Button onClick={handleSubmit} size="s1">
            {boardToEdit ? "Editar" : "Adicionar"}
          </Button>
          {boardToEdit && (
            <Button onClick={resetValues} color="danger" size="s1">
              Voltar
            </Button>
          )}
        </form>
        <div className="board-cards-holder">
          {boards.map((board) => (
            <Fragment key={board.id}>
              <BoardCard
                onEdit={() => {
                  setBoardToEdit(board);
                  setInputsValues({ name: board.name, order: board.order });
                  setSelectValue(board.is_active ? "1" : "0");
                }}
                board={board}
              />
            </Fragment>
          ))}
        </div>
      </div>
      <div className="drawer-opener">
        <button type="button" onClick={() => setOpenDrawer(!openDrawer)}>
          {openDrawer ? <FiChevronLeft /> : <FiChevronRight />}
        </button>
        {/* <FiClipboard /> */}
      </div>
    </C.Container>
  );
};

export default BoardDrawer;
