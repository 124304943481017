//STYLES
import { InvoiceRegisterStyle } from "./styles";

//LIBS
import { ChangeEvent, useEffect, useState } from "react";

//ASSETS
import AlertIcon from "../../../assets/icons/alert-triangle.svg";

//COMPONENTS
import Button from "components/Button";
import Input from "components/Input";
import Select from "components/Select";

//API
import { getSettings, registerInvoice } from "core/utils/Api";
import { SettingsType } from "core/types/Types";
import notify from "core/utils/notify";

const months = [
    { label: "Janeiro", value: "1" },
    { label: "Fevereiro", value: "2" },
    { label: "Março", value: "3" },
    { label: "Abril", value: "4" },
    { label: "Maio", value: "5" },
    { label: "Junho", value: "6" },
    { label: "Julho", value: "7" },
    { label: "Agosto", value: "8" },
    { label: "Setembro", value: "9" },
    { label: "Outubro", value: "10" },
    { label: "Novembro", value: "11" },
    { label: "Dezembro", value: "12" },
];

const years = [
    { label: "2020", value: "2020" },
    { label: "2021", value: "2021" },
    { label: "2022", value: "2022" },
    { label: "2023", value: "2023" },
    { label: "2024", value: "2024" },
    { label: "2025", value: "2025" },
];

const INITIAL_VALUES = {
    number_invoice: "",
    pdf: "",
    month: `${new Date().getMonth() + 1}`,
    year: `${new Date().getFullYear()}`,
};

const InvoiceRegisterContainer = () => {
    const [settings, setSettings] = useState<SettingsType>();
    const [values, setValues] = useState<any>(INITIAL_VALUES);
    const [imageUpload, setImageUpload] = useState<File | null>(null);

    useEffect(() => {
        getSettingsData();
    }, []);

    const getSettingsData = async () => {
        const req = await getSettings();

        setSettings(req.data);
    };

    const sendInvoice = async () => {
        let form = new FormData();

        Object.keys(values).map((key: string) => {
            if (key !== "pdf") form.append(key, values[key]);
        });
    
        if(imageUpload){
            form.append("pdf", imageUpload)
        }

        const req = await registerInvoice(form);

        if (req.error && req.error.message) {
            notify("error", req.error.message);
            return;
        }

        setValues(INITIAL_VALUES)
        setImageUpload(null)
        notify("success", "Nota fiscal registrada com sucesso!");
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setValues((prevValues: any) => ({ ...prevValues, [name]: value }));
    };

    return (
        <InvoiceRegisterStyle>
            <div className="infos">
                <div className="warning">
                    <p className="danger">
                        <img src={AlertIcon} alt="Icone de alerta" />
                        ATENÇÃO
                        <img src={AlertIcon} alt="Icone de alerta" />
                    </p>
                    <p className="text">
                        Para que não existam atrasos em seus pagamentos, emita sua Nota Fiscal até o
                        dia 10 de todo mês.
                    </p>
                    <ul>
                        <li>
                            Os Pagamentos são feitos sempre entre os dias 12 e 15 do mês. Mas quando
                            possível, a MD System Web sempre antecipará essas datas.
                        </li>
                    </ul>
                    <div className="emission-infos">
                        <p className="title">Informações para Emissão da Nota Fiscal</p>
                        <div className="infos">
                            <p>
                                <span>CNPJ:</span> {settings?.cnpj}
                            </p>
                            <p>
                                <span>RAZÃO SOCIAL:</span>
                                {settings?.name_fantasy}
                            </p>
                            <p>
                                <span>ENDEREÇO:</span> {settings?.address}, {settings?.complement},
                                {settings?.neighborhood}
                            </p>
                            <p>
                                <span>CEP:</span> {settings?.zipcode}, <span>MUNICÍPIO:</span>{" "}
                                {settings?.city}, <span>UF:</span> {settings?.state_abbreviation}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="qty-hours">
                    Total de horas trabalhadas em {`${new Date().getMonth() + 1}`}/
                    {`${new Date().getFullYear()}`}- 80.00hs
                </div>
            </div>
            <div className="form-holder">
                <form>
                    <Input
                        label="N◦ da NFe:"
                        value={values.number_invoice}
                        onChange={handleChange}
                        name="number_invoice"
                        type="number"
                    />
                    <Input
                        label="Arquivo:"
                        onChangeFile={(e) => {
                            const files: any = e.target.files;

                            setImageUpload(files[0]);
                            handleChange(e);
                        }}
                        value={values.pdf}
                        name="pdf"
                        type="file"
                        accept="application/pdf,application/vnd.ms-excel"
                    />
                    <Select
                        options={months}
                        onSelect={(value) => {
                            setValues((values: any) => ({ ...values, month: value }));
                        }}
                        value={values.month}
                        label="Mês Referencia:"
                    />
                    <Select
                        options={years}
                        value={values.year}
                        onSelect={(value) => {
                            setValues((values: any) => ({ ...values, year: value }));
                        }}
                        label="Ano Referencia:"
                    />
                    <Button onClick={sendInvoice}>Salvar</Button>
                </form>
            </div>
        </InvoiceRegisterStyle>
    );
};

export default InvoiceRegisterContainer;
