//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors } from "../../core/stylesheet/includes";

export const BoardsStyle = styled.div`
    height: fit-content;
    width: 264px;
    background-color: ${colors.white};
    border-radius: 10px;
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
    padding: 16px;
    max-height: 100%;
    min-width: 264px;
    width: 264px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .board {
        &--header {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 16px;

            &:hover {
                cursor: pointer;
            }

            p {
                font-size: 16px;
                font-weight: 500;
            }

            svg {
                width: 20px;
                height: 20px;
            }
        }

        &--body {
            overflow: auto;
            padding-top: 16px;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding-right: 2px;
            padding-bottom: 20px;

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                border-radius: 10px;
                background-color: #f5f5f5;
            }

            &::-webkit-scrollbar {
                width: 5px;
                background-color: #f5f5f5;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                background-color: ${colors.mainBlue};
            }
        }

        &--footer {
            padding-top: 16px;
        }
    }
`;
