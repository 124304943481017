//STYLES
import { GroupsStyle } from "./styles";

//LIBS
import { useEffect, useState } from "react";
import { bindActionCreators } from "@reduxjs/toolkit";
import { connect } from "react-redux";

//COMPONENTS
import Table from "components/Table";

//API
import { getAllGroups } from "core/utils/Api";

// ACTIONs
import { setLoader } from "../../core/reducers/LoaderReducer/actions";

//CONTAINERS
import GroupsRegister from "container/Groups";
import Pagination from "components/Pagination";
import Title from "components/Title";

const headersArray = ["Nome", "Abas", "Status"];

interface IGroups {
  setLoader: (state: boolean) => void;
}

const Groups = ({ setLoader }: IGroups) => {
  const [groups, setGroups] = useState<any>([]);
  const [groupToEdit, setGroupToEdit] = useState<any>(undefined);

  useEffect(() => {
    setLoader(true);
    getGroups();
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, []);

  const getGroups = async () => {
    const req = await getAllGroups();
    setGroups(req.data.all);
  };

  return (
    <GroupsStyle>
      <Title>Grupos</Title>
      <div className="content-holder">
        <GroupsRegister
          cancelEdit={() => setGroupToEdit(undefined)}
          groupToEdit={groupToEdit}
          onSend={() => {
            setGroupToEdit(undefined);
            getGroups();
          }}
        />
        <Pagination>
          <div className="table-holder">
            <Table
              onEdit={(item, id, index) => {
                let formatGroupToEdit = { ...groups[index] };

                formatGroupToEdit.abas = formatGroupToEdit.abas.map(
                  (aba: any) => aba.id
                );

                setGroupToEdit(formatGroupToEdit);
              }}
              headerList={headersArray}
              bodyList={groups.map((group: any) => {
                return {
                  id: group.id,
                  list: [
                    group.name,
                    group.abas.map((aba: any) => aba.name).join(", "),
                    group.is_active ? "Ativo" : "Inativo",
                  ],
                };
              })}
            />
          </div>
        </Pagination>
      </div>
    </GroupsStyle>
  );
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      setLoader,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Groups);
