//STYLES
import { TableContainer } from "./styles";

//LIBS
import { FaSearchPlus } from "react-icons/fa";
import { FiEdit, FiClock } from "react-icons/fi";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import { BiCalculator } from "react-icons/bi";

//COMPONENTS
import Button from "components/Button";

interface ITable {
    headerList?: string[];
    bodyList?: { id: number; list: string[] }[];
    onView?: (item: string[], id: number, index: number) => void;
    onEdit?: (item: string[], id: number, index: number) => void;
    onActivity?: (item: string[], id: number, index: number) => void;
    onCalculate?: (item: string[], id: number, index: number) => void;
    onNavigate?: (item: string[], id: number, index: number) => void;
}

function verifyFunctionQty(array: any[]) {
    if (!Array.isArray(array)) {
        return false;
    }

    const strings = array.filter((item) => typeof item === "string" && item !== undefined);

    return strings.length >= 2;
}

const Table = ({
    headerList,
    bodyList,
    onView,
    onEdit,
    onActivity,
    onCalculate,
    onNavigate,
}: ITable) => {
    const valuesToVerifyQty = [onView, onEdit, onActivity, onCalculate, onNavigate];

    return (
        <TableContainer>
            <thead>
                <tr>
                    {(onView || onEdit) && <td>Ação</td>}
                    {headerList && headerList.map((item) => <td key={item}>{item}</td>)}
                </tr>
            </thead>
            <tbody>
                {bodyList?.length ? (
                    bodyList.map((item, index) => (
                        <tr key={index}>
                            {(onView || onEdit || onActivity) && (
                                <td>
                                    <div
                                        className={
                                            verifyFunctionQty(valuesToVerifyQty)
                                                ? ""
                                                : "grid-actions"
                                        }
                                    >
                                        {onView && (
                                            <Button
                                                className="table-btn"
                                                size="s3"
                                                color="green-white"
                                                onClick={() => onView(item.list, item.id, index)}
                                            >
                                                <FaSearchPlus />
                                            </Button>
                                        )}
                                        {onEdit && (
                                            <Button
                                                className="table-btn"
                                                size="s3"
                                                color="light-blue"
                                                onClick={() => onEdit(item.list, item.id, index)}
                                            >
                                                <FiEdit />
                                            </Button>
                                        )}
                                        {onActivity && (
                                            <Button
                                                className="table-btn"
                                                size="s3"
                                                color="green"
                                                onClick={() =>
                                                    onActivity(item.list, item.id, index)
                                                }
                                            >
                                                <FiClock />
                                            </Button>
                                        )}
                                        {onCalculate && (
                                            <Button
                                                className="table-btn"
                                                size="s3"
                                                color="green"
                                                onClick={() =>
                                                    onCalculate(item.list, item.id, index)
                                                }
                                            >
                                                <BiCalculator />
                                            </Button>
                                        )}
                                        {onNavigate && (
                                            <Button
                                                className="table-btn"
                                                size="s3"
                                                color="light-blue"
                                                onClick={() =>
                                                    onNavigate(item.list, item.id, index)
                                                }
                                            >
                                                <FaArrowUpRightFromSquare />
                                            </Button>
                                        )}
                                    </div>
                                </td>
                            )}
                            {item.list.map((str, i) => (
                                <td key={i} dangerouslySetInnerHTML={{ __html: str }}></td>
                            ))}
                        </tr>
                    ))
                ) : (
                    <tr className="no-info">
                        <td
                            colSpan={
                                (onView || onEdit || onActivity || onCalculate || onNavigate) &&
                                headerList?.length
                                    ? headerList.length + 1
                                    : headerList?.length
                            }
                        >
                            nenhuma informação encontrada
                        </td>
                    </tr>
                )}
            </tbody>
        </TableContainer>
    );
};

export default Table;
