import Title from "components/Title";
import ServicesContainer from "container/Services";
import { UsersStyle } from "view/Users/styles";

const Services = () => {
  return (
    <UsersStyle>
      <Title>Services</Title>
      <div className="content-holder">
        <ServicesContainer />
      </div>
    </UsersStyle>
  );
};

export default Services;
