//STYLES
import { UserInfosStyle } from "./styles";

//LIBS
import { useEffect, useState } from "react";

//COMPONENTS
import Input from "components/Input";
import Button from "components/Button";

//TYPES
import { UserState } from "core/types/Types";
import { calculateUserHours } from "core/utils/Api";
import notify from "core/utils/notify";
import { currencyFormat } from "core/utils/currencyFormat";

interface IUserInfos {
    user: UserState;
}

const UserInfos = ({ user }: IUserInfos) => {
    const [hours, setHours] = useState<number>();
    const [calcValue, setCalcValue] = useState<number>();

    const userInfos = [
        [
            { label: "Nome", value: user.firstname },
            { label: "CPF", value: user.taxvat_primary },
            { label: "CNPJ", value: user.taxvat_secondy },
            { label: "Status", value: user.is_active ? "Ativo" : "Inativo" },
        ],
        [
            { label: "Agência", value: user.bank_agency },
            { label: "Conta", value: user.bank_account },
            { label: "Operação", value: user.bank_operation },
            { label: "Banco", value: user.bank },
            { label: "PIX", value: user.pix },
        ],
    ];

    const calculateHours = async () => {
        const req = await calculateUserHours({ hours: hours, user_id: user.id });

        if (req.error && req.error.message) {
            notify("error", req.error.message);
        }

        setCalcValue(req.data);
    };

    return (
        <UserInfosStyle>
            <div className="card">
                <div className="infos-holder">
                    {userInfos.map((infos, index) => (
                        <div className="infos" key={index}>
                            {infos.map((info) => (
                                <p key={info.label}>
                                    <span>{info.label}:</span> {info.value}
                                </p>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <div className="card">
                <Input
                    label="Qt. Horas (valor separado por ponto, Ex.:123.45)"
                    name="hours"
                    onChange={({ target }) => setHours(+target.value)}
                    type="number"
                    placeholder="Horas trabalhadas"
                />
                {calcValue && <div className="value">Resultado: {currencyFormat(calcValue)}</div>}
                <Button onClick={calculateHours}>Calcular</Button>
            </div>
        </UserInfosStyle>
    );
};

export default UserInfos;
