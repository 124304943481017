// STYLEs
import * as C from "./styles";

//COMPONENTS
import Button from "../Button";

//LIBS
import { FiEdit } from "react-icons/fi";

//TYPES
import { IBoardsHomeType } from "../../core/types/Types";

interface IBoardCard {
    board: IBoardsHomeType;
    onEdit?: () => void;
}

const BoardCard = ({ board, onEdit }: IBoardCard) => {
    return (
        <C.Container>
            <div className="card-item actions">
                <p className="label">Ações:</p>
                <div className="buttons-holder">
                    <Button onClick={onEdit} size="s3" color="light-blue">
                        <FiEdit />
                    </Button>
                </div>
            </div>
            <div className="card-item action">
                <p className="label">Quadro:</p>
                <div className="value">{board.name}</div>
            </div>
            <div className="card-item action">
                <p className="label">Ordem:</p>
                <div className="value">{board.order}</div>
            </div>
            <div className="card-item action">
                <p className="label">Status:</p>
                <div className="value">{board.is_active ? "Ativo" : "Inativo"}</div>
            </div>
            <div className="card-item action">
                <p className="label">QT. Tarefas:</p>
                <div className="value">{board.tasks_count}</div>
            </div>
        </C.Container>
    );
};

export default BoardCard;
