//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors, screens } from "../../../core/stylesheet/includes";

export const PlansStyle = styled.main`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 120px 0 56px;
    gap: 32px;
    max-width: 800px;
    width: 100%;

    .card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
    }

    .card-holder {
        padding: 8px;
    }

    .slick-slider {
        width: 100%;
    }

    .brand-holder {
        display: flex;
        flex-direction: row;
        align-items: center;

        @media ${screens.mbB} {
            padding: 0 16px;
        }

        img {
            max-width: 35px;
            height: auto;

            @media ${screens.mbB} {
                max-width: 50px;
            }
        }

        .brand-name {
            font-size: 16px;
            font-weight: 500;
            color: ${colors.darkBlue};

            @media ${screens.mbB} {
                font-size: 20px;
            }
        }
    }

    .Slider-Dots {
        position: absolute;
        bottom: -24px;
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;

        & li {
            position: relative;
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0 5px;
            padding: 0;
            cursor: pointer;

            &.slick-active button:before {
                opacity: 1;
                color: ${colors.mainBlue};
            }

            & button {
                font-size: 0;
                line-height: 0;
                display: block;
                width: 20px;
                height: 20px;
                padding: 5px;
                cursor: pointer;
                color: transparent;
                border: 0;
                outline: none;
                background: transparent;

                &:before {
                    font-family: "slick";
                    font-size: 12px;
                    line-height: 20px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 20px;
                    height: 20px;
                    content: "•";
                    text-align: center;
                    opacity: 0.5;
                    color: ${colors.grayBlue};
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }
            }
        }
    }
`;
