export const HeaderArray = [
    "Status",
    "Tipo Pagto",
    "Emite NFe",
    "Mês Referência",
    "Inicio Período",
    "Final Período",
    "Obs ao Prestador",
    "Salário Base",
    "Hs Trab.",
    "Hs Extras",
    "Valor Extra",
    "Reembolso",
    "Desconto",
    "Salário Mês",
    "Status Pagto",
    "Data Pagto",
];

export const BodyArray = [
    {
        id: 1,
        list: [
            "Aguardando",
            "Padrão",
            "true",
            "Ago/2023",
            "01/08/2023",
            "31/08/2023",
            "...",
            "R$0000,00",
            "80hs",
            "00hs",
            "R$0000,00",
            "R$0000,00",
            "R$0000,00",
            "R$0000,00",
            "false",
            "31/08/2023",
        ],
    },
];
