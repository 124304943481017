// LIBs
import { jwtDecode } from "jwt-decode";

// TYPES
import * as types from "./types";
import sign from "jwt-encode";

const chatsName = "@optimus:chats";

const INITIAL_STATE = [{}];

const chats = localStorage.getItem(chatsName);

const ChatsReducer = (state = INITIAL_STATE, action: any) => {
    let newState = [...state];

    if (chats) {
        Object.assign(newState, JSON.parse(jwtDecode(chats)));
    }

    switch (action.type) {
        case types.CHANGE_CHATS:
            localStorage.setItem(chatsName, sign(JSON.stringify(action.payload.data), "chats"));
            newState = [...action.payload.data];
            return newState;
        default:
            return newState;
    }
};

export default ChatsReducer;
