// TYPES
import * as types from "./types";

const INITIAL_STATE = false;

const LoaderReducer = (state = INITIAL_STATE, action: any) => {
    let newState = INITIAL_STATE;

    switch (action.type) {
        case types.CHANGE_LOADER:
            newState = action.payload;
            return newState;
        case types.CLEAR_LOADER:
            newState = INITIAL_STATE;
            return newState;
        default:
            return newState;
    }
};

export default LoaderReducer;
