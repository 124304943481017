//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors, screens } from "../../../core/stylesheet/includes";

export const FilterAndRegisterStyle = styled.div`
    width: 100%;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .title {
        color: ${colors.blackBlue};
        font-size: 32px;
        font-weight: 400;
    }

    .appointments-infos {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        gap: 24px;

        @media ${screens.tb} {
            flex-direction: row;
            gap: 16px;
        }

        .filter {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .filter-holder {
                display: flex;
                align-items: center;
                flex-direction: column;
                gap: 8px;

                @media ${screens.tb} {
                    flex-direction: row;
                }

                & > div:last-child {
                    width: 100%;

                    @media ${screens.tb} {
                        width: fit-content;
                    }
                }

                svg {
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .infos {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 8px;

            @media ${screens.tb} {
                width: 65%;
            }

            .danger {
                color: #c80000;
                font-size: 20px;
                font-weight: 700;
                display: flex;
                align-items: center;
            }

            .text {
                color: ${colors.blackBlue};
                font-size: 18px;
                font-weight: 500;
                line-height: 1.4;

                span {
                    color: ${colors.red};
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 1.4;
                }
            }
        }
    }

    .appointments-register {
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: 100%;

        @media ${screens.tb} {
            max-width: 80%;
        }

        .register-holder {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 8px;

            @media ${screens.tb} {
                flex-direction: row;
            }

            .small-input {
                max-width: 100%;

                @media ${screens.tb} {
                    max-width: 25%;
                }
            }

            & > div:last-child {
                width: 100%;

                @media ${screens.tb} {
                    width: fit-content;
                }
            }

            svg {
                width: 19px;
                height: 19px;
            }
        }
    }
`;
