import { Container } from "./styles";

const Spin = () => {
  return (
    <Container>
      <div className="LoadingDetails">
        <div className="LoadingDetails__spin">
          <div />
        </div>
      </div>
    </Container>
  );
};

export default Spin;
