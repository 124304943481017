//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors, screens } from "../../core/stylesheet/includes";

export const ChangePasswordStyle = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  height: 100%;

  .content-container {
    background-color: ${colors.white};
    border-radius: 20px;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.35);
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
    justify-content: center;
    padding: 24px;
    width: 100%;
    max-width: 450px;

    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
`;
