// LIBs
import styled from "styled-components";

// INCLUDEs
import { colors } from "../../core/stylesheet/includes";

export const TableContainer = styled.table`
    width: 100%;
    border-spacing: 0;
    
    thead {
        tr {
            td {
                background-color: ${colors.mainBlue};
                padding: 0;
                color: ${colors.white};
                padding: 8px;
                font-weight: 500;
                font-size: 14px;

                &:first-child {
                    border-radius: 10px 0 0 0;
                }

                &:last-child {
                    border-radius: 0 10px 0 0;
                }
            }
        }
    }

    tbody {
        tr {
            &.no-info {
                td{
                    border-radius: 0 0 10px 10px !important;
                }
            }

            td {
                .grid-actions{
                    display: grid;
                    grid-template-columns: repeat(2, min-content);
                    gap: 4px;
                }

                .table-btn{
                    width: fit-content;
                }
                
                border-bottom: 1px solid ${colors.mainBlue};
                padding: 16px 8px;
                background-color: ${colors.white};
                color: ${colors.mainBlue};
                font-weight: 500;
                font-size: 14px;

                &:first-child {
                    border-left: 1px solid ${colors.mainBlue};
                }

                &:last-child {
                    border-right: 1px solid ${colors.mainBlue};
                }
            }

            &:last-child {
                td {
                    &:first-child {
                        border-radius: 0 0 0 10px;
                    }

                    &:last-child {
                        border-radius: 0 0 10px 0;
                    }
                }
            }
        }
    }
`;
