//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors, screens } from "../../core/stylesheet/includes";

export const TimekeepingStyle = styled.main`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
