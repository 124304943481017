//STYLES
import { UserTimekeepingStyle } from "./styles";

//COMPONENTS
import Select from "components/Select";
import Button from "components/Button";
import Table from "components/Table";
import Modal from "components/Modal";

//LIBS
import { FiFilter } from "react-icons/fi";
import { useEffect, useState, ChangeEvent } from "react";
import Input from "components/Input";
import Pagination from "components/Pagination";
import {
    getAppointmentById,
    getAppointments,
    registerAppointment,
    updateAppointment,
} from "core/utils/Api";
import { AppointmentHourType, UserState } from "core/types/Types";
import { formatDate } from "core/utils/formatDate";
import notify from "core/utils/notify";

interface IUserTimekeeping {
    user: UserState;
}

const months = [
    { label: "Janeiro", value: "1" },
    { label: "Fevereiro", value: "2" },
    { label: "Março", value: "3" },
    { label: "Abril", value: "4" },
    { label: "Maio", value: "5" },
    { label: "Junho", value: "6" },
    { label: "Julho", value: "7" },
    { label: "Agosto", value: "8" },
    { label: "Setembro", value: "9" },
    { label: "Outubro", value: "10" },
    { label: "Novembro", value: "11" },
    { label: "Dezembro", value: "12" },
];

const years = [
    { label: "2020", value: "2020" },
    { label: "2021", value: "2021" },
    { label: "2022", value: "2022" },
    { label: "2023", value: "2023" },
    { label: "2024", value: "2024" },
    { label: "2025", value: "2025" },
];

const headersArrayPerTask = ["Tarefa", "Data", "Horas", "Detalhes", "Colaborador"];

const headersArrayPerHour = [
    "Dia do Apontamento",
    "Horário entrada",
    "Horário saída",
    "Tempo Total",
    "Colaborador",
];

const UserTimekeeping = ({ user }: IUserTimekeeping) => {
    const [appointment, setAppointment] = useState<AppointmentHourType>();
    const [appointmentRows, setAppointmentRows] = useState<{ id: number; list: string[] }[]>([]);
    const [dataFilter, setDataFilter] = useState<{ month: string; year: string }>({
        month: `${new Date().getMonth() + 1}`,
        year: `${new Date().getFullYear()}`,
    });

    useEffect(() => {
        getAppointmentsData();
    }, [dataFilter]);

    const getAppointmentsData = async (page = 1) => {
        const reqAptmt = await getAppointments({
            all: true,
            page: page,
            month: +dataFilter.month,
            year: +dataFilter.year,
            limit: 0,
            order: "id",
            type_order: "desc",
        });

        const appointments = reqAptmt.data.filter(
            (aptmt: AppointmentHourType) => aptmt.user_id === user.id
        );

        setAppointmentRows(
            appointments.map((aptmt: any) => {
                const totalTime = new Date(
                    (aptmt.date_time_end
                        ? new Date(aptmt.date_time_end).getTime()
                        : new Date().getTime()) - new Date(aptmt.date_time_init).getTime()
                )
                    .toISOString()
                    .split("T")[1]
                    .split(".")[0];

                return {
                    id: aptmt.id || 0,
                    list:
                        user.appointment_type_id === 1
                            ? [
                                  aptmt.task?.name || "",
                                  aptmt.date_of_appointment
                                      ? formatDate(aptmt.date_of_appointment)
                                      : "--/--/--",
                                  `${aptmt.amount_hours}`,
                                  aptmt.text || "",
                                  aptmt.user?.firstname || "",
                              ]
                            : [
                                  aptmt.date_of_appointment
                                      ? formatDate(aptmt.date_of_appointment)
                                      : "",
                                  aptmt.date_time_init
                                      ? aptmt.date_time_init.split(" ")[1]
                                      : "--:--",
                                  aptmt.date_time_end ? aptmt.date_time_end.split(" ")[1] : "--:--",
                                  aptmt.date_time_init ? totalTime : "--:--",
                                  aptmt.user.firstname,
                              ],
                };
            })
        );
    };

    const changeAppointment = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const name = e.target.name;
        const value = e.target.value;
        setAppointment((values: any) => ({ ...values, [name]: value }));
    };

    const sendAppointmentData = async () => {
        if (appointment && appointment.id) {
            const req = await updateAppointment(appointment.id, appointment);

            getAppointmentsData();
            setAppointment(undefined);
            if (req.error && req.error.title) {
                notify("error", req.error.title);
                return;
            }
            notify("success", "Apontamento registrado com sucesso!");
        }
    };

    return (
        <UserTimekeepingStyle>
            <div className="filter">
                <p className="title">Atividades Gerais</p>
                <div className="filter-holder">
                    <Select
                        options={months}
                        value={dataFilter.month}
                        onSelect={(value) =>
                            setDataFilter((prev) => {
                                return { ...prev, month: value };
                            })
                        }
                    />
                    <Select
                        options={years}
                        value={dataFilter.year}
                        onSelect={(value) =>
                            setDataFilter((prev) => {
                                return { ...prev, year: value };
                            })
                        }
                    />
                    <Button size="s3" color="white">
                        <FiFilter />
                    </Button>
                </div>
            </div>

            <div className="content-holder">
                <p className="title">Apontamento de Horas</p>
                <Pagination>
                    <div className="table-holder">
                        <Table
                            onEdit={
                                user.appointment_type_id === 1
                                    ? async (_, id) => {
                                          setAppointment((await getAppointmentById(id)).data);
                                      }
                                    : undefined
                            }
                            headerList={
                                user.appointment_type_id === 1
                                    ? headersArrayPerTask
                                    : headersArrayPerHour
                            }
                            bodyList={appointmentRows}
                        />
                    </div>
                </Pagination>
                <Modal
                    header="Editar Apontamento"
                    open={appointment !== undefined}
                    onClose={() => {
                        setAppointment(undefined);
                    }}
                >
                    <div className="modal-appointment-content">
                        <Input
                            className="small-input"
                            placeholder="dd/mm/yyyy"
                            value={appointment?.date_of_appointment || ""}
                            onChange={changeAppointment}
                            label="Data:"
                            name="date_of_appointment"
                            type="date"
                        />
                        <Input
                            className="small-input"
                            placeholder="Horas Trabalhadas"
                            label="Horas Trabalhadas:"
                            value={appointment?.amount_hours || ""}
                            onChange={changeAppointment}
                            name="amount_hours"
                            type="number"
                        />
                        <Input
                            placeholder="Resumo da atividade"
                            label="Resumo da Atividade:"
                            value={appointment?.text || ""}
                            onChange={changeAppointment}
                            name="text"
                            type="text"
                        />
                        <div className="modal-appointment-group">
                            <Button
                                color="danger"
                                onClick={() => {
                                    setAppointment(undefined);
                                }}
                            >
                                Cancelar
                            </Button>
                            <Button onClick={sendAppointmentData} color="green">
                                Editar
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        </UserTimekeepingStyle>
    );
};

export default UserTimekeeping;
