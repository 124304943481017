//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors, screens } from "../../core/stylesheet/includes";

export const AgreementsRegisterStyle = styled.main`
    background-color: ${colors.white};
    border-radius: 10px;
    width: 100%;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
    padding: 24px;

    @media ${screens.tb} {
        width: 80%;
    }

    @media ${screens.tbV} {
        width: 50%;
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .checkbox {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;

            &--holder {
                display: flex;
                gap: 8px;
                min-width: 100%;
                flex-direction: row;

                @media ${screens.mbB} {
                    min-width: calc(50% - 16px);
                }

                p {
                    color: ${colors.darkBlue};
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }
    }
`;
