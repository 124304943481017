//LIBS
import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

//PAGES
import Home from "../../view/Home";
import Login from "../../view/Login";
import Chats from "view/Chats";
import Profile from "view/Profile";
import Invoices from "view/Invoices";
import Timekeeping from "view/Timekeeping";
import Config from "view/Config";
import Users from "view/Users";
import Groups from "view/Groups";
import User from "view/User";
import Register from "view/Register";
import Forgot from "view/Forgot";
import ChangePassword from "view/ChangePassword";
import ResetPassword from "view/ResetPassword";
import ExternalInvoice from "view/ExternalInvoice";
import Services from "view/Services";

//LAYOUT
import App from "../../App";

//COMPONENT
import PrivateRoute from "../../components/PrivateRoute";
import Agreements from "view/Agreements";
import Customers from "view/Customers";
import Order from "view/Order";

const Router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      {/**
       *   Dentro do Componente abaixo adicionar rotas que possuirão o Header padrão da aplicação.
       *   Os demais Componentes que não possuirem o Header padrão mante-los fora da tag abaixo
       */}
      <Route
        path=""
        element={<App />}
        errorElement={<Navigate to="/login" replace />}
      >
        <Route path="/" element={<PrivateRoute component={<Home />} />} />
        <Route path="/Chats" element={<PrivateRoute component={<Chats />} />} />

        <Route
          path="/Profile"
          element={<PrivateRoute component={<Profile />} />}
        />
        <Route
          path="/Invoice"
          element={<PrivateRoute component={<Invoices />} />}
        />
        <Route
          path="/Timekeeping/:id"
          element={<PrivateRoute component={<Timekeeping />} />}
        />
        <Route
          path="/Timekeeping"
          element={<PrivateRoute component={<Timekeeping />} />}
        />
        <Route
          path="/Config"
          element={<PrivateRoute component={<Config />} />}
        />
        <Route path="/Users" element={<PrivateRoute component={<Users />} />} />
        <Route
          path="/Customers"
          element={<PrivateRoute component={<Customers />} />}
        />
        <Route path="/Order" element={<PrivateRoute component={<Order />} />} />
        <Route
          path="/Users/:id"
          element={<PrivateRoute component={<User />} />}
        />
        <Route
          path="/ChangePassword"
          element={<PrivateRoute component={<ChangePassword />} />}
        />
        {/* <Route path="/Abas" element={<PrivateRoute component={<Abas />} />} /> */}
        <Route
          path="/Services"
          element={<PrivateRoute component={<Services />} />}
        />
        <Route
          path="/Groups"
          element={<PrivateRoute component={<Groups />} />}
        />
        <Route
          path="/Agreements"
          element={<PrivateRoute component={<Agreements />} />}
        />
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/Register" element={<Register />} />
      <Route path="/Forgot" element={<Forgot />} />
      <Route path="/Reset" element={<ResetPassword />} />
      <Route path="/Invoice/:token" element={<ExternalInvoice />} />
    </Route>
  ),
  {
    // basename: '/hml/optimus/',
  }
);

export default Router;
