//STYLES
import { TimekeepingStyle } from "./styles";

//LIBS
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { connect } from "react-redux";

//CONTAINERS
import TimekeepingPerTask from "container/Timekeeping/PerTask";
import TimekeepingPerHour from "container/Timekeeping/PerHour";

//TYPES
import { UserState } from "core/types/Types";

interface ITimekeeping {
    user: UserState;
}

const Timekeeping = ({ user }: ITimekeeping) => {
    let { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if ((!Number(id) && id) || (!id && user.appointment_type_id !== 2)) {
            navigate("/");
            return;
        }
    }, []);

    return (
        <TimekeepingStyle>
            {user.appointment_type_id !== 1 ? <TimekeepingPerHour /> : <TimekeepingPerTask />}
        </TimekeepingStyle>
    );
};

const mapStateToProps = (state: any) => ({
    user: state.user,
});

export default connect(mapStateToProps, null)(Timekeeping);
