//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors } from "../../../core/stylesheet/includes";

export const InvoiceTableStyle = styled.div`
    width: 100%;
    max-width: 1440px;
    display: flex;

    .table-holder {
        max-width: 100%;
        padding-bottom: 16px;
        overflow: auto;
    }
`;
