//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors, screens } from "../../core/stylesheet/includes";

export const InvoicesStyle = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  .pdf {
    width: 80vw;
    max-height: 80vw;
    aspect-ratio: 9/16;
    margin-bottom: 16px;

    @media ${screens.mbB} {
      max-height: 60vw;
    }

    @media ${screens.tb} {
      max-height: 40vw;
    }

    @media ${screens.dsS} {
      max-height: 30vw;
    }
  }
`;
