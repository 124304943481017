//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors, screens } from "../../../core/stylesheet/includes";

export const TimekeepingStyle = styled.main`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content-holder {
        width: 100%;
        max-width: 1440px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
    }

    .table-holder {
        width: 100%;
        overflow: auto;
        
        table{
            min-width: 960px;
        }
    }

    .content-container {
        max-width: 553px;
        width: 100%;

        .modal-appointment-content {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .modal-appointment-group {
                display: flex;
                gap: 16px;
            }
        }
    }
`;
