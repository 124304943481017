import CustomersContainer from "container/Customers";
// import { CustomersStyle } from "./styles";
import Title from "components/Title";
import { UsersStyle } from "view/Users/styles";

const Customers = () => {
  return (
    <UsersStyle>
      <Title>Clientes</Title>
      <div className="content-holder">
        <CustomersContainer />
      </div>
    </UsersStyle>
  );
};

export default Customers;
