//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors, screens } from "../../core/stylesheet/includes";

export const UsersStyle = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;

  .content-holder {
    width: 100%;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;

    main {
      width: 100%;
    }

    @media ${screens.tb} {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
    }
  }

  .double-tables {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 100%;
    width: 100%;

    @media ${screens.tb} {
      width: calc(100% - 352px);
    }

    @media ${screens.dsS} {
      flex-direction: row;
    }

    .tables-content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      max-width: 100%;
      width: 100%;

      @media ${screens.dsS} {
        max-width: calc(100% - 12px);
      }

      .title {
        font-size: 32px;
        font-weight: 500;
      }
    }

    .table-holder {
      width: 100%;
      overflow: auto;
      padding-bottom: 16px;

      > p {
        font-size: 20px;
      }

      table {
        min-width: 750px;
      }
    }
  }
`;
