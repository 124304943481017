//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors, screens } from "../../core/stylesheet/includes";

export const PlansStyle = styled.main`
    background-color: ${colors.white};
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .selectedPlanCard {
        opacity: 0.5;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px;
        background-color: ${colors.mainBlue};

        p {
            color: ${colors.white};
            font-size: 28px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }

    .prices {
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .value {
            color: ${colors.mainBlue};
            font-size: 32px;
            font-weight: 600;
        }

        .discount {
            font-size: 16px;
        }
    }

    .list {
        .advantage {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 16px;
            padding: 16px;
            width: 100%;
            background-color: ${colors.grayBlue};

            .advantage-content {
                display: flex;
                align-items: center;
                width: 190px;
                gap: 16px;
                p {
                    color: $black;
                    font-size: 18px;
                    font-weight: 400;
                }
            }
        }

        .advantage.white {
            background-color: ${colors.white};
        }
    }

    .btn {
        padding: 16px;
    }
`;
