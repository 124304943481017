// LIBs
import React from "react";

// STYLEs
import * as C from "./styles";

// ICONs
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";

interface IPagination {
  children: React.ReactNode;
  className?: string;
  id?: string;
  navigation?: boolean;
  countPages?: number;
  currentPage?: number;
  onPage?: (pageToGo: number) => void;
  pageQty?: number;
  setPageQty?: (value: string) => void;
}

const Pagination = ({
  children,
  className,
  id,
  navigation = true,
  countPages,
  currentPage,
  onPage,
  pageQty,
  setPageQty,
}: IPagination) => {
  return (
    <C.Container className={`${className}`} id={id || ""}>
      {children}

      {countPages && currentPage && onPage && (
        <div className="Pagination__pagination-container">
          {navigation && (
            <div className="Pagination__pagination">
              {countPages > 1 && currentPage !== 1 && (
                <div
                  className="Pagination__pagination--button"
                  onClick={() => onPage(currentPage - 1)}
                  style={{ paddingLeft: 2 }}
                >
                  <BsChevronLeft />
                </div>
              )}

              {countPages &&
                Array(countPages)
                  .fill(0)
                  .map((element, index) => {
                    let paginationButtonClass =
                      "Pagination__pagination--button";

                    if (index + 1 === currentPage)
                      paginationButtonClass += " active";

                    const render = (
                      <div
                        onClick={() => onPage(index + 1)}
                        className={paginationButtonClass}
                        key={index}
                      >
                        <p>{index + 1}</p>
                      </div>
                    );

                    if (index + 1 === currentPage) {
                      return render;
                    } else if (index + 1 === currentPage + 1) {
                      return render;
                    } else if (index + 1 === currentPage - 1) {
                      return render;
                    } else {
                      return false;
                    }
                  })}

              {countPages > 1 && countPages !== currentPage && (
                <div
                  className="Pagination__pagination--button"
                  onClick={() => onPage(currentPage + 1)}
                  style={{ paddingLeft: 2 }}
                >
                  <BsChevronRight />
                </div>
              )}
            </div>
          )}
          {setPageQty && (
            <div className="Pagination__pagination-qty">
              Mostrar{" "}
              <select
                name="pageQty"
                title="pageQty"
                value={pageQty}
                onChange={(e) => {
                  setPageQty(e.target.value);
                }}
              >
                <option value={3}>3</option>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={70}>70</option>
                <option value={100}>100</option>
              </select>{" "}
              items por página
            </div>
          )}
        </div>
      )}
    </C.Container>
  );
};

export default Pagination;
