//LIBS

//STYLES
import Pagination from "components/Pagination";
import { InvoiceTableStyle } from "./styles";

//COMPONENTS
import Table from "components/Table";
import { InvoiceType, PaginationType } from "core/types/Types";
import { formatDate } from "core/utils/formatDate";
import { currencyFormat } from "core/utils/currencyFormat";
import { useEffect, useState } from "react";
import { getInvoices } from "core/utils/Api";

const HeaderArray = [
  "Usuário",
  "Status",
  "Tipo Pagto",
  "Emite NFe",
  "Mês Referência",
  "Inicio Período",
  "Final Período",
  "Obs ao Prestador",
  "Salário Base",
  "Hs Trab.",
  "Hs Extras",
  "Valor Extra",
  "Reembolso",
  "Desconto",
  "Salário Mês",
  "Status Pagto",
  "Data Pagto",
];

const INITIAL_PAG = {
  total: 0,
  per_page: 3,
  current_page: 1,
  last_page: 1,
  from: null,
  to: null,
};

const TiposPagmto = ["Freelancer", "Mensalista", "Por Hora"];

interface IInvoiceTableContainer {
  onView: (pdf: string, pdfNumber: number) => void;
}

const InvoiceTableContainer = ({ onView }: IInvoiceTableContainer) => {
  const [notasFiscais, setNotasFiscais] = useState<InvoiceType[]>([]);
  const [pagination, setPagination] = useState<PaginationType>(INITIAL_PAG);
  const [pagePerView, setPagePerView] = useState("3");
  const [pdfPath, setPdfPath] = useState<string>("");

  useEffect(() => {
    getInvoicesData();
  }, []);

  const getInvoicesData = async (page = 1) => {
    const req = await getInvoices({
      page: page,
      limit: pagePerView,
      order: "id",
      type_order: "desc",
    });

    setNotasFiscais(req.data.list.data);
    setPagination(req.data.list.pagination);
    setPdfPath(req.data.path);
  };

  return (
    <InvoiceTableStyle>
      <Pagination
        countPages={pagination.last_page}
        currentPage={pagination.current_page}
        onPage={(num) => getInvoicesData(num)}
        pageQty={pagination.per_page}
        setPageQty={(num) => setPagePerView(num)}
      >
        <div className="table-holder">
          <Table
            headerList={HeaderArray}
            bodyList={notasFiscais.map((nf) => {
              return {
                id: nf.id,
                list: [
                  nf.user.firstname,
                  nf.invoice_status?.name,
                  TiposPagmto[nf.user?.agreement_id - 1],
                  nf.user?.agreement_id == 2 ? "Ativo" : "Inativo",
                  nf.reference_month,
                  formatDate(nf.start_period),
                  formatDate(nf.end_period),
                  nf.observation,
                  currencyFormat(nf.base_salary),
                  `${nf.user.amount_time}`,
                  nf.extra_hour,
                  currencyFormat(nf.extra_value),
                  currencyFormat(nf.reimbursement),
                  currencyFormat(nf.discount),
                  currencyFormat(nf.salary_month),
                  nf.status_payment ? "Ativo" : "Inativo",
                  formatDate(nf.date_payment),
                ],
              };
            })}
            onView={(_, id) =>
              onView(
                [pdfPath, notasFiscais.find((nf) => nf.id === id)?.pdf].join(
                  "/"
                ),
                notasFiscais.find((nf) => nf.id === id)?.number_invoice
              )
            }
          />
        </div>
      </Pagination>
    </InvoiceTableStyle>
  );
};

export default InvoiceTableContainer;
