// STYLEs
import * as C from "./styles";

interface ISelect {
    label?: string;
    name?: string;
    options: { value: string; label: string }[];
    placeholder?: string;
    value?: string;
    onSelect?: (selected: string) => void;
    className?:string;
}

const Select = ({ label, name, placeholder, options, value, onSelect, className }: ISelect) => {
    return (
        <C.Container className={className}>
            {label && <label htmlFor={name}>{label}</label>}
            <select
                onChange={(e) => onSelect && onSelect(e.target.value)}
                value={value}
                name={name}
                required
            >
                {placeholder && <option value="">{placeholder}</option>}
                {options.map((opt) => (
                    <option key={opt.value} value={opt.value}>
                        {opt.label}
                    </option>
                ))}
            </select>
        </C.Container>
    );
};

export default Select;
