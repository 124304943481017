//STYLES
import { UsersStyle } from "./styles";

//CONTAINERS
import RegisterUser from "container/Users/RegisterUser";

//COMPONENTS
import Table from "components/Table";

//LIBS
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//API
import { getUsers } from "core/utils/Api";

//UTILS
import { formatDate } from "core/utils/formatDate";
import { currencyFormat } from "core/utils/currencyFormat";
import Pagination from "components/Pagination";
import Title from "components/Title";

const headersArray = [
  "Nome",
  "CPF",
  "Inicio",
  "Status",
  "PIX",
  "Salário",
  "Grupo",
];

const Users = () => {
  const [allUsers, setAllUsers] = useState<any>([]);
  const [userToEdit, setUserToEdit] = useState<any>();
  const [collabs, setCollabs] = useState<{ id: number; list: string[] }[]>([]);
  const [terceiros, setTerceiros] = useState<{ id: number; list: string[] }[]>(
    []
  );

  const navigate = useNavigate();

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    setCollabs([]);
    setTerceiros([]);
    const req = await getUsers();

    const allUsers = req.data.all.map((user: any) => {
      const tableBody = {
        id: user.id,
        list: [
          user.firstname,
          user.taxvat_primary,
          formatDate(user.date_init),
          user.is_active ? "Ativo" : "Inativo",
          user.pix,
          currencyFormat(user.wage),
          user.group.name,
        ],
      };

      if (user.group.name === "terceiros") {
        setTerceiros((prev) => [...prev, tableBody]);
      } else {
        setCollabs((prev) => [...prev, tableBody]);
      }

      return user;
    });

    setAllUsers(allUsers);
  };

  return (
    <UsersStyle>
      <Title>Usuários</Title>
      <div className="content-holder">
        <RegisterUser
          onSend={() => {
            getAllUsers();
            setUserToEdit(undefined);
          }}
          userToEdit={userToEdit}
          cancelEdit={() => {
            setUserToEdit(undefined);
          }}
          allUsers={allUsers}
        />
        <div className="double-tables">
          <div className="tables-content">
            <Pagination>
              <div className="table-holder">
                {collabs.length ? (
                  <Table
                    onEdit={(item) => {
                      setUserToEdit(
                        allUsers.find((user: any) => user.firstname === item[0])
                      );
                    }}
                    onActivity={(item) =>
                      navigate(
                        `/users/${
                          allUsers.find(
                            (user: any) => user.firstname === item[0]
                          ).id
                        }`
                      )
                    }
                    headerList={headersArray}
                    bodyList={collabs}
                  />
                ) : (
                  <p>Nenhum colaborador encontrado!</p>
                )}
              </div>
            </Pagination>
          </div>
        </div>
      </div>
    </UsersStyle>
  );
};

export default Users;
