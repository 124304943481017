//LIBs
import React from "react";

//COMPONENTs
import Button from "../Button";

//TYPES
import { PlanType } from "core/types/Types";

//STYLES
import { PlansStyle } from "./styles";

interface IPlanCard {
    plan: PlanType;
    onClick: () => void;
    btnLabel: string;
    selected: boolean;
}

const PlanCard = ({ plan, onClick, btnLabel, selected = false }:IPlanCard) => {
    let currencyFormat = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
    });

    return (
        <PlansStyle>
            <div>
                <div className={`header ${selected && "selectedPlanCard"}`}>
                    <p>{plan.name}</p>
                </div>
                <div className="content">
                    <div className="prices">
                        <p className={`value ${selected && "selectedPlanCard"}`}>
                            {currencyFormat.format(plan.price)}
                        </p>
                    </div>
                    {/*<div className="list">
                        {plan.json_items &&
                            plan.json_items.map(
                                (adv, index) =>
                                    adv.is_active && (
                                        <div
                                            key={index}
                                            className={`advantage ${index % 2 && "white"}`}
                                        >
                                            <div className="advantage-content">
                                                <img src={CheckedSquare} alt="Icone de Check" />
                                                <p>{adv.item}</p>
                                            </div>
                                        </div>
                                    )
                                    )}
                    </div>*/}
                </div>
            </div>
            <div className="btn">
                <Button className={selected ? "selectedPlanCard" : ""} onClick={onClick}>
                    {btnLabel}
                </Button>
            </div>
        </PlansStyle>
    );
};

export default PlanCard;
