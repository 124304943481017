// STYLEs
import * as C from "./styles";

//COMPONENTS
import Input from "../Input";
import Button from "../Button";

//LIBS
import { ChangeEvent, useState, useEffect } from "react";

//TYPES
import { ITask } from "../../core/types/Types";
import notify from "../../core/utils/notify";

interface IFormTask {
  onClose: () => void;
  onSave: (data: { [key: string]: any }) => void;
  taskCode?: string;
  resetData: boolean;
  frameId?: number;
  taskToEdit?: ITask;
  isLoading?: boolean;
}

const formInitialInputs = {
  name: "",
  description: "",
  delivery_date: "",
  quantity_hours: "",
  frames_id: "",
  files: "",
};

const FormTask = ({
  onClose,
  onSave,
  taskCode,
  resetData,
  frameId,
  taskToEdit,
  isLoading = false,
}: IFormTask) => {
  const [inputsValues, setInputsValues] = useState<{ [key: string]: any }>(
    formInitialInputs
  );
  const [toggleImgValue, setToggleImgValue] = useState<number>(0);

  useEffect(() => {
    if (taskToEdit) {
      setInputsValues({
        name: formatName(),
        description: taskToEdit.description,
        delivery_date: taskToEdit.delivery_date,
        quantity_hours: taskToEdit.quantity_hours,
        frames_id: taskToEdit.frames_id,
        file: taskToEdit.files,
      });
    } else {
      setInputsValues({ ...formInitialInputs, frames_id: frameId });
    }

    setToggleImgValue((prev) => (prev == 0 ? 1 : 0));
  }, [resetData]);

  const formatName = () => {
    return (
      taskToEdit &&
      (taskToEdit.name.split(" - ").length > 1
        ? taskToEdit.name
            .split(" - ")
            .filter((value, index) => index !== 0)
            .join(" - ")
        : taskToEdit.name)
    );
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputsValues((values) => ({ ...values, [name]: value }));
  };

  const handleChangeImg = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.files;
    setInputsValues((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = () => {
    const inputsRequired = [
      "name",
      "description",
      "delivery_date",
      "frames_id",
      "quantity_hours",
    ];

    if (inputsRequired.every((input) => inputsValues[input] !== "")) {
      let removeEmptyInputs = Object.fromEntries(
        Object.entries(inputsValues).filter(([_, v]) => v !== "null")
      );

      onSave({
        ...removeEmptyInputs,
        name: `${taskCode} - ${inputsValues.name}`,
      });
    } else {
      notify("error", "Preencha todos os campos corretamente!");
    }
  };

  return (
    <C.Container>
      <form>
        <Input
          onChange={handleChange}
          value={inputsValues.name}
          label="Título"
          name="name"
          type="task-title"
          title={taskCode}
        />
        <Input
          onChange={handleChange}
          value={inputsValues.description}
          label="Descrição"
          name="description"
          type="textarea"
        />
        <Input
          onChange={handleChange}
          value={inputsValues.delivery_date}
          label="Entrega"
          name="delivery_date"
          type="date"
        />
        <Input
          onChange={handleChange}
          value={inputsValues.quantity_hours}
          label="Qt. Horas"
          name="quantity_hours"
          type="number"
        />
        <Input
          key={toggleImgValue}
          onChangeFile={handleChangeImg}
          label="Imagens"
          name="files"
          type="file"
          accept="image/png, image/jpeg"
          multiple
        />
        <div className="buttons-holder">
          <Button color="danger" onClick={onClose}>
            Fechar
          </Button>
          <Button onClick={handleSubmit} isLoading={isLoading}>
            Salvar
          </Button>
        </div>
      </form>
    </C.Container>
  );
};

export default FormTask;
