// LIBs
import styled from "styled-components";

// INCLUDEs
import { colors } from "../../core/stylesheet/includes";

export const Container = styled.div`
  min-width: 100vw;
  min-height: 100dvh;
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: #00000075;
  top: 0;
  padding: 16px;
  left: 0;
  z-index: 1000;

  &.active {
    display: flex;
  }

  .content-container {
    max-width: 100%;
    display: block;
    background-color: ${colors.white};
    border-radius: 20px;
    max-height: 90dvh;

    &.images {
      max-width: 1440px !important;

      .Modal {
        &--body {
          display: flex;
          justify-content: center;

          img {
            max-width: max-content;
            width: 100%;
            height: unset;
          }
        }
      }
    }
  }

  .Modal {
    &--header {
      padding: 16px;
      border-bottom: 1px solid ${colors.lightBlue};
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: ${colors.darkBlue};

      p {
        text-overflow: ellipsis;
        font-weight: 500;
        max-width: 80%;
        font-size: 20px;
        color: ${colors.darkBlue};
        overflow: hidden;
        text-wrap: nowrap;
      }

      button {
        color: ${colors.darkBlue};

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    &--body {
      padding: 16px;
      overflow-y: auto;
      max-height: 700px;
    }
  }
`;
