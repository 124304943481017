import { colors, screens } from "core/stylesheet/includes";
import styled from "styled-components";

export const OrderStyle = styled.main`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media ${screens.tb} {
    flex-direction: row;
  }
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: ${colors.white};
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
    padding: 24px;
    border-radius: 10px;
    .double-input {
      display: flex;
      flex-direction: column;
      gap: 16px;
      @media ${screens.mbV} {
        flex-direction: row;
        gap: 16px;
      }
    }
  }
  .double-tables {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 100%;
    width: 100%;
    @media ${screens.tb} {
      width: calc(100% - 352px);
    }
    @media ${screens.dsS} {
      flex-direction: row;
    }
    .tables-content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      max-width: 100%;
      width: 100%;
      @media ${screens.dsS} {
        max-width: calc(100% - 12px);
      }
      .title {
        font-size: 32px;
        font-weight: 500;
      }
    }
    .table-holder {
      width: 100%;
      overflow: auto;
      padding-bottom: 16px;
      > p {
        font-size: 20px;
      }
      table {
        min-width: 750px;
      }
    }
  }
`;
