//LIBS
import { FiEdit, FiMoreVertical, FiPlus, FiTrash2 } from "react-icons/fi";
import { Editor } from "@tinymce/tinymce-react";
import { Fragment, useEffect, useRef, useState } from "react";
import {
  FiChevronDown,
  FiChevronUp,
  FiArrowUpRight,
  FiArrowLeft,
} from "react-icons/fi";

// STYLES
import { ChatsContainerStyle } from "./styles";

//COMPONENTS
import Button from "components/Button";
import {
  ChatLink,
  IChatMessages,
  IChatTypes,
  UserState,
} from "core/types/Types";
import {
  deleteLink,
  getAllMessagesUser,
  getLinks,
  sendMessageUser,
} from "core/utils/Api";
import Loading from "components/Loading";
import notify from "core/utils/notify";

import { getUsers } from "core/utils/Api";

//REDUCERS
import { clearUser } from "core/reducers/UserReducer/actions";

interface IChatsCard {
  name: string;
  active?: boolean;
  onClick?: () => void;
  count?: number | any;
}

const ChatCard = ({ name, active, onClick, count }: IChatsCard) => {
  return (
    <div onClick={onClick} className={`chat-card ${active ? "active" : ""}`}>
      <p className="name">{name}</p>
      {count > 0 && (
        <div className="chat-card-count">{count > 0 ? count : ""}</div>
      )}
    </div>
  );
};

interface IChatsContainer {
  chats?: IChatTypes[];
  currUser: UserState;
  onAddLink?: () => void;
  onEditLink?: (link: ChatLink) => void;
  reloadLinks?: boolean;
}

const ChatsContainer = ({
  chats,
  currUser,
  onAddLink,
  onEditLink,
  reloadLinks,
}: IChatsContainer) => {
  const [openToolbar, setOpenToolbar] = useState<boolean>(false);
  const [openLinks, setOpenLinks] = useState<boolean>(false);
  const [selectedChat, setSelectedChat] = useState<number>();
  const [messages, setMessages] = useState<IChatMessages[]>();
  const [loadMessages, setLoadMessages] = useState<boolean>(false);
  const [editorMessage, setEditorMessage] = useState<string>("");
  const [onChatPage, setOnChatPage] = useState<boolean>(false);
  const [links, setLinks] = useState<ChatLink[]>([]);
  const [chatCounts, setChatCounts] = useState<number[]>([]);
  const [idUser, setIdUser] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 989);

  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const messageBoxRef = useRef<HTMLDivElement | null>(null);
  const scrollToBottom = () => {
    if (messageBoxRef.current) {
      messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 989);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setInterval(() => {
      const getChats = async () => {
        try {
          const req = await getUsers();
          setIdUser(req.data.enables_chat);
        } catch (err) {
          clearUser();
        }
      };

      getChats();
    }, 3000);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", toogleToolbar);
    toogleToolbar();

    return () => window.removeEventListener("resize", toogleToolbar);
  }, [openToolbar]);

  useEffect(() => {
    selectedChat && getChatMessages(selectedChat);

    let messagesInterval = setInterval(() => {
      selectedChat && getChatMessages(selectedChat);
    }, 3000);

    return () => clearInterval(messagesInterval);
  }, [selectedChat]);

  useEffect(() => {
    getLinksData();
  }, [reloadLinks]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const getLinksData = async () => {
    const req = await getLinks();

    if (currUser.group.name === "Master") {
      setLinks(req.data.all);
    } else {
      setLinks(req.data.enable);
    }
  };

  async function getChatMessages(id: number) {
    const req = await getAllMessagesUser(id);

    if (req.error && req.error.title) {
      notify("error", req.error.title);
    } else {
      setMessages(req.data);
      setChatCounts((count) => ({
        ...count,
        [id]: req.data.length,
      }));
    }
  }

  async function onSend() {
    if (selectedChat) {
      const req = await sendMessageUser(selectedChat, editorMessage);

      if (req.error && req.error.title) {
        notify("error", req.error.title);
      } else {
        getChatMessages(selectedChat);
      }

      setEditorMessage("");
    }
  }

  function toogleToolbar() {
    const toolbar = document.querySelector(
      ".tox.tox-tinymce-inline"
    ) as HTMLElement | null;
    const editor = document.querySelector(".input-message");

    if (editor) {
      let editorXY = editor.getBoundingClientRect();

      if (toolbar) {
        toolbar.style.left = `${editorXY.left}px !important`;
        toolbar.style.top = `${
          editorXY.top - editor.scrollHeight - 32
        }px !important`;

        if (openToolbar) {
          toolbar.classList.add("toolbar-shown");
          toolbar.classList.remove("toolbar-hidden");
        } else {
          toolbar.classList.add("toolbar-hidden");
          toolbar.classList.remove("toolbar-shown");
        }
      }
    }
  }

  const removeLink = async (id: number) => {
    const req = await deleteLink(id);

    if (req.error && req.error.title) {
      notify("error", req.error.title);
    } else {
      notify("success", "Link removido com sucesso!");
      getLinksData();
    }
  };

  return (
    <ChatsContainerStyle className={onChatPage ? "on-message-page" : ""}>
      {loadMessages && <Loading />}
      <div className="left-content">
        <div className="links-holder">
          <div
            className="links-header"
            onClick={() => setOpenLinks(!openLinks)}
          >
            Links
            {openLinks ? <FiChevronUp /> : <FiChevronDown />}
          </div>
          {openLinks && (
            <div className="links-body">
              {links.map((link) => (
                <div
                  key={link.id}
                  className={`link-card ${link.is_active ? "" : "disabled"}`}
                >
                  <a href={link.link} target="_blank">
                    {link.name}
                  </a>
                  <div>
                    <FiArrowUpRight />{" "}
                    {currUser.group.name === "Master" && (
                      <>
                        <FiEdit
                          onClick={() => {
                            onEditLink && onEditLink(link);
                          }}
                        />{" "}
                        <FiTrash2
                          onClick={() => link.id && removeLink(link.id)}
                        />
                      </>
                    )}
                  </div>
                </div>
              ))}
              <Button className="add-link" color="white" onClick={onAddLink}>
                Adicionar Link <FiPlus />
              </Button>
            </div>
          )}
        </div>
        {/* aqui.. */}
        <div className="chats-holder">
          {/* <h2>Chats</h2> */}

          {/* {chats &&
            chats.length &&
            chats[0].userName &&
            chats.map((chat) => (
              <Fragment key={chat.id}>
                <ChatCard
                  onClick={() => {
                    setLoadMessages(true);
                    setSelectedChat(chat.id);
                    setOnChatPage(true);
                    setTimeout(() => {
                      setLoadMessages(false);
                    }, 500);
                  }}
                  name={chat.userName}
                  count={chatsNotification}
                  active={chat.id === selectedChat}
                />
              </Fragment>
          ))} */}

          {idUser.map((chat: any) => (
            <Fragment key={chat.id}>
              <ChatCard
                onClick={() => {
                  setLoadMessages(true);
                  setSelectedChat(chat.id);
                  setOnChatPage(true);
                  setTimeout(() => {
                    setLoadMessages(false);
                  }, 500);
                }}
                name={chat.firstname}
                count={chat.chat_messages_count}
                active={!isMobile && chat.id === selectedChat}
              />
            </Fragment>
          ))}
        </div>
      </div>
      <div className="messages-holder" ref={messagesEndRef}>
        <div className="messages-header">
          <button title="voltar" onClick={() => setOnChatPage(false)}>
            <FiArrowLeft />
          </button>
          {chats &&
            selectedChat &&
            chats.filter((chat) => chat.id === selectedChat)[0].userName}
        </div>
        <div className="message-box" ref={messageBoxRef}>
          {messages &&
            messages.map((message) => (
              <div
                className={`message-holder ${
                  currUser.id === message.user_id_from ? "mine" : ""
                }`}
                key={message.id}
              >
                <div
                  className="message-card"
                  dangerouslySetInnerHTML={{ __html: message.message }}
                ></div>
              </div>
            ))}
        </div>
        <div className="input-message-holder">
          <div className="input-message">
            <Editor
              onEditorChange={(text) => setEditorMessage(text)}
              value={editorMessage}
              apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
              init={{
                inline: true,
                menubar: true,
                plugins: ["link", "lists", "autolink"],
              }}
            />
            <button
              title="Mais opc"
              onClick={() => setOpenToolbar(!openToolbar)}
            >
              <FiMoreVertical />
            </button>
          </div>
          <Button onClick={onSend}>Enviar</Button>
        </div>
      </div>
    </ChatsContainerStyle>
  );
};

export default ChatsContainer;
