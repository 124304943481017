// STYLEs
import * as C from "./styles";

interface ILoading {
  opacity?:number;
}

const Loading = ({opacity = 0.9}:ILoading) => {
  return (
    <C.Container style={{opacity:opacity}}>
      <div className="LoadingDetails">
        <div className="LoadingDetails__spin">
          <div />
        </div>
      </div>
    </C.Container>
  );
}

export default Loading;