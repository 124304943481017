//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors, screens } from "../../../core/stylesheet/includes";

export const InvoiceLinksStyle = styled.div`
    width: 100%;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    .header {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: end;

        @media ${screens.tb} {
            flex-direction: row;
        }
    }

    .table-holder {
        width: 100%;
        overflow: auto;
        padding-bottom: 16px;
        max-width: 1440px;

        table{
          width: 100%;
          min-width: 800px;
        }

        .copy{
          background-color: ${colors.lightBlue2};
          border-radius: 10px;
          padding: 12px 16px;
          color: ${colors.white};
          font-size: 16px;
          font-weight: 600;

          &:hover{
            background-color: ${colors.mainBlue};
          }
        }
      }

      .edit-link{
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: 600px;
        width: 100%;
        min-width: 250px;
      }
`;
