//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors, screens } from "../../../core/stylesheet/includes";

export const RegisterUserStyle = styled.div`
  width: 100%;
  background-color: ${colors.white};
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 24px;
  border-radius: 10px;
        
  @media ${screens.tb} {
    max-width: 320px;
    width: 100%;
  }

  form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    grid-auto-flow: column;

    .column {
      display: flex;
      grid-column-start: 1;
      grid-column-end: 2;
    }

    .column-1,
    .column-2,
    .column-3,
    .column-4,
    .save-button {
      display: none;
      grid-column-start: 1;
      grid-column-end: 2;
    }

    .column-1.active,
    .column-2.active,
    .column-3.active,
    .column-4.active {
      display: flex;
      grid-column-start: 1;
      grid-column-end: 2;
    }

    .buttons-holder-mobile {
      display: flex;
      flex-direction: column;
      gap: 16px;
      grid-column-start: 1;
      grid-column-end: 2;
    }

    .save-button {
      display: none;
      height: 100%;
      align-items: end;
    }
  }

  .double-inputs {
    display: flex;
    gap: 16px;
    flex-direction: column;

    @media ${screens.mbB} {
      flex-direction: row;
    }
  }
`;
