import { Titulo } from "./styles";

interface IProps {
  children: React.ReactNode;
}

const Title = ({ children }: IProps) => {
  return <Titulo>{children}</Titulo>;
};

export default Title;
