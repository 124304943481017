import React, { useState } from "react";
import { SwitchStyle } from "./styles";

interface ISwitch {
    option1: string;
    option2: string;
    onChange?: (opt: string) => void;
}

const Switch = ({ option1, option2, onChange = () => {} }: ISwitch) => {
    const [selectedOption, setSelectedOption] = useState(option1);

    const handleOptionClick = (option: string) => {
        setSelectedOption(option);
        onChange(option);
    };

    return (
        <SwitchStyle className="switch">
            <button
                className={selectedOption === option1 ? "selected" : ""}
                onClick={() => handleOptionClick(option1)}
            >
                {option1}
            </button>
            <button
                className={selectedOption === option2 ? "selected" : ""}
                onClick={() => handleOptionClick(option2)}
            >
                {option2}
            </button>
        </SwitchStyle>
    );
};

export default Switch;
