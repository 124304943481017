// LIBS
import { ChangeEvent, useEffect, useState } from "react";

// COMPONENTS
import Button from "components/Button";
import Input from "components/Input";

// API
import { getServices, addServices, updateServices } from "core/utils/Api";

//UTILS
import notify from "core/utils/notify";
import Select from "components/Select";
import { currencyFormat } from "core/utils/currencyFormat";
import Pagination from "components/Pagination";
import Table from "components/Table";
// import { ServicesStyle } from "container/Services/styles";
import { RegisterUserStyle } from "container/Users/RegisterUser/styles";

const EMPTY_INPUTS = {
  name: "",
  description: "",
  price: "",
  is_active: true,
};

const headersArray = ["Nome", "Description", "Price", "Status"];

const ServicesContainer = () => {
  const [services, setServices] = useState<any>([]);
  const [inputsValues, setInputsValues] = useState(EMPTY_INPUTS);
  const [servicesToEdit, setServicesToEdit] = useState<any | null>(null);
  const [editingService, setEditingService] = useState<{
    data: any;
    index: number;
  } | null>(null);

  // GET Services
  const getServicesData = async () => {
    const req = await getServices();
    if (req.error.length === 0) {
      setServices(req.data);
    } else {
      notify("error", "Erro ao buscar serviços");
    }
  };

  useEffect(() => {
    getServicesData();
  }, []);

  // ADD SERVICES
  const handleAddService = async () => {
    const req = await addServices(inputsValues);

    if (req.error && req.error.title) {
      notify("error", "Erro ao salvar serviço");
    } else {
      notify("success", "Serviço cadastrado com sucesso");
      onSend();
      setInputsValues(EMPTY_INPUTS);
    }
  };

  // UPDATE
  const handleUpdateService = async () => {
    const req = await updateServices(servicesToEdit.id, inputsValues);

    if (req.error && req.error.title) {
      notify("error", req.error.title || "Erro ao atualizar serviço **");
    } else {
      notify("success", "Serviço atualizado com sucesso");
      onSend();
    }
  };

  useEffect(() => {
    setInputsValues(servicesToEdit || EMPTY_INPUTS);
  }, [servicesToEdit]);

  useEffect(() => {
    if (editingService) {
      setInputsValues({ ...editingService.data });
      setServicesToEdit(editingService.data);
    } else {
      setInputsValues(EMPTY_INPUTS);
    }
  }, [editingService]);

  const onSend = async () => {
    const updatedServices = await getServices();
    setServices(updatedServices.data);
    setInputsValues(EMPTY_INPUTS);
  };

  // HANDLE CHANGE
  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = event.target;
    const finalValue =
      type === "checkbox" ? (event.target as HTMLInputElement).checked : value;
    setInputsValues((values) => ({ ...values, [name]: finalValue }));
  };

  // HANDLE SUBMIT
  const handleSubmit = () => {
    if (
      Object.values(inputsValues).every(
        (value) => value !== "" && value !== null
      )
    ) {
      if (editingService) {
        handleUpdateService();
      } else {
        handleAddService();
      }
    } else {
      notify("error", "Preencha todos os campos corretamente");
    }
  };

  // HANDLE EDIT (onEdit)
  const onEdit = (item: any, index: number) => {
    const service = services[index];
    setInputsValues(service);
    setEditingService({ data: service, index });
  };

  return (
    <>
      <RegisterUserStyle>
        <form>
          <Input
            value={inputsValues.name}
            onChange={handleChange}
            className="column"
            label="Nome:"
            name="name"
            type="text"
            required
          />
          <Input
            value={inputsValues.description}
            onChange={handleChange}
            className="column"
            label="Descrição:"
            name="description"
            type="textarea"
            required
          />
          <Input
            value={inputsValues.price}
            onChange={handleChange}
            className="column"
            label="Preço:"
            name="price"
            type="number"
            required
          />

          <Select
            label="Status"
            value={inputsValues.is_active ? "1" : "0"}
            className="column"
            onSelect={(value) =>
              setInputsValues((prev: any) => {
                return { ...prev, is_active: value === "1" };
              })
            }
            options={[
              { label: "Ativo", value: "1" },
              { label: "Inativo", value: "0" },
            ]}
          />
          <div className="buttons-holder-mobile">
            <Button onClick={handleSubmit}>Salvar</Button>
            {servicesToEdit && (
              <Button
                color="danger"
                onClick={() => {
                  setServicesToEdit(null);
                  setEditingService(null);
                }}
              >
                Cancelar
              </Button>
            )}
          </div>
        </form>
      </RegisterUserStyle>
      <div className="double-tables">
        <div className="tables-content">
          <Pagination>
            <div className="table-holder">
              {services.length ? (
                <Table
                  onEdit={onEdit}
                  headerList={headersArray}
                  bodyList={services.map((service: any, index: number) => ({
                    id: index,
                    list: [
                      service.name,
                      service.description,
                      currencyFormat(service.price),
                      service.is_active ? "Ativo" : "Inativo",
                    ],
                  }))}
                />
              ) : (
                <p>Nenhum serviço encontrado!</p>
              )}
            </div>
          </Pagination>
        </div>
      </div>
    </>
  );
};

export default ServicesContainer;
