// LIBs
import styled from "styled-components";

// INCLUDEs
import { colors } from "../../core/stylesheet/includes";

export const Container = styled.div`
  padding: 16px;
  background-color: ${colors.white};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  .card-item {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 8px;

    &.actions {
      display: flex;
      justify-content: space-between;
    }

    p {
      font-size: 16px;
      font-weight: 700;
      color: ${colors.blackBlue};
    }

    .value {
      font-size: 16px;
      color: ${colors.blackBlue};
      font-weight: 500;
    }

    .buttons-holder {
      width: fit-content;
    }
  }
`;
