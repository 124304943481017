// STYLES
import { OrderStyle } from "./styles";

// API
import {
  getOrder,
  addOrder,
  updateOrder,
  getCustomers,
  getServices,
} from "core/utils/Api";

// LIBS
import { currencyFormat } from "core/utils/currencyFormat";
import { useEffect, useState } from "react";

// UTILS
import notify from "core/utils/notify";

// COMPONENTS
import Pagination from "components/Pagination";
import Table from "components/Table";
import Input from "components/Input";
import Select from "components/Select";
import Button from "components/Button";
import { PaginationType } from "core/types/Types";

interface Customer {
  id: string;
  name: string;
}

interface Service {
  id: string;
  name: string;
  price: number;
}
interface OrderProps {
  customer_id: string | null;
  service_id: string | null;
  price: number | null;
  quantity: number | null;
  amount: number | null;
  discount: number | null;
  amount_end: number | null;
  type_payment: "pix" | "credit_card";
}

const EMPTY_INPUTS: OrderProps = {
  customer_id: null,
  service_id: null,
  price: null,
  quantity: null,
  amount: null,
  discount: null,
  amount_end: null,
  type_payment: "pix",
};

const headersArray = [
  "Preço",
  "Quantidade",
  "Valor",
  "Desconto",
  "Valor Final",
  "Tipo Pagamento",
];

const OrderContainer = () => {
  const [inputsValues, setInputsValues] = useState<OrderProps>(EMPTY_INPUTS);
  const [orders, setOrders] = useState<OrderProps[]>([]);
  const [orderToEdit, setOrderEdit] = useState<any>(null);
  const [customers, setCustomers] = useState<Customer[]>([
    {
      name: "Selecione...",
      id: "",
    },
  ]);
  const [services, setServices] = useState<Service[]>([]);
  const [discount, setDiscount] = useState<number | null>(null);
  const [pagePerView, setPagePerView] = useState("3");
  const [pagination, setPagination] = useState<PaginationType>({
    total: 0,
    per_page: 5,
    current_page: 1,
    last_page: 1,
    from: null,
    to: null,
  });

  // GET DATA
  const getOrderData = async (page = 1) => {
    const req = await getOrder(page);

    if (req.error.length === 0) {
      setOrders(req.data.data);
      setPagination((prev) => ({
        ...prev,
        current_page: page,
        ...req.data,
      }));
    } else {
      notify("error", req.error);
    }
  };

  useEffect(() => {
    const fetchCustomersAndServices = async () => {
      try {
        const [customersData, servicesData] = await Promise.all([
          getCustomers(),
          getServices(),
        ]);
        const updatedCustomers = customersData.data.map((customer: any) => ({
          id: customer.id,
          name: customer.name,
        }));
        setCustomers([
          {
            name: "Selecione...",
            id: "",
          },
          ...updatedCustomers,
        ]);
        setServices(servicesData.data);
      } catch (error) {
        console.error("Failed to fetch customers and services:", error);
      }
    };

    fetchCustomersAndServices();
    getOrderData();
  }, []);

  // ADD ORDER
  const createOrder = async (order: OrderProps) => {
    const req = await addOrder(order);
    if (req.error && req.error.title) {
      notify("error", "Erro ao criar ordem");
    } else {
      notify("success", "Ordem criada com sucesso");
      onSend();
      setInputsValues(EMPTY_INPUTS);
      setDiscount(null);
    }
  };

  // Edit Order
  const editOrder = async (order: OrderProps) => {
    const req = await updateOrder(orderToEdit.id, order);
    if (req.error && req.error.title) {
      notify("error", "Erro ao editar ordem");
    } else {
      notify("success", "Ordem editada com sucesso");
      onSend();
    }
  };

  // HANDLE SUBMIT
  const handleSubmit = () => {
    if (
      Object.values({ ...inputsValues, discount }).every(
        (value) => value !== "" && value !== null
      )
    ) {
      const order = {
        ...inputsValues,
        discount: (inputsValues.amount || 0) * ((discount || 0) / 100),
      };
      if (orderToEdit) {
        editOrder(order);
      } else {
        createOrder(order);
      }
    } else {
      notify("error", "Preencha todos os campos corretamente");
    }
  };

  // HANDLE CHANGE
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setInputsValues((prevValues) => ({
      ...prevValues,
      [name]:
        name === "price" || name === "quantity" ? parseFloat(value) : value,
    }));
  };

  useEffect(() => {
    const { price, quantity } = inputsValues;
    const priceNumber = Number(price) || 0;
    const quantityNumber = Number(quantity) || 0;
    const discountNumber = discount || 0;

    const amount = priceNumber * quantityNumber;
    const discountValue = (amount * discountNumber) / 100;
    const amount_end = amount - discountValue;

    setInputsValues((prev) => ({
      ...prev,
      amount,
      amount_end,
    }));
  }, [inputsValues.price, inputsValues.quantity, discount]);

  // HANDLE ADD (onAdd)
  const onSend = () => {
    setInputsValues(EMPTY_INPUTS);
    setDiscount(null);
    getOrderData();
  };

  // HANDLE EDIT (onEdit)
  const onEdit = (item: any, index: number) => {
    const order = orders[index];

    const discountPercent = 100 / ((order.amount || 0) / (order.discount || 0));
    setInputsValues({
      service_id: order.service_id,
      customer_id: order.customer_id,
      price: order.price,
      quantity: order.quantity,
      amount: order.amount,
      amount_end: order.amount_end,
      discount: order.discount,
      type_payment: order.type_payment,
    });
    setOrderEdit(order);
    setDiscount(discountPercent);
  };

  // Handle Cancel
  const handleCancel = () => {
    setInputsValues(EMPTY_INPUTS);
    setOrderEdit(null);
    setDiscount(null);
  };

  return (
    <>
      <OrderStyle>
        <div className="form-holder">
          <form>
            <Select
              key="select_cliente"
              label="Cliente"
              className="column-1 active"
              value={inputsValues.customer_id || ""}
              onSelect={(value) => {
                setInputsValues((prev) => ({
                  ...prev,
                  customer_id: value,
                }));
              }}
              options={customers.map((customer) => ({
                label: customer.name,
                value: customer.id,
              }))}
            />

            <Select
              key="select_serviço"
              label="Serviço"
              className="column-1 active"
              value={inputsValues.service_id || ""}
              onSelect={(value) => {
                const selectedService = services.filter(
                  (service) => service.id == value
                );
                if (selectedService) {
                  setInputsValues((prev) => ({
                    ...prev,
                    service_id: value,
                    price: selectedService[0].price || null,
                  }));
                }
              }}
              options={services.map((service) => ({
                label: service.name,
                value: service.id,
              }))}
            />
            <Input
              value={
                inputsValues.price !== null ? inputsValues.price.toString() : ""
              }
              onChange={(e) =>
                setInputsValues((prev) => ({
                  ...prev,
                  price: parseFloat(e.target.value) || null,
                }))
              }
              className="column-1 active"
              label="Preço:"
              name="price"
              type="number"
              required
            />
            <Input
              value={
                inputsValues.quantity !== null
                  ? inputsValues.quantity.toString()
                  : ""
              }
              onChange={handleChange}
              className="column-1 active"
              label="Quantidade:"
              name="quantity"
              type="number"
              required
            />
            <Input
              value={
                inputsValues.amount !== null
                  ? inputsValues.amount.toString()
                  : ""
              }
              onChange={handleChange}
              className="column-1 active"
              label="SubTotal:"
              name="amount"
              type="text"
              readOnly
              required
            />
            <Input
              value={discount || 0}
              onChange={(e) => setDiscount(parseFloat(e.target.value) || null)}
              className="column-1 active"
              label="Desconto:"
              name="discount"
              type="text"
              required
            />
            <Input
              value={
                inputsValues.amount_end !== null
                  ? inputsValues.amount_end.toString()
                  : ""
              }
              onChange={handleChange}
              className="column-1 active"
              label="Total"
              name="amount_end"
              type="text"
              readOnly
              required
            />

            <Select
              label="Tipo de Pagamento"
              className="column-1 active"
              value={inputsValues.type_payment}
              onSelect={(value) =>
                setInputsValues((prev: any) => {
                  return { ...prev, type_payment: value };
                })
              }
              options={[
                { label: "Pix", value: "pix" },
                { label: "Cartão de Crédito", value: "credit_card" },
              ]}
            />

            <div className="buttons-holder-mobile">
              <Button onClick={handleSubmit}>
                {orderToEdit ? "Editar Order" : "Criar Order"}
              </Button>
              {orderToEdit && (
                <Button onClick={handleCancel} color="danger">
                  Cancelar
                </Button>
              )}
            </div>
          </form>
        </div>

        <div className="double-tables">
          <div className="tables-content">
            <Pagination
              countPages={pagination.last_page}
              currentPage={pagination.current_page}
              onPage={(num) => getOrderData(num)}
              pageQty={pagination.per_page}
              setPageQty={(num) => setPagePerView(num)}
            >
              <div className="table-holder">
                {orders.length ? (
                  <Table
                    onEdit={onEdit}
                    headerList={headersArray}
                    bodyList={orders.map((orderItem: any, index: number) => ({
                      id: index,
                      list: [
                        currencyFormat(orderItem.price),
                        orderItem.quantity,
                        orderItem.amount,
                        100 /
                          ((orderItem.amount || 0) / (orderItem.discount || 0)),
                        currencyFormat(orderItem.amount_end),
                        orderItem.type_payment,
                      ],
                    }))}
                  />
                ) : (
                  <p>Nenhuma ordem encontrada!</p>
                )}
              </div>
            </Pagination>
          </div>
        </div>
      </OrderStyle>
    </>
  );
};

export default OrderContainer;
