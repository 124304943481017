//STYLES
import { InvoicesStyle } from "./styles";

//LIBS
import { useState } from "react";

//CONTAINERS
import InvoiceTableContainer from "container/Invoices/Table";
import InvoiceRegisterContainer from "container/Invoices/Register";

//COMPONENTS
import Modal from "components/Modal";
import Button from "components/Button";
import Title from "components/Title";

const Invoices = () => {
  const [openNf, setOpenNf] = useState<boolean>(false);
  const [pdfPath, setPdfPath] = useState<string>("");
  const [pdfNum, setPdfNum] = useState<number>(0);

  return (
    <InvoicesStyle>
      <Title>Notas e Recebimentos</Title>
      <InvoiceRegisterContainer />
      <InvoiceTableContainer
        onView={(pdf, number) => {
          setOpenNf(true);
          setPdfPath(pdf);
          setPdfNum(number);
        }}
      />
      <Modal
        header={`NF.:${pdfNum}`}
        open={openNf}
        onClose={() => setOpenNf(!openNf)}
      >
        <iframe className="pdf" src={pdfPath}></iframe>
        <Button
          color="danger"
          onClick={() => {
            setOpenNf(!openNf);
            setPdfPath("");
            setPdfNum(0);
          }}
        >
          Fechar
        </Button>
      </Modal>
    </InvoicesStyle>
  );
};

export default Invoices;
