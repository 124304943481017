export const estadosBrasileiros = [
  { label: "Acre", value: "Acre" },
  { label: "Alagoas", value: "Alagoas" },
  { label: "Amapá", value: "Amapá" },
  { label: "Amazonas", value: "Amazonas" },
  { label: "Bahia", value: "Bahia" },
  { label: "Ceará", value: "Ceará" },
  { label: "Distrito Federal", value: "Distrito Federal" },
  { label: "Espírito Santo", value: "Espírito Santo" },
  { label: "Goiás", value: "Goiás" },
  { label: "Maranhão", value: "Maranhão" },
  { label: "Mato Grosso", value: "Mato Grosso" },
  { label: "Mato Grosso do Sul", value: "Mato Grosso do Sul" },
  { label: "Minas Gerais", value: "Minas Gerais" },
  { label: "Pará", value: "Pará" },
  { label: "Paraíba", value: "Paraíba" },
  { label: "Paraná", value: "Paraná" },
  { label: "Pernambuco", value: "Pernambuco" },
  { label: "Piauí", value: "Piauí" },
  { label: "Rio de Janeiro", value: "Rio de Janeiro" },
  { label: "Rio Grande do Norte", value: "Rio Grande do Norte" },
  { label: "Rio Grande do Sul", value: "Rio Grande do Sul" },
  { label: "Rondônia", value: "Rondônia" },
  { label: "Roraima", value: "Roraima" },
  { label: "Santa Catarina", value: "Santa Catarina" },
  { label: "São Paulo", value: "São Paulo" },
  { label: "Sergipe", value: "Sergipe" },
  { label: "Tocantins", value: "Tocantins" },
];

export const estadosBrasileirosUF = [
  { label: "AC", value: "AC" },
  { label: "AL", value: "AL" },
  { label: "AP", value: "AP" },
  { label: "AM", value: "AM" },
  { label: "BA", value: "BA" },
  { label: "CE", value: "CE" },
  { label: "DF", value: "DF" },
  { label: "ES", value: "ES" },
  { label: "GO", value: "GO" },
  { label: "MA", value: "MA" },
  { label: "MT", value: "MT" },
  { label: "MS", value: "MS" },
  { label: "MG", value: "MG" },
  { label: "PA", value: "PA" },
  { label: "PB", value: "PB" },
  { label: "PR", value: "PR" },
  { label: "PE", value: "PE" },
  { label: "PI", value: "PI" },
  { label: "RJ", value: "RJ" },
  { label: "RN", value: "RN" },
  { label: "RS", value: "RS" },
  { label: "RO", value: "RO" },
  { label: "RR", value: "RR" },
  { label: "SC", value: "SC" },
  { label: "SP", value: "SP" },
  { label: "SE", value: "SE" },
  { label: "TO", value: "TO" },
];
