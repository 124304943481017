//STYLES
import { TimekeepingStyle } from "./styles";

//LIBS
import { useNavigate, useParams } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from "react";
import { connect } from "react-redux";

//CONTAINERS
import FilterAndRegisterTimekeeping from "container/Timekeeping/FilterAndRegister";
import Table from "components/Table";

// COMPONENTs
import Input from "components/Input";
import Modal from "components/Modal";
import Button from "components/Button";
import Pagination from "components/Pagination";
import {
    getAppointmentById,
    getAppointments,
    getTaskById,
    registerAppointment,
    updateAppointment,
} from "core/utils/Api";
import notify from "core/utils/notify";
import { formatDate } from "core/utils/formatDate";
import { UserState } from "core/types/Types";

const headersArray = ["Tarefa", "Data", "Horas", "Detalhes", "Colaborador"];

const INITIAL_VALUES = {
    date_of_appointment: "",
    amount_hours: "",
    text: "",
};

interface ITimekeeping {
    user: UserState;
}

const TimekeepingPerTask = ({ user }: ITimekeeping) => {
    let { id } = useParams();
    const navigate = useNavigate();
    const [appointments, setAppointments] = useState<any[]>([]);
    const [appointmentRows, setAppointmentRows] = useState<{ id: number; list: string[] }[]>([]);
    const [appointmentEdit, setAppointmentEdit] = useState<any>(INITIAL_VALUES);
    const [paginationData, setPaginationData] = useState({
        total: 2,
        per_page: 5,
        current_page: 1,
        last_page: 1,
        from: 1,
        to: 2,
    });

    useEffect(() => {
        if (!Number(id) && user.appointment_type_id === 1) {
            navigate("/");
            return;
        }

        getAppointmentsData(Number(id));
    }, []);

    const getAppointmentsData = async (id: number) => {
        setAppointmentEdit(INITIAL_VALUES);
        const req = await getTaskById(id);

        if (req.data) {
            setAppointments(req.data.appointments);

            setAppointmentRows(
                req.data.appointments.map((aptmt: any) => {
                    return {
                        id: aptmt.id,
                        list: [
                            `[${req.data.name.split(" - ")[0]}]`,
                            formatDate(aptmt.date_of_appointment),
                            (Math.round(aptmt.amount_hours * 100) / 100).toFixed(2),
                            aptmt.text,
                            user.firstname,
                        ],
                    };
                })
            );
        }
    };

    const sendAppointmentData = async (data: any) => {
        let req;

        if (appointmentEdit.date_of_appointment.length) {
            req = await updateAppointment(appointmentEdit.id, {
                ...data,
                frames_tasks_id: Number(id),
            });
        } else {
            req = await registerAppointment({ ...data, frames_tasks_id: Number(id) });
        }

        getAppointmentsData(Number(id));
        if (req.error && req.error.title) {
            notify("error", req.error.title);
            return;
        }
        notify("success", "Apontamento registrado com sucesso!");
    };

    const changeAppointment = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const name = e.target.name;
        const value = e.target.value;
        const newAppointment = { ...appointmentEdit };
        newAppointment[name] = value;
        setAppointmentEdit(newAppointment);
    };

    return (
        <TimekeepingStyle>
            <div className="content-holder">
                <h1>Apontamento de Horas</h1>
                <FilterAndRegisterTimekeeping onSend={sendAppointmentData} />
                <Pagination
                    countPages={paginationData.last_page}
                    onPage={(num) => num}
                    currentPage={paginationData.current_page}
                    pageQty={paginationData.per_page}
                    setPageQty={() => {}}
                >
                    <div className="table-holder">
                        <Table
                            onEdit={async (edit, id) => {
                                setAppointmentEdit(appointments.find((item) => item.id === id));
                            }}
                            headerList={headersArray}
                            bodyList={appointmentRows}
                        />
                    </div>
                </Pagination>
                <Modal
                    header="Editar Apontamento"
                    open={appointmentEdit.date_of_appointment.length}
                    onClose={() => setAppointmentEdit(INITIAL_VALUES)}
                >
                    <div className="modal-appointment-content">
                        <Input
                            className="small-input"
                            placeholder="dd/mm/yyyy"
                            value={appointmentEdit.date_of_appointment}
                            onChange={changeAppointment}
                            label="Data:"
                            name="date_of_appointment"
                            type="date"
                        />
                        <Input
                            className="small-input"
                            placeholder="Horas Trabalhadas"
                            label="Horas Trabalhadas:"
                            value={appointmentEdit.amount_hours}
                            onChange={changeAppointment}
                            name="amount_hours"
                            type="number"
                        />
                        <Input
                            placeholder="Resumo da atividade"
                            label="Resumo da Atividade:"
                            value={appointmentEdit.text}
                            onChange={changeAppointment}
                            name="text"
                            type="text"
                        />
                        <div className="modal-appointment-group">
                            <Button
                                color="danger"
                                onClick={() => setAppointmentEdit(INITIAL_VALUES)}
                            >
                                Cancelar
                            </Button>
                            <Button
                                color="green"
                                onClick={() => {
                                    sendAppointmentData(appointmentEdit);
                                }}
                            >
                                Editar
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        </TimekeepingStyle>
    );
};

const mapStateToProps = (state: any) => ({
    user: state.user,
});

export default connect(mapStateToProps, null)(TimekeepingPerTask);
