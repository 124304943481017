//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors, screens } from "../../core/stylesheet/includes";

export const HeaderStyle = styled.div`
  padding: 0;
  background-color: ${colors.mainBlue};
  position: relative;
  width: 100%;
  min-width: 150px;

  @media ${screens.tb} {
    width: fit-content;
    padding: 16px 24px;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    color: ${colors.white};
  }

  &.active {
    background-color: ${colors.white};

    p {
      color: ${colors.mainBlue};
    }

    svg {
      color: ${colors.mainBlue} !important;
    }
  }

  &:hover {
    cursor: pointer;
  }

  .header-item {
    padding: 16px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    position: relative;

    @media ${screens.tb} {
      padding: 0;
    }

    img {
      max-height: 24px;
    }

    svg {
      height: 24px;
      width: 24px;
      color: ${colors.white};
    }

    .notification {
      background-color: ${colors.red};
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      font-size: 10px;
      color: ${colors.white};
      font-weight: 700;
    }
  }

  .header-dropdown {
    top: 100%;
    left: 0;
    width: 100%;
    height: auto;
    color: ${colors.blackBlue};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media ${screens.tb} {
      position: absolute;
      box-shadow: 1px 1px 8px #0e255225;
    }

    button {
      width: 100%;
      height: 100%;
      background-color: ${colors.white};
      font-size: 20px;
      font-weight: 400;
      padding: 16px 24px;
      text-align: left;

      @media ${screens.tb} {
        padding: 12px;
        text-align: center;
        font-size: 16px;
      }

      &.active {
        background-color: ${colors.blue};
        font-weight: 500;
        color: ${colors.white};
      }

      &:not(:last-child) {
        border-bottom: 1px solid ${colors.lightBlue};
      }
    }
  }
`;
