//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors, screens } from "../../../core/stylesheet/includes";

export const ChatsContainerStyle = styled.main`
  background-color: ${colors.white};
  border-radius: 10px;
  width: 100%;
  max-width: 1440px;
  height: 100%;
  display: flex;
  flex-direction: row;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
  max-height: 600px;

  &.on-message-page {
    .left-content {
      display: none;

      @media ${screens.tbV} {
        display: block;
      }
    }

    .messages-holder {
      display: flex;
      width: 100%;

      @media ${screens.tbV} {
        width: 75%;
        display: flex;
      }
    }
  }

  .left-content {
    width: 100%;
    height: 100%;
    overflow: hidden;

    @media ${screens.tbV} {
      border-right: 1px solid ${colors.darkBlue};
      width: 25%;
    }

    .links-holder {
      width: 100%;
      background-color: ${colors.mainBlue};
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 10px 10px 0 0;

      @media ${screens.tbV} {
        border-radius: 10px 0 0 0;
      }

      .links-header {
        color: ${colors.white};
        font-weight: 500;
        font-size: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 8px;
        width: 100%;

        &:hover {
          cursor: pointer;
        }
      }

      .links-body {
        max-height: 150px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        gap: 16px;
        background-color: ${colors.white};
        width: 100%;
        padding: 16px;
        border-bottom: 1px solid ${colors.blackBlue};

        .add-link > a {
          button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 5px;
            font-size: 18px;
            font-weight: 500;
            padding: 8px 16px;
          }
        }

        .link-card {
          display: flex;
          padding: 8px 16px;
          justify-content: space-between;
          align-items: center;
          border-radius: 5px;
          background-color: ${colors.mainBlue};
          color: ${colors.white};

          &.disabled {
            opacity: 0.7;
          }

          a {
            font-size: 18px;
            font-weight: 500;
            color: ${colors.white};

            &:hover {
              cursor: pointer;
            }
          }

          svg {
            width: 24px;
            height: 24px;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }

    .chats-holder {
      overflow: auto;
      max-height: 90%;
      padding: 24px 16px;
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 16px;

      h2 {
        font-size: 20px;
        font-weight: 700;
        color: ${colors.blackBlue};
        margin-bottom: 8px;
      }

      .chat-card {
        display: flex;
        padding: 12px;
        align-items: center;
        gap: 8px;
        border-radius: 5px;
        border: 1px solid ${colors.darkBlue};
        background-color: ${colors.white};
        width: 100%;
        justify-content: space-between;

        &-count {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: ${colors.red};
          border-radius: 100px;
          width: 18px;
          height: 18px;
          font-size: 12px;
          color: #ffffff;
          font-weight: 700;
        }

        &:hover {
          background-color: ${colors.lightBlue};
          cursor: pointer;
        }

        &.active {
          background-color: ${colors.blue};

          .name {
            color: ${colors.white};
          }
        }

        .name {
          color: ${colors.darkBlue};
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
  }

  .messages-holder {
    width: 75%;
    padding: 16px;
    display: none;
    flex-direction: column;
    gap: 16px;

    @media ${screens.tbV} {
      display: flex;
    }

    .messages-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: 700;
      color: ${colors.mainBlue};
      font-size: 20px;
      gap: 16px;

      @media ${screens.tbV} {
        display: none;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${colors.mainBlue};

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    .message-box {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      border: 1px solid ${colors.darkBlue};
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      overflow-x: hidden;
      overflow-y: auto;

      .message-holder {
        width: 100%;
        display: flex;
        justify-content: start;

        &.mine {
          justify-content: end;

          .message-card {
            border-radius: 10px 10px 0px 10px;
            background: ${colors.lightBlue2};
          }
        }

        .message-card {
          display: flex;
          padding: 8px;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          border-radius: 10px 10px 10px 0px;
          background: ${colors.blue};
          width: fit-content;
          max-width: 90%;
          color: ${colors.white};

          p,
          span,
          strong,
          h1,
          h2,
          h3,
          h4,
          h5 {
            max-width: 100%;
            word-break: break-word;
            color: ${colors.white};
          }

          pre {
            overflow: auto;
            max-width: 100%;
          }

          p {
            font-weight: 400;
            font-size: 16px;
          }
        }
      }
    }

    .input-message-holder {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      width: 100%;

      @media ${screens.mbB} {
        flex-direction: row;
      }

      .input-message {
        display: flex;
        align-items: center;
        width: 100%;
        overflow: hidden;
        border-radius: 5px;
        border: 1px solid ${colors.darkBlue};

        @media ${screens.mbS} {
          height: 44px;
        }

        svg {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;
          height: 24px;
          color: ${colors.darkBlue};
        }

        > div {
          max-height: 60px;
          width: 100%;
          height: 100%;
          overflow: auto;
        }
      }

      div:last-child {
        width: 100%;

        @media ${screens.mbB} {
          width: fit-content;
        }

        button {
          border-radius: 5px;
          height: 100%;
        }
      }
    }
  }
`;
