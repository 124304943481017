// REDUCER
import * as types from "./types";

// API
import { getUser } from "../../utils/Api";

//TYPES
import { UserState } from "../../types/Types";

export const changeUser = async () => {
    const req = await getUser();

    return { type: types.CHANGE_USER, payload: req };
};

export const setUser = (user: UserState) => ({
    type: types.CHANGE_USER,
    payload: {
        data: { ...user },
    },
});

export const clearUser = () => ({
    type: types.CLEAR_USER,
});
