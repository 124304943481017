//STYLES
import { ChangePasswordStyle } from "./styles";

//LIBS
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//COMPONENTS
import Input from "components/Input";

// ACTIONs
import { setLoader } from "../../core/reducers/LoaderReducer/actions";
import { UserState } from "core/types/Types";
import Button from "components/Button";
import { updateUser } from "core/utils/Api";
import { useState } from "react";
import notify from "core/utils/notify";
import { formDataUser } from "core/utils/transformData";
import Title from "components/Title";

interface IChangePassword {
  user: UserState;
}

const ChangePassword = ({ user }: IChangePassword) => {
  // const [currPassword, setCurrPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [passwordVerif, setPasswordVerif] = useState<string>("");

  const updatePassword = async () => {
    if (newPassword === passwordVerif) {
      const req = await updateUser(
        user.id,
        formDataUser({ ...user, password: newPassword })
      );

      if (req.error && req.error.message) {
        notify("error", req.error.message);
      } else {
        notify("success", "Senha alterada com sucesso");
      }
    } else {
      notify("error", "Senhas divergentes!");
    }
  };

  return (
    <ChangePasswordStyle>
      <Title>Alterar Senha</Title>
      <div className="content-container">
        <form>
          {/* <Input
                        onChange={({ target }) => setCurrPassword(target.value)}
                        value={currPassword}
                        label="Senha Atual:"
                        name="current_password"
                        type="password"
                    /> */}
          <Input
            onChange={({ target }) => setNewPassword(target.value)}
            value={newPassword}
            label="Nova Senha:"
            name="password"
            type="password"
          />
          <Input
            onChange={({ target }) => setPasswordVerif(target.value)}
            value={passwordVerif}
            label="Confirmar Senha:"
            name="password_confirmation"
            type="password"
          />
        </form>
        <Button onClick={updatePassword}>Atualizar</Button>
      </div>
    </ChangePasswordStyle>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      setLoader,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
