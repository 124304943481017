// STYLEs
import * as C from "./styles";

//LIBS
import { CgClose } from "react-icons/cg";

interface IModal {
  open: boolean;
  header: string;
  children: React.ReactNode;
  onClose?: () => void;
  className?: string;
  wdt1440?: string;
}

const Modal = ({ header, children, open, onClose, className, wdt1440 }: IModal) => {
  return (
    <C.Container className={`${open ? "active" : ""} ${className}`}>
      <div className={`content-container ${wdt1440}`}>
        <div className="Modal--header">
          <p>{header}</p>
          <button title="fechar" onClick={onClose}>
            <CgClose />
          </button>
        </div>
        <div className="Modal--body">{children}</div>
      </div>
    </C.Container>
  );
};

export default Modal;
