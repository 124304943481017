//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors, screens } from "../../core/stylesheet/includes";

export const ImageSelectorStyle = styled.div`
    max-width: 320px;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;

    img{
        width: 150px;
        height: 150px;
        border-radius: 100%;
    }

    input{
        display: none;
    }
`;
