//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors, screens } from "../../core/stylesheet/includes";

export const HomeStyle = styled.main`
  display: flex;
  align-items: start;
  justify-content: center;
  padding: 0 !important;

  main {
    display: flex;
    width: 100vw;
    min-height: 100dvh;
    height: 100%;
    flex-direction: row;
    gap: 16px;
  }

  .content-holder {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    overflow: hidden;
    padding: 90px 0 0;

    .board-button {
      display: block;
      max-width: 320px;

      @media ${screens.tb} {
        display: none;
      }

      button {
        padding: 16px 64px;
      }
    }
  }
`;
