//LIBS
import { FiFilter, FiCheck } from "react-icons/fi";
import {  ChangeEvent, useState } from "react";

//STYLES
import { FilterAndRegisterStyle } from "./styles";

//ASSETS
import AlertIcon from "../../../assets/icons/alert-triangle.svg";
import Select from "components/Select";
import Button from "components/Button";
import Input from "components/Input";
import notify from "core/utils/notify";

const months = [
    { label: "Janeiro", value: "1" },
    { label: "Fevereiro", value: "2" },
    { label: "Março", value: "3" },
    { label: "Abril", value: "4" },
    { label: "Maio", value: "5" },
    { label: "Junho", value: "6" },
    { label: "Julho", value: "7" },
    { label: "Agosto", value: "8" },
    { label: "Setembro", value: "9" },
    { label: "Outubro", value: "10" },
    { label: "Novembro", value: "11" },
    { label: "Dezembro", value: "12" },
];

const years = [
    { label: "2020", value: "2020" },
    { label: "2021", value: "2021" },
    { label: "2022", value: "2022" },
    { label: "2023", value: "2023" },
    { label: "2024", value: "2024" },
    { label: "2025", value: "2025" },
];

interface IFilterAndRegister {
    onSend: (data:any) => void;
}

const INITIAL_VALUES = {
    date_of_appointment:"",
    amount_hours:"",
    text:""
}

const FilterAndRegisterTimekeeping = ({onSend}:IFilterAndRegister) => {
    const [appointmentData, setAppointmentData] = useState<any>(INITIAL_VALUES);

    const changeAppointment = (e:ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const name = e.target.name 
        const value = e.target.value 
        const newAppointment = {...appointmentData};
        newAppointment[name] = value;
        setAppointmentData(newAppointment);
    };

    const registerAppointment = () =>{
        if(Object.values(appointmentData).every((value) => value !== "" && value !== null)){
            setAppointmentData(INITIAL_VALUES)
            onSend(appointmentData)
            
            return;
        }

        notify("error", "Preencha todos os campos corretamente!")
    }

    return (
        <FilterAndRegisterStyle>
            <div className="appointments-infos">
                <div className="filter">
                    <p className="title">Apontamentos referentes</p>
                    <div className="filter-holder">
                        <Select options={months} />
                        <Select options={years} />
                        <Button size="s3" color="white">
                            <FiFilter />
                        </Button>
                    </div>
                </div>
                <div className="infos">
                    <p className="danger">
                        <img src={AlertIcon} alt="Icone de alerta" />
                        ATENÇÃO
                        <img src={AlertIcon} alt="Icone de alerta" />
                    </p>
                    <p className="text">
                        A execução de horas extras, ou seja, prestação de serviços acima de 8 horas
                        no dia, deve ser previamente autorizada pela MD System WEB e pelo cliente em
                        que você trabalha. Esta autorização deve ser registrada de maneira formal e
                        via E-mail. <br />{" "}
                        <span>
                            Caso não exista autorização formal registrada, as horas podem não ser
                            pagas.
                        </span>
                    </p>
                </div>
            </div>
            <div className="appointments-register">
                <p className="title">Inserir Apontamento</p>
                <div className="register-holder">
                    <Input
                        className="small-input"
                        placeholder="dd/mm/yyyy"
                        label="Data"
                        name="date_of_appointment"
                        type="date"
                        showLabel={false}
                        value={appointmentData.date_of_appointment}
                        onChange={changeAppointment}
                    />
                    <Input
                        className="small-input"
                        placeholder="Horas Trabalhadas"
                        label="Hs Trab."
                        name="amount_hours"
                        type="number"
                        showLabel={false}
                        value={appointmentData.amount_hours}
                        onChange={changeAppointment}
                    />
                    <Input
                        placeholder="Resumo da atividade"
                        label="atividade"
                        name="text"
                        type="text"
                        showLabel={false}
                        value={appointmentData.text}
                        onChange={changeAppointment}
                    />
                    <Button color="default" size="s3" onClick={registerAppointment}>
                        <FiCheck />
                    </Button>
                </div>
            </div>
        </FilterAndRegisterStyle>
    );
};

export default FilterAndRegisterTimekeeping;
