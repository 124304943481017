//LIBS
import { Navigate, useNavigate } from "react-router-dom";
import { useState } from "react";

//STYLES
import { ForgotStyle } from "./styles";

//COMPONETS
import Input from "components/Input";
import Button from "components/Button";

//ASSETS
import MDSWLogo from "../../assets/images/MDSWLogoTransparent.png";

//API
import { forgotPassword } from "core/utils/Api";

//UTILS
import notify from "core/utils/notify";
import { validateUser } from "core/utils/validation";
import Title from "components/Title";

const Forgot = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>("");

    const handleForgot = async () => {
        if (email.length) {
            const req = await forgotPassword({ email: email });

            if (req.error && req.error.message) {
                notify("error", req.error.message);
            } else {
                notify("success", req.data.message);
            }
        } else {
            notify("error", "Preencha todos os campos corretamente!");
        }
    };

    return validateUser() ? (
        <Navigate to="/" replace />
    ) : (
        <ForgotStyle>
            <div className="form-holder">
                <Title>RECUPERAR SENHA</Title>
                <form>
                    <Input
                        onChange={({ target }) => {
                            setEmail(target.value);
                        }}
                        value={email}
                        label="E-mail:"
                        name="email"
                        type="text"
                    />
                    <Button onClick={handleForgot}>Recuperar</Button>
                    <div className="text-holder">
                        <p className="bottom-text">
                            Já possui uma conta?{" "}
                            <span onClick={() => navigate("/login")}>Entrar</span>
                        </p>
                        <p className="bottom-text">
                            Não possui uma conta?{" "}
                            <span onClick={() => navigate("/register")}>Cadastre-se</span>
                        </p>
                    </div>
                    <div className="brand-holder">
                        <img src={MDSWLogo} alt="Logo MDSYstem Web" />
                        <p className="brand-name">MDSystem Web Design</p>
                    </div>
                </form>
            </div>
        </ForgotStyle>
    );
};

export default Forgot;
