import { screens } from "core/stylesheet/includes";
import { styled } from "styled-components";

export const OrderStyle = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  .content-holder {
    width: 100%;
    max-width: 1440px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;
