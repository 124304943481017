//LIBS
import { useEffect, useState, ChangeEvent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InputMask from "react-input-mask";
import axios, { AxiosResponse } from "axios";

// ACTIONs
import { setLoader } from "../../core/reducers/LoaderReducer/actions";

//STYLES
import { ConfigContainerStyle } from "./styles";

//COMPONENTS
import Input from "components/Input";
import ImageSelector from "components/ImageSelector";
import Button from "components/Button";
import { getSettings, getUser, sendSettings, updateUser } from "core/utils/Api";

//TYPES
import { UserState } from "core/types/Types";
import { formDataUser } from "core/utils/transformData";
import { changeUser } from "core/reducers/UserReducer/actions";
import notify from "core/utils/notify";

interface IConfigContainer {
  setLoader: (state: boolean) => void;
  user: UserState;
  changeUser: () => void;
}

const ConfigContainer = ({ setLoader, user, changeUser }: IConfigContainer) => {
  const [settings, setSettings] = useState<any>({
    name_fantasy: "",
    cnpj: "",
    zipcode: "",
    state: "",
    state_abbreviation: "",
    uf: "",
    city: "",
    neighborhood: "",
    address: "",
    number: "",
    complement: "",
  });
  const [imageUser, setImageUser] = useState<string>("");
  const [imageUpload, setImageUpload] = useState<any | null>(null);

  //TODO: Integrar api de configuração
  useEffect(() => {
    setLoader(true);
    getSettingsData();

    setTimeout(() => {
      setLoader(false);
    }, 500);
  }, []);

  const getSettingsData = async () => {
    const req = await getSettings();
    if (req && req.data.favicon !== null && imageUser === "") {
      const userPhoto: string = req.data.favicon;
      userPhoto && setImageUser(userPhoto);
    }
    setSettings(req.data);
  };

  interface CepResponse {
    data: {
      cep: string;
      logradouro: string;
      complemento: string;
      bairro: string;
      localidade: string;
      state: string;
      uf: string;
      ibge: string;
      gia: string;
      ddd: string;
      siafi: string;
    };
  }

  const fetchCEP = async (cep: string): Promise<CepResponse | null> => {
    try {
      const response: AxiosResponse<CepResponse> = await axios.get(
        `https://api.optimus.mdsystemweb.com.br/api/viacep/${cep}`
      );
      return response.data;
    } catch (error) {
      throw new Error("Erro ao buscar CEP");
    }
  };

  const inputsHandler = async (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = e.target.name;
    const value = e.target.value;

    setSettings((values: any) => ({ ...values, [name]: value }));

    if (name === "zipcode") {
      const data = await fetchCEP(value);

      if (data) {
        const cepData = data.data;

        setSettings((values: any) => ({
          ...values,
          ...cepData,
          zipcode: cepData.cep,
          state: cepData.state || "",
          state_abbreviation: cepData.uf,
          city: cepData.localidade,
          neighborhood: cepData.bairro,
          address: cepData.logradouro,
        }));
      }
    }
  };

  const send = async () => {
    if (imageUpload !== null) {
      try {
        let form = new FormData();

        Object.keys(settings).map((key) => {
          if (key !== "logo" && key !== "favicon")
            form.append(key, settings[key]);
        });

        form.append("logo", imageUpload || settings.logo);
        form.append("favicon", imageUpload || settings.favicon);

        const req = await sendSettings(form);

        if (req.error && req.error.message) {
          notify("error", "Erro ao alterar as configurações");
        }

        getSettingsData();

        notify("success", "Configuração alterada com sucesso");
      } catch (err) {
        notify("error", "Erro ao alterar as configurações");
      }
    }
  };

  return (
    <ConfigContainerStyle>
      <ImageSelector
        image={imageUser}
        onChangeImg={(img) => {
          setImageUpload(img[0]);
          setImageUser(img[0]);
        }}
      />
      <form>
        <Input
          onChange={inputsHandler}
          value={settings.name_fantasy || ""}
          label="Razão Social:"
          name="name_fantasy"
          type="text"
        />
        <InputMask
          onChange={inputsHandler}
          value={settings.cnpj || ""}
          mask="99.999.999.9999-99"
        >
          <Input label="CNPJ:" name="cnpj" type="text" />
        </InputMask>
        <div className="double-input">
          <InputMask
            onChange={inputsHandler}
            value={settings.zipcode}
            mask="99999-999"
          >
            <Input label="CEP:" name="zipcode" type="text" />
          </InputMask>
          <Input
            onChange={inputsHandler}
            value={settings.state}
            label="Estado:"
            name="state"
            type="text"
          />
        </div>
        <div className="double-input">
          <Input
            onChange={inputsHandler}
            value={settings.city}
            label="Cidade:"
            name="city"
            type="text"
          />
          <Input
            onChange={inputsHandler}
            value={settings.state_abbreviation}
            label="UF:"
            name="state_abbreviation"
            type="text"
          />
        </div>
        <div className="double-input">
          <Input
            onChange={inputsHandler}
            value={settings.neighborhood}
            label="Bairro:"
            name="neighborhood"
            type="text"
          />
          <Input
            onChange={inputsHandler}
            value={settings.address}
            label="Endereço:"
            name="address"
            type="text"
          />
        </div>
        <div className="double-input">
          <Input
            onChange={inputsHandler}
            value={settings.number}
            label="N◦:"
            name="number"
            type="text"
          />
          <Input
            onChange={inputsHandler}
            value={settings.complement || ""}
            label="Complemento:"
            name="complement"
            type="text"
          />
        </div>
        <Button color="green" onClick={send}>
          Salvar Alterações
        </Button>
      </form>
    </ConfigContainerStyle>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      setLoader,
      changeUser,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ConfigContainer);
