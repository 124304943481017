//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors } from "../../../core/stylesheet/includes";

export const BoardsContainerStyle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  overflow: auto;
  width: 100%;
  padding: 16px;
  height: 100%;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    /* border-radius: 10px; */
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    height: 8px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    /* border-radius: 10px; */
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${colors.lightBlue};
  }
`;
