//LIBS
import { ChangeEvent, HTMLInputTypeAttribute } from "react";

//STYLES
import { InputStyle } from "./styles";

interface IInput {
  name: string;
  label: string;
  showLabel?: boolean;
  type: HTMLInputTypeAttribute | "textarea" | "task-title";
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onChangeFile?: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
  required?: boolean;
  value?: string | number;
  title?: string;
  multiple?: boolean;
  min?: number;
  max?: number;
  readOnly?: boolean;
  checked?: boolean;
  accept?: string;
  key?: number;
}

const Input = ({
  name,
  label,
  showLabel = true,
  type,
  onChange,
  onChangeFile,
  placeholder,
  className,
  required = false,
  value,
  title,
  multiple,
  min,
  max,
  readOnly = false,
  checked = false,
  accept,
  key,
}: IInput) => {
  function getInputByType(type: string) {
    switch (type) {
      case "textarea":
        return (
          <textarea
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            required={required}
            value={value}
          />
        );
      case "task-title":
        return (
          <div className="title-input">
            <div className="title">{title}</div>
            <input
              placeholder={placeholder}
              type={type}
              name={name}
              onChange={onChange}
              required={required}
              value={value}
            />
          </div>
        );
      default:
        return (
          <input
            key={key}
            placeholder={placeholder}
            type={type}
            name={name}
            checked={checked}
            onChange={type === "file" ? onChangeFile : onChange}
            required={required}
            value={value}
            multiple={multiple}
            min={min}
            max={max}
            readOnly={readOnly}
            accept={accept}
          />
        );
    }
  }

  return (
    <InputStyle className={`${className} ${type}`}>
      <label className={!showLabel ? "hidden" : ""} htmlFor={name}>
        {label}
      </label>
      {getInputByType(type)}
    </InputStyle>
  );
};

export default Input;
