//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors } from "../../core/stylesheet/includes";

export const ButtonStyle = styled.div`
  width: 100%;
  display: block;

  a {
    display: block;
  }

  button {
    background-color: ${colors.mainBlue};
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
    font-weight: 500;
    color: ${colors.white};
    padding: 8px;
    border-radius: 6px;
    font-size: 14px;

    &:hover {
      opacity: 0.9;
    }

    &:disabled {
      opacity: 0.7;
    }

    &.light-blue {
      border: 1px solid ${colors.white};
      background-color: ${colors.lightBlue2};
      color: ${colors.white};
    }

    &.green {
      border: 1px solid ${colors.white};
      background-color: ${colors.green};
      color: ${colors.white};
    }

    &.yellow {
      background-color: ${colors.yellow};
      color: ${colors.white};
    }

    &.white {
      background-color: ${colors.white};
      border: 1px solid ${colors.mainBlue};
      color: ${colors.mainBlue};
    }

    &.danger {
      background-color: ${colors.red};
    }

    &.green-white {
      border: 1px solid ${colors.green};
      background-color: ${colors.white};
      color: ${colors.green};
    }
  }
`;
