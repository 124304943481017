//STYLES
import { AgreementsRegisterStyle } from "./styles";

//LIBS
import { ChangeEvent, useEffect, useState } from "react";
import { bindActionCreators } from "@reduxjs/toolkit";
import { connect } from "react-redux";

//COMPONENTS
import Button from "components/Button";
import Input from "components/Input";
import Select from "components/Select";

//API
import { registerAgreement, getTabs, updateAgreement } from "core/utils/Api";

//UTILS
import notify from "core/utils/notify";

// ACTIONs
import { setLoader } from "../../core/reducers/LoaderReducer/actions";

const EMPTY_INPUTS = {
    name: "",
    is_active: true
};

interface IAgreementsRegister {
    agreementToEdit?: {
        id: number;
        name: string;
        is_active: number | boolean;
    };
    cancelEdit?: () => void;
    onSend: () => void;
    setLoader: (state: boolean) => void;
}

const AgreementsRegister = ({ agreementToEdit, cancelEdit, onSend, setLoader }: IAgreementsRegister) => {
    const [inputsValues, setInputsValues] = useState<any>(EMPTY_INPUTS);

    useEffect(() => {
        setInputsValues(agreementToEdit || EMPTY_INPUTS);
    }, [agreementToEdit]);

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputsValues((values: any) => ({ ...values, [name]: value }));
    };

    const handleSubmit = async () => {
        if (inputsValues.name !== "") {
            setLoader(true);

            let req;

            if (agreementToEdit) {
                req = await updateAgreement(agreementToEdit.id, inputsValues);
            } else {
                req = await registerAgreement(inputsValues);
            }

            if (req.error && req.error.title) {
                notify("error", req.error.title);
            } else {
                notify("success", `Acordo ${agreementToEdit ? "editado" : "cadastrado"} com sucesso`);
                onSend();
            }
        } else {
            notify("error", "Preencha todos os campos corretamente");
        }

        setLoader(false);
    };

    return (
        <AgreementsRegisterStyle>
            <form>
                <Input
                    onChange={handleChange}
                    value={inputsValues.name}
                    label="Nome:"
                    name="name"
                    type="text"
                />
                <Select
                    value={inputsValues.is_active ? "1" : "0"}
                    onSelect={(value) =>
                        setInputsValues((prev: any) => {
                            return { ...prev, is_active: value === "1" };
                        })
                    }
                    options={[
                        { label: "Ativo", value: "1" },
                        { label: "Inativo", value: "0" },
                    ]}
                />
                <Button onClick={handleSubmit}>Salvar</Button>
                {agreementToEdit && (
                    <Button color="danger" onClick={cancelEdit}>
                        Cancelar
                    </Button>
                )}
            </form>
        </AgreementsRegisterStyle>
    );
};

const mapDispatchToProps = (dispatch: any) =>
    bindActionCreators(
        {
            setLoader,
        },
        dispatch
    );

export default connect(null, mapDispatchToProps)(AgreementsRegister);
