// LIBs
import styled from "styled-components";

// INCLUDEs
import { colors, screens } from "../../core/stylesheet/includes";

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 20px;
    background-color: ${colors.white};
    border-radius: 10px;
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);

    .Pagination {
        &__pagination-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column-reverse;
            gap: 16px;
            width: 100%;

            @media ${screens.tb} {
                flex-direction: row;
            }
        }

        &__pagination {
            display: flex;
            gap: 14px;

            &--button {
                width: 26px;
                height: 26px;
                border-radius: 5px;
                font-size: 16px;
                border: 1px solid ${colors.grayBlue};
                transition: all ease 0.5s;
                cursor: pointer;

                display: flex;
                justify-content: center;
                align-items: center;

                &:last-child {
                    margin-right: 0;
                }

                &:nth-child(5) {
                    display: flex;
                }

                &:nth-child(4) {
                    display: none;
                }

                @media ${screens.mb} {
                    &:nth-child(4) {
                        display: flex;
                    }
                }

                &:hover {
                    background-color: ${colors.mainBlue};
                    color: ${colors.white};
                    border-color: ${colors.mainBlue};

                    p {
                        color: ${colors.white};
                    }
                }

                &.active {
                    background-color: ${colors.mainBlue};
                    color: ${colors.white};
                    border-color: ${colors.mainBlue};

                    p {
                        color: ${colors.white};
                    }
                }

                p {
                    font-size: 12px;
                    transition: all ease 0.5s;
                }
            }
        }
    }
`;
