//STYLES
import { EditInvoiceStyle } from "./styles";

//COMPONENTS
import Modal from "components/Modal";
import Input from "components/Input";
import Select from "components/Select";
import Button from "components/Button";

//TYPES
import { InvoiceType } from "core/types/Types";

//LIBS
import { useState, ChangeEvent } from "react";
import { updateInvoice } from "core/utils/Api";
import notify from "core/utils/notify";

interface IEditInvoice {
    hours?: number;
    invoiceToEdit: InvoiceType;
    modalOpen: boolean;
    onClose?: () => void;
}

const StatusOptns = [
    { label: "Pagamento Realizado", value: "1" },
    { label: "Pagamento Programado", value: "2" },
    { label: "Aguarde", value: "3" },
];

const PaymentStatusOptns = [
    { label: "Falta Pagar", value: "0" },
    { label: "Pago", value: "1" },
];

const ModalEditInvoice = ({
    hours = 0,
    invoiceToEdit,
    modalOpen = false,
    onClose,
}: IEditInvoice) => {
    const [invoice, setInvoice] = useState<any>({
        observation: invoiceToEdit.observation || "",
        extra_hour: invoiceToEdit.extra_hour,
        reimbursement: invoiceToEdit.reimbursement,
        discount: invoiceToEdit.discount,
        invoices_status_id: invoiceToEdit.invoices_status_id,
        status_payment: invoiceToEdit.status_payment,
        date_payment: invoiceToEdit.date_payment,
    });

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        setInvoice((values: any) => ({ ...values, [name]: value }));
    };

    const editInvoice = async () => {
        const req = await updateInvoice(invoiceToEdit.id, invoice);

        if (req.error && req.error.message) {
            notify("error", req.error.message);
        }

        if (req.data && req.data.message) {
            notify("success", req.data.message);
        }

        onClose && onClose();
    };

    return (
        <Modal header="NF:00" open={modalOpen} onClose={onClose}>
            <EditInvoiceStyle>
                <p className="info">
                    Horas Trabalhadas:
                    <span>{Number(hours).toFixed(2)} hs</span>
                </p>
                <Input
                    onChange={handleChange}
                    label="Obs. Prestador:"
                    name="observation"
                    type="textarea"
                />
                <div className="inputs-holder">
                    <Input
                        onChange={handleChange}
                        value={invoice.extra_hour}
                        label="Qt. Horas Extras:"
                        name="extra_hour"
                        type="number"
                    />
                    <Input
                        onChange={handleChange}
                        value={invoice.reimbursement}
                        label="Reembolso:"
                        name="reimbursement"
                        type="number"
                    />
                    <Input
                        onChange={handleChange}
                        value={invoice.discount}
                        label="Desconto:"
                        name="discount"
                        type="number"
                    />
                </div>
                <div className="inputs-holder">
                    <Select
                        label="Status:"
                        value={`${invoice.invoices_status_id || StatusOptns[0].value}`}
                        onSelect={(value) => {
                            setInvoice((prev: any) => {
                                return { ...prev, invoices_status_id: +value };
                            });
                        }}
                        name="invoice_status"
                        options={StatusOptns}
                    />
                    <Select
                        label="Status Pagamento:"
                        value={`${invoice.status_payment || PaymentStatusOptns[0].value}`}
                        onSelect={(value) => {
                            setInvoice((prev: any) => {
                                return { ...prev, status_payment: +value };
                            });
                        }}
                        name="hours_extra"
                        options={PaymentStatusOptns}
                    />
                    <Input
                        onChange={handleChange}
                        label="Data Pagamento:"
                        value={invoice.date_payment}
                        name="date_payment"
                        type="date"
                    />
                </div>
                <div className="buttons-holder">
                    <Button onClick={onClose} color="danger">
                        Fechar
                    </Button>
                    <Button color="green" onClick={editInvoice}>
                        Editar
                    </Button>
                </div>
            </EditInvoiceStyle>
        </Modal>
    );
};

export default ModalEditInvoice;
