//LIBS
import { useLocation } from "react-router-dom";
import { FiUser, FiMenu } from "react-icons/fi";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//STYLES
import { HeaderStyle } from "./styles";

//COMPONENST
import HeaderItem from "../../components/HeaderItem";

//ASSETS
import MDSWLogo from "../../assets/images/MDSWLogoTransparent.png";

//API
import { getTabs, getUsers } from "../../core/utils/Api";

//TYPES
import { Aba, IAbas, IChatTypes, UserState } from "../../core/types/Types";

//REDUCERS
import { clearUser } from "core/reducers/UserReducer/actions";
import { setChats } from "core/reducers/ChatReducer/actions";

//UTILS
import notify from "core/utils/notify";
import { validateUser } from "core/utils/validation";

interface IHeaderAbas {
  link?: string;
  name: string;
  icon?: string | React.ReactElement;
  innerItems?: IAbas[];
  notifications?: number;
}

interface IHeader {
  clearUser: () => void;
  setChats: (Chats: IChatTypes[]) => void;
  user: UserState;
}

const Header = ({ clearUser, setChats, user }: IHeader) => {
  const [abas, setAbas] = useState<IHeaderAbas[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [chatsNotification, setChatsNotification] = useState<number>();

  const location = useLocation();

  const headerData: IHeaderAbas[] = [
    {
      link: "/",
      name: "Dashboard",
      icon: <img src={MDSWLogo} alt="MDSLOGO" />,
    },
    {
      name: user.firstname,
      icon: <FiUser />,
      innerItems: [],
    },
  ];

  useEffect(() => {
    if (validateUser()) {
      getChats();
      getData();

      let notificationInterval = setInterval(() => {
        getChats();
      }, 5000);

      return () => {
        clearInterval(notificationInterval);
      };
    }
  }, []);

  const getChats = async () => {
    try {
      const req = await getUsers();

      if (req && req.data) {
        const reqData = req.data.enables_chat;
        const count = req.data.enables_chat_count_amount;

        const chatsData = reqData
          ?.map((chat: any) => ({
            id: chat.id,
            userName: chat.firstname,
            count: chat.chat_messages_count,
          }))
          .sort((a: any, b: any) => a.id - b.id);

        setChats(chatsData);
        setChatsNotification(count);
      } else if (req.error && req.error.title) {
        notify("error", req.error.title);
      }
    } catch (err) {
      clearUser();
    }
  };

  const getData = async () => {
    if (localStorage.getItem("Token")) {
      const listAbas: Aba[] = Array.isArray(user.abas) ? [...user.abas] : [];

      let abasEnabled = listAbas!.filter((aba: IAbas) => aba.name !== "Abas");

      const chatIndex = abasEnabled.findIndex(
        (aba: IAbas) => aba.name === "Chats"
      );

      let abaChat;

      if (chatIndex !== -1) {
        abaChat = abasEnabled.splice(chatIndex, 1)[0];
        headerData.push(abaChat);
      }

      headerData[1].innerItems = [
        ...abasEnabled,
        { name: "Meus Dados", link: "profile" },
        { name: "Alterar Senha", link: "changepassword" },
        {
          name: "Sair",
          onClick: () => {
            clearUser();
          },
        },
      ];

      if (user.appointment_type_id === 2) {
        headerData[1].innerItems.unshift({
          name: "Apontamentos",
          link: "Timekeeping",
        });
      }

      setAbas(headerData);
    }
  };

  return (
    <HeaderStyle>
      <div className="mobile-header">
        <img src={MDSWLogo} alt="Logo MdSystem Web" />
        <button title="Menu" onClick={() => setOpen(!open)}>
          <FiMenu />
        </button>
      </div>
      <div className={`content-holder ${open ? "active" : ""}`}>
        {abas &&
          abas.map((item: IHeaderAbas, index) => (
            <HeaderItem
              onClick={() => setOpen(!open)}
              icon={item.icon}
              to={item.link}
              label={item.name}
              active={
                item.link === location.pathname ||
                item.link === location.pathname.slice(1) ||
                item.link === "/"
              }
              key={index}
              innerItems={item.innerItems}
              notifications={item.name === "Chats" ? chatsNotification : 0}
            />
          ))}
      </div>
    </HeaderStyle>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      clearUser,
      setChats,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Header);
