//LIB
import { Outlet } from "react-router-dom";
import { useLayoutEffect } from "react";
import { connect } from "react-redux";

//COMPONENT
import Header from "./container/Header";
import Loading from "./components/Loading";

//API
import { getUser, setToken } from "./core/utils/Api";

//SLIDER STYLES
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface IApp {
    loader: boolean;
}

function App({ loader }: IApp) {

    useLayoutEffect(() => {
        const localToken = localStorage.getItem("Token")
        if (localToken) {
            setToken(localToken)
            isToken();
        }
    }, []);

    const isToken = async () => {
        try {
            await getUser();
        } catch (err) {
        }
    };

    return (
        <div className="App">
            {loader && <Loading opacity={1} />}
            <Header />
            <Outlet />
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    loader: state.loader
});


export default connect(mapStateToProps, null)(App);
