export const EMPTY_OBJECT = {
  id: "",
  user_id: "",
  user_dependent_id: "",
  position_id: "",
  agreement_id: "",
  group_id: "",
  mobile_id: "",
  firstname: "",
  lastname: "",
  birthday: "",
  general_record: "",
  taxvat_primary: "",
  taxvat_secondy: "",
  photo: "",
  name_fantasy: "",
  zipcode: "",
  address: "",
  number: "",
  complement: "",
  neighborhood: "",
  city: "",
  state: "",
  state_abbreviation: "",
  email: "",
  email_guys: "",
  bank_agency: "",
  bank_account: "",
  bank_operation: "",
  bank: "",
  pix: "",
  contact: "",
  date_init: "",
  wage: "",
  amount_time: "",
  login: "",
  is_active: true,
  user_master: "",
  user_dependent: "",
  position: {
    id: "",
    user_id: "",
    name: "",
    is_active: "",
  },
  agreement: {
    id: "",
    name: "",
    is_active: "",
  },
  group: {
    id: "",
    name: "",
    is_active: "",
  },
  password: "",
};

export const inputsToValidate = [
  "position_id",
  "agreement_id",
  "group_id",
  "lastname",
  "birthday",
  "general_record",
  "taxvat_primary",
  "taxvat_secondy",
  "name_fantasy",
  "zipcode",
  "address",
  "neighborhood",
  "city",
  "state",
  "state_abbreviation",
  "email",
  "email_guys",
  "bank_agency",
  "bank_account",
  "bank_operation",
  "bank",
  "pix",
  "contact",
  "date_init",
  "wage",
  "amount_time",
  "login",
  "password",
  "is_active",
];
