//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors, screens } from "../../../core/stylesheet/includes";

export const TimekeepingStyle = styled.main`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content-holder {
        width: 100%;
        max-width: 1440px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
    }

    .pagination-container {
        width: 100%;
        align-items: center;

        @media ${screens.tb} {
            align-items: start;
        }
    }

    .timekeeping-register {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap-reverse;
        gap: 24px;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @media ${screens.mb} {
            flex-direction: row;
        }

        .point {
            width: 100%;
            order: 4;

            @media ${screens.mb} {
                order: 1;
            }

            @media ${screens.tbV} {
                width: fit-content;
            }

            button {
                font-size: 18px;

                @media ${screens.mb} {
                    font-size: 20px;
                }
            }
        }

        .switch-holder {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            order: 3;

            @media ${screens.mb} {
                order: 0;
            }

            @media ${screens.tbS} {
                width: fit-content;
                order: 3;
            }
        }

        .hours {
            order: 3;

            &:first-child {
                order: 2;
            }

            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: center;

            @media ${screens.mbV} {
                gap: 16px;
            }

            svg {
                width: 48px;
                height: 48px;

                @media ${screens.mb} {
                    width: 28px;
                    height: 28px;
                }

                @media ${screens.mbB} {
                    width: 32px;
                    height: 32px;
                }

                @media ${screens.mbV} {
                    width: 48px;
                    height: 48px;
                }
            }

            &__texts {
                span {
                    font-size: 16px;
                    font-weight: 500;
                    color: ${colors.grayBlue};
                }

                p {
                    font-size: 28px;
                    font-weight: 500;
                    color: ${colors.mainBlue};

                    @media ${screens.mb} {
                        font-size: 24px;
                    }

                    @media ${screens.mbB} {
                        font-size: 28px;
                    }
                }
            }
        }
    }

    .table-holder {
        width: 100%;
        overflow: auto;

        table {
            min-width: 960px;
        }
    }
`;
