//LIBS
import { Navigate } from "react-router-dom";

//UTILS
import { validateUser } from "../../core/utils/validation";

interface IPrivateRoute {
    component: JSX.Element;
}

/**
 * Componente para validação se o usuário logado possui
 * as devidas autorizações de acesso a página passada
 * na propiedade "component".
 *
 */

const PrivateRoute = ({ component }: IPrivateRoute) => {
    return validateUser() ? component : <Navigate to="/login" replace />;
};

export default PrivateRoute;
