// LIBs
import styled from "styled-components";

// INCLUDEs
import { colors } from "../../core/stylesheet/includes";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  .LoadingDetails {
    max-width: 280px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

    &__image {
      img {
        width: 100%;
      }
    }

    &__spin {
      div {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        border: 2px solid ${colors.white};
        border-left-color: ${colors.darkBlue};
        animation: spinner 500ms linear infinite;
      }
    }
  }

  @keyframes spinner {
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(360deg);
    }
  }
`;
